import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getNotificationGET = (Constants, { page }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:t7gUyQgs/notifications?page=${encodeQueryParam(
      `${typeof page === 'string' ? page : JSON.stringify(page ?? '')}`
    )}&perPage=20`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetNotificationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Notifications', args],
    () => getNotificationGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetNotificationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNotificationGET(
    { page },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNotification: refetch });
};

export const markSeenPOST = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:t7gUyQgs/mark_notifications_seen`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useMarkSeenPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => markSeenPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Notification', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Notification');
        queryClient.invalidateQueries('Notifications');
      },
    }
  );
};

export const FetchMarkSeenPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useMarkSeenPOST(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMarkSeen: refetch });
};
