import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CountryCodesPicker from '../custom-files/CountryCodesPicker';
import * as CustomCode from '../custom-files/CustomCode';
import concat_Names from '../global-functions/concat_Names';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openCameraUtil from '../utils/openCamera';
import openImagePickerUtil from '../utils/openImagePicker';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const EditMyProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [action_sheet, setAction_sheet] = React.useState(false);
  const [all_user_types, setAll_user_types] = React.useState([]);
  const [business_type, setBusiness_type] = React.useState('');
  const [call, setCall] = React.useState(false);
  const [chat, setChat] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [company_type, setCompany_type] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [email, setEmail] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [location_search_text, setLocation_search_text] = React.useState('');
  const [mail, setMail] = React.useState(false);
  const [mobile_number, setMobile_number] = React.useState('');
  const [myLat, setMyLat] = React.useState('');
  const [myLong, setMyLong] = React.useState('');
  const [myProfile, setMyProfile] = React.useState({});
  const [name, setName] = React.useState('');
  const [profile_pic, setProfile_pic] = React.useState('');
  const [selected_user_type, setSelected_user_type] = React.useState('');
  const [showPicker, setShowPicker] = React.useState(false);
  const [
    show_business_classification_modal,
    setShow_business_classification_modal,
  ] = React.useState(false);
  const [show_imagePicker_options, setShow_imagePicker_options] =
    React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_user_type_modal, setShow_user_type_modal] = React.useState(false);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [user_type, setUser_type] = React.useState(0);
  const split_Fullname = fullname => {
    const myArray = fullname.split(' ');
    return myArray;
  };
  const xanoUpdateProfilePOST = XanoApi.useUpdateProfilePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await XanoApi.userProfileGET(Constants, {
            profile_id: Constants['User_Details']?._profile_of_user?.id,
          })
        )?.json;
        console.log(result);
        setMyProfile(
          (() => {
            const e = result?.data;
            console.log(e);
            return e;
          })()
        );
        setName(split_Fullname(result?.profile_name)?.[0]);
        setEmail(result?.email);
        setLocation(result?.location);
        setMobile_number(result?.phone_number);
        setCompany_type(result?._user_types?.name);
        setBusiness_type(result?.type_of_business);
        const types = (await XanoApi.userTypesGET(Constants))?.json;
        setAll_user_types(types);
        setSelected_user_type(result?.data?.company_type);
        setProfile_pic(result?.profile_image?.url);
        setUser_type(result?.user_types_id);
        setLastName(split_Fullname(result?.profile_name)?.[1]);
        setCall(result?._permissions_of_user?.privacy_call);
        setChat(result?._permissions_of_user?.privacy_messenger);
        setMail(result?._permissions_of_user?.privacy_mail);
        setMyLat(result?.coordinates?.data?.lat);
        setMyLong(result?.coordinates?.data?.lng);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <Utils.CustomCodeErrorBoundary>
        <CountryCodesPicker.Index
          theme={theme}
          setCountryCode={setCountryCode}
          show={showPicker}
          setShow={setShowPicker}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Cancel */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                }),
                dimensions.width
              )}
            >
              {'Cancel'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Screen heading Phone'],
            dimensions.width
          )}
        >
          {'Edit Profile'}
        </Text>
        {/* Save */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            const handler = async () => {
              try {
                setIs_Loading(true);
                const api_Response = (
                  await xanoUpdateProfilePOST.mutateAsync({
                    email: email,
                    lat: myLat,
                    location: location,
                    long: myLong,
                    phone_number: mobile_number,
                    privacy_call: call,
                    privacy_mail: mail,
                    privacy_message: chat,
                    profile_id: Constants['User_Details']?._profile_of_user?.id,
                    profile_name: concat_Names(name, lastName),
                    profile_photo: profile_pic,
                    type_of_business: business_type,
                    user_types_id: user_type,
                  })
                )?.json;
                setIs_Loading(false);
                if (api_Response?.id) {
                  showAlertUtil({
                    title: 'Dirt Near Me',
                    message: 'Profile updated Successfully',
                    buttonText: 'Ok',
                  });
                }
                if (api_Response?.id) {
                  navigation.goBack();
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <>
              {!is_Loading ? null : (
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ],
                    dimensions.width
                  )}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                }),
                dimensions.width
              )}
            >
              {'Save'}
            </Text>
          </View>
        </Pressable>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          { padding: 16, paddingBottom: 25 },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        {/* Image */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 20,
            },
            dimensions.width
          )}
        >
          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                setShow_imagePicker_options(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Image
              resizeMode={'cover'}
              source={Images.ImageSurface}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 180,
                  width: 180,
                }),
                dimensions.width
              )}
            />
            <Image
              resizeMode={'cover'}
              source={{ uri: `${profile_pic}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  borderRadius: 8,
                  height: 180,
                  position: 'absolute',
                  top: 0,
                  width: 180,
                }),
                dimensions.width
              )}
            />
          </Pressable>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'Poppins_400Regular',
                marginTop: 4,
              }),
              dimensions.width
            )}
          >
            {'Click to change the picture'}
          </Text>
        </View>
        {/* First Name */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'First Name'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Divider'],
                borderRadius: 8,
                height: 44,
                justifyContent: 'center',
                marginTop: 6,
              },
              dimensions.width
            )}
          >
            <TextInput
              autoCapitalize={'words'}
              keyboardType={'default'}
              onChangeText={newTextInputValue => {
                try {
                  setName(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter your first name'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                  { textTransform: 'capitalize' }
                ),
                dimensions.width
              )}
              textContentType={'name'}
              value={name}
            />
          </View>
        </View>
        {/* Last Name */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Last Name'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Divider'],
                borderRadius: 8,
                height: 44,
                justifyContent: 'center',
                marginTop: 6,
              },
              dimensions.width
            )}
          >
            <TextInput
              autoCapitalize={'words'}
              keyboardType={'default'}
              onChangeText={newTextInputValue => {
                try {
                  setLastName(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter your last name'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                dimensions.width
              )}
              textContentType={'familyName'}
              value={lastName}
            />
          </View>
        </View>
        {/* Phone Number */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Phone Number'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Divider'],
                borderRadius: 8,
                flexDirection: 'row',
                height: 44,
                justifyContent: 'flex-start',
                marginTop: 6,
                paddingLeft: 16,
              },
              dimensions.width
            )}
          >
            <Touchable
              delayLongPress={0.3}
              disabled={true}
              onPress={() => {
                try {
                  setShowPicker(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 16,
                  }),
                  dimensions.width
                )}
              >
                {countryCode}
              </Text>
            </Touchable>

            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <TextInput
                autoCapitalize={'none'}
                keyboardType={'phone-pad'}
                onChangeText={newTextInputValue => {
                  try {
                    setMobile_number(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter phone number'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                  dimensions.width
                )}
                textContentType={'telephoneNumber'}
                value={mobile_number}
              />
            </View>
          </View>
        </View>
        {/* Profile Type */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Profile Type'}
          </Text>

          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                setShow_user_type_modal(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Divider'],
                  borderRadius: 8,
                  height: 44,
                  justifyContent: 'center',
                  marginTop: 6,
                },
                dimensions.width
              )}
            >
              {/* Placeholder */}
              <>
                {company_type ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Light'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          marginLeft: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Select Profile type'}
                  </Text>
                )}
              </>
              {/* Value */}
              <>
                {!company_type ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          marginLeft: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {company_type}
                  </Text>
                )}
              </>
            </View>
          </Pressable>
        </View>
        {/* Business Classification */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Business Classification'}
          </Text>

          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                setShow_business_classification_modal(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Divider'],
                  borderRadius: 8,
                  height: 44,
                  justifyContent: 'center',
                  marginTop: 6,
                },
                dimensions.width
              )}
            >
              {/* Placeholder */}
              <>
                {business_type ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Light'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          marginLeft: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Select Business Classification'}
                  </Text>
                )}
              </>
              {/* Value */}
              <>
                {!business_type ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          marginLeft: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {business_type}
                  </Text>
                )}
              </>
            </View>
          </Pressable>
        </View>
        {/* Location */}
        <View
          style={StyleSheet.applyWidth(
            { height: 75, marginTop: 16 },
            dimensions.width
          )}
        >
          {/* Label */}
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Location'}
          </Text>

          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                setShow_location_modal(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Divider'],
                  borderRadius: 8,
                  height: 44,
                  justifyContent: 'center',
                  marginTop: 6,
                },
                dimensions.width
              )}
            >
              {/* Placeholder */}
              <>
                {location ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Light'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          marginLeft: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Select Location'}
                  </Text>
                )}
              </>
              {/* Value */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 17,
                    marginLeft: 16,
                  }),
                  dimensions.width
                )}
              >
                {location}
              </Text>
            </View>
          </Pressable>
        </View>

        <View>
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Poppins_400Regular',
                fontSize: 17,
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {'Contact Preferences'}
          </Text>
          {/* Call */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 25,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary Blue']}
              name={'MaterialIcons/call'}
              size={24}
            />
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <SwitchRow
                activeTrackColor={theme.colors['Primary Blue']}
                defaultValue={call}
                label={'Call'}
                onValueChange={newSwitchRowValue => {
                  const switchRowValue = newSwitchRowValue;
                  try {
                    if (call === true) {
                      setCall(false);
                    }
                    if (call === false) {
                      setCall(true);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                    {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginLeft: 15,
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* Chat */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 25,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary Blue']}
              name={'MaterialIcons/chat-bubble'}
              size={24}
            />
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <SwitchRow
                activeTrackColor={theme.colors['Primary Blue']}
                defaultValue={chat}
                label={'Chat'}
                onValueChange={newSwitchRowValue => {
                  const switchValue = newSwitchRowValue;
                  try {
                    if (chat === false) {
                      setChat(true);
                    }
                    if (chat === true) {
                      setChat(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                    {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginLeft: 15,
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* Email */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 25,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary Blue']}
              name={'MaterialIcons/email'}
              size={24}
            />
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              <SwitchRow
                activeTrackColor={theme.colors['Primary Blue']}
                defaultValue={mail}
                label={'Email'}
                onValueChange={newSwitchRowValue => {
                  const switchValue = newSwitchRowValue;
                  try {
                    if (mail === false) {
                      setMail(true);
                    }
                    if (mail === true) {
                      setMail(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                    {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginLeft: 15,
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
      {/* company types modal */}
      <>
        {!show_user_type_modal ? null : (
          <Modal animationType={'fade'} transparent={true}>
            {/* Black BG View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_18'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.5,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            {/* Company Type List */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  overflow: 'hidden',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { height: 400, overflow: 'hidden' },
                  dimensions.width
                )}
              >
                <XanoApi.FetchUserTypes2GET>
                  {({ loading, error, data, refetchUserTypes2 }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <RadioButtonGroup
                        onValueChange={newRadioButtonGroupValue => {
                          try {
                            setSelected_user_type(newRadioButtonGroupValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        value={selected_user_type}
                      >
                        <FlatList
                          contentContainerStyle={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom #ffffff'],
                              borderRadius: 8,
                              justifyContent: 'center',
                              overflow: 'hidden',
                            },
                            dimensions.width
                          )}
                          data={(() => {
                            const e = fetchData;
                            console.log(e);
                            return e;
                          })()}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          listKey={'41SsVYGb'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setSelected_user_type(listData?.value);
                                    setCompany_type(listData?.label);
                                    setShow_user_type_modal(false);
                                    setUser_type(listData?.value);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Primary Yellow'],
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      padding: 12,
                                      paddingLeft: 30,
                                      width: 250,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    source={{ uri: `${listData?.icon_path}` }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        { height: 30, width: 30 }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          flex: 1,
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 18,
                                          marginLeft: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.label}
                                  </Text>
                                </View>
                              </Pressable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      </RadioButtonGroup>
                    );
                  }}
                </XanoApi.FetchUserTypes2GET>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Business Classifications Modal */}
      <>
        {!show_business_classification_modal ? null : (
          <Modal animationType={'fade'} transparent={true}>
            {/* Black BG View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_18'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.5,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth({ height: 200 }, dimensions.width)}
              >
                <RadioButtonGroup>
                  <FlatList
                    contentContainerStyle={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderRadius: 8,
                        justifyContent: 'center',
                        overflow: 'hidden',
                      },
                      dimensions.width
                    )}
                    data={['N/A', 'WBE', 'MBE', 'DBE']}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'W1bMkvys'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setBusiness_type(listData);
                              setShow_business_classification_modal(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Primary Yellow'],
                                flexDirection: 'row',
                                justifyContent: 'center',
                                padding: 12,
                                paddingLeft: 12,
                                width: 160,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    flex: 1,
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 18,
                                    marginLeft: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </RadioButtonGroup>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Location Modal */}
      <>
        {!show_location_modal ? null : (
          <Modal animationType={'none'}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Divider'],
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 12,
                  marginRight: 12,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 50,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'Change Location'}
              </Text>

              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_location_modal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                          marginRight: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Close'}
                  </Text>
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'Ionicons/md-close'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>

            <View
              style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
            >
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={100}
                onChangeText={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Search a location'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'],
                    {
                      borderColor: theme.colors['Divider'],
                      borderWidth: 1,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginBottom: 15,
                      paddingLeft: 16,
                    }
                  ),
                  dimensions.width
                )}
                value={location_search_text}
              />
              <XanoGooglePlacesApi.FetchSearchGET search={location_search_text}>
                {({ loading, error, data, refetchSearch }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <FlatList
                      data={fetchData?.predictions}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'jQWSfgb9'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setShow_location_modal(false);
                                  const coords = (
                                    await XanoGooglePlacesApi.getCoordinatesGET(
                                      Constants,
                                      { place_id: listData?.place_id }
                                    )
                                  )?.json;
                                  console.log(coords);
                                  setMyLat(
                                    coords?.result?.geometry?.location?.lat
                                  );
                                  setMyLong(
                                    coords?.result?.geometry?.location?.lng
                                  );
                                  setLocation(listData?.description);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  paddingBottom: 10,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Entypo/location-pin'}
                                size={24}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.description}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XanoGooglePlacesApi.FetchSearchGET>
            </View>
          </Modal>
        )}
      </>
      {/* Image Picker */}
      <ActionSheet visible={show_imagePicker_options}>
        {/* Gallery */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Gallery'}
          onPress={() => {
            const handler = async () => {
              try {
                const image = await openImagePickerUtil({
                  mediaTypes: 'All',
                  allowsEditing: false,
                  quality: 0.2,
                });

                setProfile_pic(image);
                setShow_imagePicker_options(false);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Camera */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Camera'}
          onPress={() => {
            const handler = async () => {
              try {
                const image = await openCameraUtil({
                  mediaTypes: 'Images',
                  allowsEditing: false,
                  cameraType: 'front',
                  videoMaxDuration: undefined,
                  quality: 0.2,
                });

                setProfile_pic(image);
                setShow_imagePicker_options(false);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Cancel */}
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setShow_imagePicker_options(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
    </ScreenContainer>
  );
};

export default withTheme(EditMyProfileScreen);
