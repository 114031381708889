import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ChatApi from '../apis/ChatApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const BlockedUsersScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [showUnBlockConfirmationPopUp, setShowUnBlockConfirmationPopUp] =
    React.useState(false);
  const [tempUser, setTempUser] = React.useState({});
  const checkUserIDs = item => {
    if (item.user_id == my_user_ID) return item.user2_id;
    return item.user_id;
  };

  const chatDisplay = (dump, item) => {
    if (item.posts_id) {
      if (!item._posts) return false;
      return true;
    }
    return true;
  };

  const chamgeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };

  const useRelativeTimeGetter = chatTime => {
    const time = chatTime;
    var date = new Date().getTime();
    var elapsed = date - new Date(time).getTime();
    // "time" here is the Parameter name you chose //

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' sec ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' mins ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'around ' + Math.round(elapsed / msPerYear) + ' year ago';
    }
  };
  const xanoUnblockAUserDELETE = XanoApi.useUnblockAUserDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await ChatApi.getAllChatroomsGET(Constants))?.json;
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-sharp'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                  textTransform: 'capitalize',
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { color: theme.colors['Error'], marginRight: 70 }
            ),
            dimensions.width
          )}
        >
          {'Blocked Users'}
        </Text>
      </View>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <XanoApi.FetchGetBlockedUsersGET>
          {({ loading, error, data, refetchGetBlockedUsers }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <>
                  {!fetchData?.length ? null : (
                    <FlatList
                      data={fetchData}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'amrxfaJh'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Custom #ffffff'],
                                flexDirection: 'row',
                                height: 74,
                                justifyContent: 'space-between',
                                paddingLeft: 16,
                                paddingRight: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <Image
                              resizeMode={'cover'}
                              source={{
                                uri: `${listData?._profile_of_user?.profile_image?.url}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  { borderRadius: 22, height: 44, width: 44 }
                                ),
                                dimensions.width
                              )}
                            />
                            {/* details */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flex: 1,
                                  height: '100%',
                                  justifyContent: 'center',
                                  marginLeft: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* name */}
                              <Text
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 16,
                                      lineHeight: 20,
                                      textTransform: 'capitalize',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?._profile_of_user?.profile_name}
                              </Text>
                            </View>
                            {/* Actions */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flex: 0,
                                  flexDirection: 'row',
                                  height: '100%',
                                  justifyContent: 'flex-end',
                                  minWidth: 100,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Unblock */}
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setTempUser(
                                      (() => {
                                        const e = listData;
                                        console.log(e);
                                        return e;
                                      })()
                                    );
                                    setShowUnBlockConfirmationPopUp(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderRadius: 10,
                                      height: 48,
                                      justifyContent: 'center',
                                      minWidth: 22,
                                      padding: 3,
                                      paddingLeft: 5,
                                      paddingRight: 5,
                                      width: 48,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={theme.colors['Error']}
                                    name={'Ionicons/trash-outline'}
                                    size={24}
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 2 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </Pressable>
                            </View>
                          </View>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  )}
                </>
                {/* Empty State */}
                <>
                  {fetchData?.length ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['empty state'],
                          {
                            backgroundColor: theme.colors['Custom #ffffff'],
                            padding: 20,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 24,
                              marginBottom: 10,
                              opacity: 0.5,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'No users blocked yet'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Unblock Confirmation Pop up */}
                <>
                  {!showUnBlockConfirmationPopUp ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          bottom: 0,
                          left: 0,
                          position: 'absolute',
                          right: 0,
                          top: 0,
                        },
                        dimensions.width
                      )}
                    >
                      {/* BG */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Strong'],
                            bottom: 0,
                            left: 0,
                            opacity: 0.3,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                          dimensions.width
                        )}
                      />
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Pop up */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom #ffffff'],
                              borderColor: theme.colors['Divider'],
                              borderRadius: 10,
                              borderWidth: 1,
                              marginLeft: 30,
                              marginRight: 30,
                              overflow: 'hidden',
                              paddingTop: 25,
                              width: '80%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 17,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Unblock '}
                            {tempUser?._profile_of_user?.profile_name}
                            {'!'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 17,
                                  marginTop: 10,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Are you sure you want to unblock the user?'}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Divider'],
                                borderTopWidth: 2,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Cancel, */}
                            <Button
                              onPress={() => {
                                try {
                                  setShowUnBlockConfirmationPopUp(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Custom #ffffff'],
                                    borderColor: theme.colors['Divider'],
                                    borderRightWidth: 1,
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                    height: 50,
                                    width: '50%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Cancel'}
                            />
                            {/* UnBlock */}
                            <Button
                              loading={isDeleting}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setIsDeleting(true);
                                    const api_Response = (
                                      await xanoUnblockAUserDELETE.mutateAsync({
                                        blocked_users_id: tempUser?.id,
                                      })
                                    )?.json;
                                    setIsDeleting(false);
                                    setShowUnBlockConfirmationPopUp(false);
                                    if (api_Response?.status === 'Success!') {
                                      await refetchGetBlockedUsers();
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor:
                                      theme.colors['Custom #ffffff'],
                                    borderColor: theme.colors['Divider'],
                                    borderLeftWidth: 1,
                                    borderRadius: 0,
                                    color: theme.colors['Error'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    height: 50,
                                    width: '50%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Yes, UnBlock'}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              </>
            );
          }}
        </XanoApi.FetchGetBlockedUsersGET>
      </View>
      {/* Loading_View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Loading_View 2'],
                { flex: 1 }
              ),
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                }),
                dimensions.width
              )}
            >
              {'Fetching your blocked List...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(BlockedUsersScreen);
