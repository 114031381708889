import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const reverseGeocodeGET = (
  Constants,
  { api_key, latitude, longitude },
  handlers = {}
) =>
  fetch(
    `https://api.geocod.io/v1.7/reverse?q=${encodeQueryParam(
      `${
        typeof latitude === 'string' ? latitude : JSON.stringify(latitude ?? '')
      },${
        typeof longitude === 'string'
          ? longitude
          : JSON.stringify(longitude ?? '')
      }`
    )}&limit=1&api_key=${encodeQueryParam(
      `${typeof api_key === 'string' ? api_key : JSON.stringify(api_key ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useReverseGeocodeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['geoCodeReverseGeocodeGET', args],
    () => reverseGeocodeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['geoCodeReverseGeocodeGETS']),
    }
  );
};

export const FetchReverseGeocodeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  api_key,
  latitude,
  longitude,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useReverseGeocodeGET(
    { api_key, latitude, longitude },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReverseGeocode: refetch });
};
