import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Get_labels_from_bools from '../global-functions/Get_labels_from_bools';
import generateBranchIOLink from '../global-functions/generateBranchIOLink';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Checkbox,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

const PostDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [isReporting, setIsReporting] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [is_fav, setIs_fav] = React.useState(false);
  const [pin_icon, setPin_icon] = React.useState('');
  const [post_details, setPost_details] = React.useState({});
  const [showReportPostConfirmationView, setShowReportPostConfirmationView] =
    React.useState(false);
  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDataFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const postsFavoriteUnfavoriteAPostPOST =
    PostsApi.useFavoriteUnfavoriteAPostPOST();
  const xanoBlockAPostPOST = XanoApi.useBlockAPostPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await PostsApi.postDetailsGET(Constants, {
            posts_id: props.route?.params?.post_id ?? '',
          })
        )?.json;
        setPost_details(
          (() => {
            const e = result;
            console.log(e);
            return e;
          })()
        );
        setIs_fav(true);
        if (result?.type === 'Have') {
          setPin_icon(result?._material_types?.have_icon?.url);
        }
        if (result?.type === 'Need') {
          setPin_icon(result?._material_types?.need_icon?.url);
        }
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Container */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
              borderBottomWidth: 1,
              borderColor: theme.colors['Divider'],
              marginRight: 8,
            }),
            dimensions.width
          )}
        >
          {/* back */}
          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: 48,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Primary']}
                name={'Ionicons/chevron-back-outline'}
                size={28}
              />
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 15,
                  }),
                  dimensions.width
                )}
              >
                {'Back'}
              </Text>
            </View>
          </Pressable>
          {/* screen heading */}
          <>
            {!post_details?.type ? null : (
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                    { paddingLeft: 10, paddingRight: 22 }
                  ),
                  dimensions.width
                )}
              >
                {post_details?.type} {addCommasToNumber(post_details?.quantity)}
                {' CYD -  '}
                {post_details?._material_types?.name}
              </Text>
            )}
          </>
          {/* Favorite */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Nav Btn Phone'],
              dimensions.width
            )}
          >
            <Checkbox
              checkedIcon={'AntDesign/heart'}
              color={theme.colors['Primary Blue']}
              defaultValue={post_details?._is_favorite_of_user}
              onCheck={() => {
                const handler = async () => {
                  try {
                    const api_Response2 = (
                      await postsFavoriteUnfavoriteAPostPOST.mutateAsync({
                        posts_id: post_details?.id,
                      })
                    )?.json;

                    showAlertUtil({
                      title: api_Response2?.status,
                      message: 'Post added to favorites',
                      buttonText: 'Ok',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              onUncheck={() => {
                const handler = async () => {
                  try {
                    const api_Response = (
                      await postsFavoriteUnfavoriteAPostPOST.mutateAsync({
                        posts_id: post_details?.id,
                      })
                    )?.json;

                    showAlertUtil({
                      title: api_Response?.status,
                      message: 'Post removed from favorites.',
                      buttonText: 'Ok',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              uncheckedColor={theme.colors['Primary Blue']}
              uncheckedIcon={'AntDesign/hearto'}
            />
          </View>
          {/* Report */}
          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                setShowReportPostConfirmationView(true);
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: 48,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Primary']}
                name={'MaterialCommunityIcons/alert-octagon-outline'}
                size={28}
              />
            </View>
          </Pressable>
        </View>
        <>
          {is_Loading ? null : (
            <ScrollView
              bounces={true}
              contentContainerStyle={StyleSheet.applyWidth(
                { flex: 0, padding: 16 },
                dimensions.width
              )}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Map View */}
              <View
                style={StyleSheet.applyWidth({ height: 150 }, dimensions.width)}
              >
                <MapView
                  apiKey={'AIzaSyCqfSy6WqO1ho-saMDNPlLHLgNf9QaOW0g'}
                  latitude={post_details?.coordinates?.data?.lat}
                  loadingEnabled={true}
                  longitude={post_details?.coordinates?.data?.lng}
                  provider={'google'}
                  rotateEnabled={true}
                  scrollEnabled={true}
                  showsPointsOfInterest={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.MapViewStyles(theme)['Map View'],
                    dimensions.width
                  )}
                  zoom={12}
                  zoomEnabled={true}
                >
                  <MapMarker
                    description={post_details?.details}
                    latitude={post_details?.coordinates?.data?.lat}
                    longitude={post_details?.coordinates?.data?.lng}
                    pinImage={`${pin_icon}`}
                    pinImageSize={80}
                    title={post_details?.site_name}
                  />
                </MapView>
              </View>
              {/* Material type */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    height: 64,
                    justifyContent: 'space-between',
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Material Type:'}
                  </Text>
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {post_details?._material_types?.name}
                  </Text>
                </View>
                {/* Share */}
                <>
                  {Platform.OS === 'web' ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 48,
                          justifyContent: 'center',
                          width: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const link = await generateBranchIOLink(
                                post_details,
                                props.route?.params?.post_id ?? '',
                                undefined
                              );
                              await openShareUtil(`${link}`);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Primary Blue']}
                            name={'MaterialCommunityIcons/share'}
                            size={24}
                          />
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Share'}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
              </View>
              {/* Quantity */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Quantity:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {addCommasToNumber(post_details?.quantity)} {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        {
                          color: theme.colors['Medium'],
                          fontFamily: 'Poppins_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'CYD'}
                  </Text>
                </Text>
              </View>
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 8, minHeight: 64 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Location:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'capitalize',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {post_details?.location}
                </Text>
              </View>
              {/* Job site name */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12, minHeight: 64 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Job Site Name:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'capitalize',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {post_details?.site_name}
                </Text>
              </View>
              {/* Description */}
              <>
                {!post_details?.details ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 12, minHeight: 64 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Description:'}
                    </Text>
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {post_details?.details}
                    </Text>
                  </View>
                )}
              </>
              {/* Available from */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Available From:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {changeDataFormat(
                    post_details?.availability_date,
                    Constants['AppDateformat']
                  )}
                </Text>
              </View>
              {/* Available until */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Available Until:'}
                </Text>
                {/* Value */}
                <>
                  {!post_details?.expires_at ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {changeDataFormat(
                        post_details?.expires_at,
                        Constants['AppDateformat']
                      )}
                    </Text>
                  )}
                </>
                {/* Value */}
                <>
                  {post_details?.expires_at ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Always'}
                    </Text>
                  )}
                </>
              </View>
              {/* Price Preference */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Price Preference:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {post_details?.price_type}
                </Text>
              </View>
              {/* Price */}
              <>
                {!(post_details?.price_type === 'Fixed') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 64, marginTop: 8 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Price:'}
                    </Text>
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {post_details?.price}
                    </Text>
                  </View>
                )}
              </>
              {/* Proctor?  */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {!(post_details?.type === 'Need') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Material Proctor Required:'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(post_details?.type === 'Have') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Proctor Available:'}
                    </Text>
                  )}
                </>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(post_details?.material_proctor)}
                </Text>
              </View>
              {/* Trucking/ Hauling required? */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Trucking/ Hauling required:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(post_details?.is_trucking_required)}
                </Text>
              </View>
              {/* Load Materials */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Load Materials:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(
                    post_details?.ability_to_load_material
                  )}
                </Text>
              </View>
              {/* Attachment */}
              <>
                {!post_details?._post_attachments ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 64, marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('ImageScreen', {
                            URL: post_details?._post_attachments?.file?.url,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            height: 64,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Custom Color_33'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 17,
                                textDecorationColor:
                                  theme.colors['Custom Color_33'],
                                textDecorationLine: 'underline',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'View Attachment'}
                        </Text>
                        <Icon
                          color={theme.colors['Primary Blue']}
                          name={'Entypo/attachment'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 12 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                )}
              </>
              {/* Profile */}
              <>
                {!post_details?._profile_of_user ? null : (
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        navigation.navigate('UserProfileScreen', {
                          profile_id: post_details?._profile_of_user?.id,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Profile */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderColor: theme.colors['Divider'],
                          borderTopWidth: 1,
                          flexDirection: 'row',
                          marginTop: 20,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!post_details?._profile_of_user
                          ?.profile_image ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={{
                              uri: `${post_details?._profile_of_user?.profile_image?.url}`,
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { borderRadius: 8, height: 100 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <>
                        {post_details?._profile_of_user
                          ?.profile_image ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={{
                              uri: `${post_details?._profile_of_user?._user_types?.icon_path}`,
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 100 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <View
                        style={StyleSheet.applyWidth(
                          { marginLeft: 20, paddingTop: 5 },
                          dimensions.width
                        )}
                      >
                        {/* Name */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Poppins_500Medium', fontSize: 21 }
                            ),
                            dimensions.width
                          )}
                        >
                          {post_details?._profile_of_user?.profile_name}
                          {/* Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 21,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {null}
                          </Text>
                          {/* Name */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 21,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {null}
                            {/* Name */}
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 21,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {null}
                            </Text>
                          </Text>
                        </Text>
                        {/* Profile Type */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                            ),
                            dimensions.width
                          )}
                        >
                          {post_details?._profile_of_user?._user_types?.name}
                        </Text>

                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['ShopAppBlue'],
                                fontFamily: 'Poppins_400Regular',
                                paddingTop: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'See Profile'}
                        </Text>
                      </View>
                    </View>
                  </Pressable>
                )}
              </>
            </ScrollView>
          )}
        </>
        {/* CTAs */}
        <>
          {!post_details?.type ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderColor: theme.colors['Divider'],
                  borderTopWidth: 2,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  marginRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                  padding: 16,
                },
                dimensions.width
              )}
            >
              {/* Call */}
              <>
                {!post_details?._profile_of_user?._permissions_of_user
                  ?.privacy_call ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, paddingRight: 6 },
                      dimensions.width
                    )}
                  >
                    {/* Call */}
                    <Button
                      icon={'Ionicons/md-call-outline'}
                      onPress={() => {
                        try {
                          Linking.openURL(
                            `tel:${post_details?._profile_of_user?.phone_number}`
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Action Btn Phone'],
                          { fontSize: 17 }
                        ),
                        dimensions.width
                      )}
                      title={'  Call'}
                    />
                  </View>
                )}
              </>
              {/* Chat */}
              <>
                {!post_details?._profile_of_user?._permissions_of_user
                  ?.privacy_messenger ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, paddingLeft: 6, paddingRight: 6 },
                      dimensions.width
                    )}
                  >
                    {/* Chat */}
                    <Button
                      icon={'Ionicons/md-chatbubble-outline'}
                      onPress={() => {
                        try {
                          navigation.navigate('ChatScreen', {
                            profile_pic:
                              post_details?._profile_of_user?.profile_image
                                ?.url,
                            post_id: props.route?.params?.post_id ?? '',
                            Other_user_name:
                              post_details?._profile_of_user?.profile_name,
                            receiver_id:
                              post_details?._profile_of_user?.user_id,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Action Btn Phone'],
                          { fontSize: 17 }
                        ),
                        dimensions.width
                      )}
                      title={'  Chat'}
                    />
                  </View>
                )}
              </>
              {/* Email */}
              <>
                {!post_details?._profile_of_user?._permissions_of_user
                  ?.privacy_mail ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, paddingLeft: 6 },
                      dimensions.width
                    )}
                  >
                    {/* Email */}
                    <Button
                      icon={'MaterialCommunityIcons/email-outline'}
                      onPress={() => {
                        try {
                          Linking.openURL(
                            `mailto:${post_details?._profile_of_user?.email}`
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Action Btn Phone'],
                          { fontSize: 17 }
                        ),
                        dimensions.width
                      )}
                      title={'  Email'}
                    />
                  </View>
                )}
              </>
            </View>
          )}
        </>
        {/* Loading View */}
        <>
          {!is_Loading ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'large'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ],
                  dimensions.width
                )}
              />
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 21,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Please Wait\nFetcing the Post details...'}
              </Text>
            </View>
          )}
        </>
      </View>
      {/* Report Confirmation View */}
      <>
        {!showReportPostConfirmationView ? null : (
          <View
            style={StyleSheet.applyWidth(
              { bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 },
              dimensions.width
            )}
          >
            {/* BG */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.3,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            {/* Pop up View */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Pop up */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderColor: theme.colors['Divider'],
                    borderRadius: 10,
                    borderWidth: 1,
                    marginLeft: 30,
                    marginRight: 30,
                    paddingTop: 18,
                    width: '80%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 17,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Report and Remove!'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 17,
                      marginTop: 10,
                      paddingLeft: 15,
                      paddingRight: 15,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Are you sure to report and remove this post?'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Divider'],
                      borderTopWidth: 2,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* Cancel, */}
                  <Button
                    onPress={() => {
                      try {
                        setShowReportPostConfirmationView(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderColor: theme.colors['Divider'],
                          borderRightWidth: 1,
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          height: 50,
                          width: '50%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                  {/* Block */}
                  <Button
                    loading={isReporting}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setIsReporting(true);
                          const api_Response = (
                            await xanoBlockAPostPOST.mutateAsync({
                              posts_id: props.route?.params?.post_id ?? '',
                            })
                          )?.json;
                          setIsReporting(false);
                          console.log(api_Response);
                          setShowReportPostConfirmationView(false);
                          if (api_Response?.status === 'Success!') {
                            navigation.goBack();
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderColor: theme.colors['Divider'],
                          borderLeftWidth: 1,
                          borderRadius: 0,
                          color: theme.colors['Error'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          height: 50,
                          width: '50%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Report'}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(PostDetailsScreen);
