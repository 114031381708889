import React from 'react';
import { Touchable } from '@draftbit/ui';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as WebBrowser from 'expo-web-browser';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Google from 'expo-auth-session/providers/google';
//import * as Facebook from 'expo-auth-session/providers/facebook';
import jwtDecode from 'jwt-decode';

WebBrowser.maybeCompleteAuthSession();

export function GoogleWrapper({
  children,
  navigation,
  setErrorMessage,
  setFirst_name,
  setLast_name,
}) {
  const [accessToken, setAccessToken] = React.useState(null);
  const [type, setType] = React.useState(null);

  const variables = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const [request, response, promptAsync] = Google.useAuthRequest(
    {
      iosClientId:
        '552016975176-e28s54koch501193kl0ii4ih6qif4ha2.apps.googleusercontent.com', //variables.GOOGLE_IOS_CLIENT_ID,
      // androidClientId: '552016975176-e28s54koch501193kl0ii4ih6qif4ha2.apps.googleusercontent.com', //variables.GOOGLE_IOS_CLIENT_ID,
      // webClientId:"690674253190-nvho5rfppha7i7265cl00sbc28alg1im.apps.googleusercontent.com",

      androidClientId:
        '552016975176-62o4kqlfkqu7sdvmkksi8sd328kboo2s.apps.googleusercontent.com', //variables.GOOGLE_IOS_CLIENT_ID,
      webClientId:
        '552016975176-cjp8mlfvn019ql1l11n87c4fj95tqc49.apps.googleusercontent.com',
      expoClientId:
        '993304702394-iqhopj5dbabcoolvcakdfc63spaf4uu2.apps.googleusercontent.com', //variables.GOOGLE_IOS_CLIENT_ID,
    },
    {
      scheme: 'dirt-app://',
      path: '',
    }
  );

  React.useEffect(() => {
    if (response) {
      setType(response.type);
    }
  }, [response]);

  React.useEffect(() => {
    if (type) {
      if (type === 'success') {
        setAccessToken(response.authentication.accessToken);
      } else {
        // setErrorMessage('Error: Could not receive access token');
      }
    }
  }, [type]);

  React.useEffect(() => {
    if (accessToken) {
      const handler = async () => {
        try {
          setTimeout(async () => {
            // setIsLoading(true);
            signupwithGmail(
              {
                gmail_token: accessToken,
                device_type: 'iOS',
                setFirst_name,
                setLast_name,
              },
              navigation,
              setGlobalVariableValue,
              setErrorMessage
            );
            setIsLoading(false);
          }, 600);
        } catch (err) {
          console.error(err);
        }
      };
      handler();
    } else {
      return;
    }
  }, [accessToken]);

  return (
    <Touchable
      onPress={() => {
        try {
          promptAsync({ useProxy: false, showInRecents: true });
          // signupwithGmail(
          //   {
          //     gmail_token: "ya29.a0AbVbY6Ok3ICqkxtpp___WyZtbQ1c4KNpHG1vRZrmhTy4VsHoBPb0BNX8l6GPWoYYsEGa6O8sFF0VySSOWOtpCql_8KM-bDFzk5106dFE2Y98HhQBxR0mxF0oIuc8LSwA8dxkQS04-my2mrOk1z7U1QYDIv2KaCgYKAecSARISFQFWKvPl7rB1w0jTxINymuMHHVIgVA0163",
          //     device_type: 'iOS',
          //   },
          //   navigation,
          //   setGlobalVariableValue,
          //   setErrorMessage
          // );
        } catch (err) {
          setErrorMessage(err);
        }
      }}
    >
      {children}
    </Touchable>
  );
}

// export function FacebookWrapper({ children, navigation, setErrorMessage }) {
//   const variables = GlobalVariables.useValues();
//   const setGlobalVariableValue = GlobalVariables.useSetValue();

//   const [request, response, promptAsync] = Facebook.useAuthRequest({
//     clientId: variables.FACEBOOK_APP_ID,
//     scopes: ['public_profile', 'email'],
//   });

//   React.useEffect(() => {
//     console.log(response);
//     if (response && response.type === 'success' && response.authentication) {
//       const { access_token } = response.params;

//       (async () => {
//         const userInfoResponse = await fetch(
//           `https://graph.facebook.com/me?access_token=${access_token}&fields=id,name,email`
//         );
//         const userInfo = await userInfoResponse.json();
//         // console.log(userInfo);

//         setTimeout(async () => {
//           //   setIsLoading(true);
//           signupwithFacebook(
//             {
//               //email: response?.user?.profile?.email,
//               fb_token: access_token,
//               // gmail_token: '',
//               //name: `${userInfo?.name || ''}`,
//               device_type: 'iOS',
//               // device_token: access_token,
//               // password: '12345678',
//             },
//             navigation,
//             setGlobalVariableValue,
//             //  setErrorMessage
//           );
//           //  setIsLoading(false);
//         }, 600);
//       })();
//     }
//   }, [response]);

//   return (
//     <Touchable
//       onPress={() => {
//         try {
//           promptAsync({ useProxy: false, showInRecents: true });
//         } catch (err) {
//           setErrorMessage(JSON.stringify(err));
//         }
//       }}
//     >
//       {children}
//     </Touchable>
//   );
// }

export function AppleWrapper({
  children,
  setIsLoading,
  setErrorMessage,
  isLoading,
  navigation,
}) {
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const [appleAuthAvailable, setAppleAuthAvailable] = React.useState(false);

  React.useEffect(() => {
    const checkAvailable = async () => {
      const isAvailable = await AppleAuthentication.isAvailableAsync();
      setAppleAuthAvailable(isAvailable);
    };
    checkAvailable();
  }, []);

  const login = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      const decoded = jwtDecode(credential?.identityToken);

      setTimeout(() => {
        console.log(credential, decoded);
      }, 3000);

      setTimeout(async () => {
        //  setIsLoading(true);
        signupwithApple(
          {
            email: decoded?.email,
            name: `${credential?.fullName.givenName || ''} ${
              credential?.fullName.familyName || ''
            }`,
            device_type: 'iOS',
            user_id: credential?.user,
          },
          navigation,
          setGlobalVariableValue,
          setErrorMessage
        );
        //setIsLoading(false);
      }, 600);
    } catch (e) {
      console.log(e);
    }
  };

  if (appleAuthAvailable) {
    return (
      <Touchable onPress={login} disabled={!appleAuthAvailable || isLoading}>
        {children}
      </Touchable>
    );
  }
  return null;
}

const signupWithSocial = async (
  data,
  navigation,
  setGlobal,
  setErrorMessage
) => {
  const {
    name,
    email,
    device_type,
    device_token,
    gmail_token,
    facebook_token,
    password,
  } = data;

  const response = await fetch(`https://www.dirtnearme.com/api/social_login`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      facebook_token,
      gmail_token,
      device_type,
      device_token,
      password,
      name,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  const token = responseData?.auth_token;
  const profile = responseData?._profile_of_user;

  if (responseData?.message) {
    setErrorMessage(responseData.message); //data?.message)
    return;
  }

  if (token) {
    setGlobal({
      key: 'AUTH_TOKEN',
      value: 'Bearer ' + token,
    });
    setGlobal({
      key: 'User_Details',
      value: responseData?.user,
    });
  }
  if (profile) {
    //  console.log( responseData?.data?.user)

    navigation.navigate('HomeScreen');
  } else {
    navigation.navigate('LoginScreen', {
      fullName: name,
      email,
    });
  }
};

const signupwithApple = async (
  data,
  navigation,
  setGlobal,
  setErrorMessage
) => {
  const { name, email, device_type, user_id } = data;

  console.log('befre fetch');
  const response = await fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/apple?email=${email}&device_type=${device_type}&name=${name}&user_id=${user_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const responseData = await response.json();
  const token = responseData?.auth_token;
  const profile = responseData?.user?._profile_of_user;
  console.log('after call', responseData);

  if (responseData?.message) {
    //  setErrorMessage(responseData.message); //data?.message)
  }
  if (token) {
    setGlobal({
      key: 'AUTH_TOKEN',
      value: 'Bearer ' + token,
    });
    setGlobal({
      key: 'User_Details',
      value: responseData?.user,
    });
    setGlobal({
      key: 'isAppleLogin',
      value: true,
    });

    console.log(responseData?.user);
  }

  console.log('after call', profile);

  if (profile) {
    console.log('in profile');

    navigation.navigate('BottomTabNavigator', {
      screen: 'HomeScreen',
    });
    // navigation.navigate('HomeScreen');
  } else {
    navigation.navigate('SocialCompleteProfileScreen', {
      first_name: responseData?.user?.first_name,
      last_name: responseData?.user?.last_name,
      email: responseData?.user?.email,
    });
  }
};

// const signupwithFacebook = async (
//   data,
//   navigation,
//   setGlobal,
//   setErrorMessage
// ) => {
//   const {
//     fb_token,
//     // email,
//     device_type,
//     // user_id,
//   } = data;

//   console.log('befre fetch')
//   const response = await fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/facebook?token=${fb_token}&device_type=${device_type}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   const responseData = await response.json();
//   const token = responseData?.auth_token;
//   const profile = responseData?.user?._profile_of_user;
//   console.log('after call', responseData);

//   if (responseData?.message) {
//     //  setErrorMessage(responseData.message); //data?.message)
//   }
//   if (token) {
//     setGlobal({
//       key: 'AUTH_TOKEN',
//       value: 'Bearer ' + token,
//     });
//     setGlobal({
//       key: 'User_Details',
//       value: responseData?.user,
//     });
//      console.log( responseData?.user)
//   }

//   console.log('after call', profile);

//   if (profile) {
//     console.log('in profile');

//     navigation.navigate('BottomTabNavigator', {
//       screen: 'HomeScreen',
//     });
//     // navigation.navigate('HomeScreen');
//   } else {
//     navigation.navigate('SocialCompleteProfileScreen', {
//       fullName: name,
//       email,
//     });
//   }
// };

const signupwithGmail = async (
  data,
  navigation,
  setGlobal,
  setErrorMessage
) => {
  const {
    gmail_token,
    // email,
    device_type,
    // user_id,
    setFirst_name,
    setLast_name,
  } = data;

  console.log('befre fetch');
  const response = await fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/google?token=${gmail_token}&device_type=${device_type}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const responseData = await response.json();
  const token = responseData?.auth_token;
  const profile = responseData?.user?._profile_of_user;
  console.log('after call', responseData);

  if (responseData?.message) {
    //  setErrorMessage(responseData.message); //data?.message)
  }
  if (token) {
    setGlobal({
      key: 'AUTH_TOKEN',
      value: 'Bearer ' + token,
    });
    setGlobal({
      key: 'User_Details',
      value: responseData?.user,
    });

    console.log(responseData?.user);
  }

  console.log('after call', profile);

  if (profile) {
    console.log('in profile');
    navigation.navigate('BottomTabNavigator', {
      screen: 'HomeScreen',
    });
  } else {
    navigation.navigate('SocialCompleteProfileScreen', {
      first_name: responseData?.user?.first_name,
      last_name: responseData?.user?.last_name,
      email: responseData?.user?.email,
    });
  }
};
