import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const MyPostsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [All_Posts, setAll_Posts] = React.useState([]);
  const [is_loading, setIs_loading] = React.useState(true);
  const [selected_Menu, setSelected_Menu] = React.useState('Active');
  const filterPostsByStatusType = (list, status) => {
    if (status === 'Fulfilled') return list.filter(item => item.fulfilled);
    else if (status === 'Expired')
      return list.filter(
        item => item.expires_at && item.expires_at < Date.now()
      );
    else
      return list.filter(
        item =>
          !item.fulfilled && !(item.expires_at && item.expires_at < Date.now())
      );
  };

  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await PostsApi.myPostsGET(Constants))?.json;
        setAll_Posts(result);
        setIs_loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Matched_posts_count */}
      <PostsApi.FetchMatchedPostsCountGET
        handlers={{
          onData: matchedPostsCountData => {
            try {
              setGlobalVariableValue({
                key: 'matched_posts_count',
                value: matchedPostsCountData?.count,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        refetchInterval={Constants['polling_interval']}
      >
        {({ loading, error, data, refetchMatchedPostsCount }) => {
          const matchedPostsCountData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </PostsApi.FetchMatchedPostsCountGET>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
            paddingLeft: 8,
            paddingRight: 8,
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Screen heading Phone'],
            dimensions.width
          )}
        >
          {'My Posts'}
        </Text>
      </View>
      {/* Top Menu */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'stretch',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 20,
            paddingBottom: 8,
            paddingTop: 8,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Light Button Color'],
              borderRadius: 9,
              flexDirection: 'row',
              height: 32,
              justifyContent: 'space-between',
              padding: 2,
            },
            dimensions.width
          )}
        >
          {/* Active */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '33%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Active') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Active'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Active' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Active');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Active'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
          {/* Fulfilled */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '33%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Fulfilled') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Fulfilled'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Fulfilled' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Fulfilled');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Fulfilled'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
          {/* Expired */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '33%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Expired') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Expired'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Expired' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Expired');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Expired'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
        </View>
      </View>
      {/* Active Posts */}
      <>
        {!(selected_Menu === 'Active') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Custom #ffffff'], flex: 1 },
              dimensions.width
            )}
          >
            <FlatList
              data={filterPostsByStatusType(All_Posts, 'Approved')}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              listKey={'hNJWHECm'}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        navigation.navigate('MyPostsDetailsActiveScreen', {
                          post_id: listData?.id,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          minHeight: 120,
                          paddingRight: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            height: '100%',
                            marginRight: 5,
                            width: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {!(listData?.type === 'Need') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Primary Yellow'],
                                  height: '100%',
                                  width: 15,
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {!(listData?.type === 'Have') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Primary Blue'],
                                  height: '100%',
                                  width: 15,
                                },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, paddingLeft: 6, paddingRight: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 17,
                                  lineHeight: 22,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.type}{' '}
                            {addCommasToNumber(listData?.quantity)}
                            {' CYD -  '}
                            {listData?._material_types?.name}
                          </Text>

                          <Text
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_300Light',
                                  fontSize: 15,
                                  lineHeight: 24,
                                  marginTop: 2,
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.location}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['post_list_date'],
                              dimensions.width
                            )}
                          >
                            {'Available From: '}
                            {changeDateFormat(
                              listData?.availability_date,
                              Constants['AppDateformat']
                            )}
                          </Text>
                          <>
                            {!listData?.expires_at ? null : (
                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'post_list_date'
                                  ],
                                  dimensions.width
                                )}
                              >
                                {'Available Until: '}
                                {changeDateFormat(
                                  listData?.expires_at,
                                  Constants['AppDateformat']
                                )}
                              </Text>
                            )}
                          </>
                          <>
                            {listData?.expires_at ? null : (
                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'post_list_date'
                                  ],
                                  dimensions.width
                                )}
                              >
                                {'Available Until: Always'}
                              </Text>
                            )}
                          </>
                        </View>
                        <>
                          {!listData?.match_count ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Light'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 11,
                                      marginRight: 8,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'See Matches'}
                              </Text>

                              <Circle bgColor={theme.colors['Error']} size={18}>
                                {/* Text 2 */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Custom #ffffff'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 10,
                                        lineHeight: 16,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.match_count}
                                </Text>
                              </Circle>
                            </View>
                          )}
                        </>
                        <Icon
                          color={theme.colors['Primary']}
                          name={'Feather/chevron-right'}
                          size={24}
                        />
                      </View>
                    </View>
                  </Pressable>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            />
            {/* empty state */}
            <>
              {filterPostsByStatusType(All_Posts, 'Approved')?.length ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 2'],
                      { justifyContent: 'flex-start', margin: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You don’t have posts yet.\nCreate one by clicking on “Create Post”'
                    }
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Loading_View */}
      <>
        {!is_loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Loading_View'],
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching your posts...'}
            </Text>
          </View>
        )}
      </>
      {/* Fulfilled Posts */}
      <>
        {!(selected_Menu === 'Fulfilled') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Custom #ffffff'], flex: 1 },
              dimensions.width
            )}
          >
            <>
              {!filterPostsByStatusType(All_Posts, 'Fulfilled') ? null : (
                <FlatList
                  data={filterPostsByStatusType(All_Posts, 'Fulfilled')}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'jeYaYnf8'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            navigation.navigate(
                              'MyPostsDetailsFulfilledScreen',
                              { post_id: listData?.id }
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* colorCode */}
                          <View
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)['colorCode 2'],
                              dimensions.width
                            )}
                          >
                            <>
                              {!(listData?.type === 'Need') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Primary Yellow'],
                                      height: '100%',
                                      width: 15,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!(listData?.type === 'Have') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Primary Blue'],
                                      height: '100%',
                                      width: 15,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Divider'],
                                flex: 1,
                                flexDirection: 'row',
                                height: 120,
                                justifyContent: 'space-between',
                                paddingRight: 10,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1, marginLeft: 6, paddingRight: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 17,
                                      lineHeight: 22,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.type}{' '}
                                {addCommasToNumber(listData?.quantity)}
                                {' CYD - '}
                                {listData?._material_types?.name}
                              </Text>

                              <Text
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_300Light',
                                      fontSize: 15,
                                      lineHeight: 24,
                                      marginTop: 2,
                                      textTransform: 'capitalize',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.location}
                              </Text>

                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'post_list_date'
                                  ],
                                  dimensions.width
                                )}
                              >
                                {'Available From: '}
                                {changeDateFormat(
                                  listData?.availability_date,
                                  Constants['AppDateformat']
                                )}
                              </Text>
                              <>
                                {!listData?.expires_at ? null : (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'post_list_date'
                                      ],
                                      dimensions.width
                                    )}
                                  >
                                    {'Available Until: '}
                                    {changeDateFormat(
                                      listData?.expires_at,
                                      Constants['AppDateformat']
                                    )}
                                  </Text>
                                )}
                              </>
                              <>
                                {listData?.expires_at ? null : (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'post_list_date'
                                      ],
                                      dimensions.width
                                    )}
                                  >
                                    {'Available Until: Always'}
                                  </Text>
                                )}
                              </>
                            </View>
                            <Icon
                              color={theme.colors['Primary']}
                              name={'Feather/chevron-right'}
                              size={24}
                            />
                          </View>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
            {/* empty state */}
            <>
              {filterPostsByStatusType(All_Posts, 'Fulfilled')
                ?.length ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 2'],
                      { justifyContent: 'flex-start', margin: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You don’t have posts yet.\nCreate one by clicking on “Create Post”'
                    }
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Expired Posts */}
      <>
        {!(selected_Menu === 'Expired') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Custom #ffffff'], flex: 1 },
              dimensions.width
            )}
          >
            <>
              {!(
                filterPostsByStatusType(All_Posts, 'Expired')?.length > 0
              ) ? null : (
                <FlatList
                  data={filterPostsByStatusType(All_Posts, 'Expired')}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'o2k4i3Dy'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            navigation.navigate('MyPostsDetailsExpiredScreen', {
                              post_id: listData?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* colorCode */}
                          <View
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)['colorCode 3'],
                              dimensions.width
                            )}
                          >
                            <>
                              {!(listData?.type === 'Need') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Primary Yellow'],
                                      height: '100%',
                                      width: 15,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!(listData?.type === 'Have') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Primary Blue'],
                                      height: '100%',
                                      width: 15,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Divider'],
                                flex: 1,
                                flexDirection: 'row',
                                height: 120,
                                justifyContent: 'space-between',
                                paddingRight: 10,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1, marginLeft: 6, paddingRight: 16 },
                                dimensions.width
                              )}
                            >
                              <Text
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 17,
                                      lineHeight: 22,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.type}{' '}
                                {addCommasToNumber(listData?.quantity)}
                                {' CYD - '}
                                {listData?._material_types?.name}
                              </Text>

                              <Text
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_300Light',
                                      fontSize: 15,
                                      lineHeight: 24,
                                      marginTop: 2,
                                      textTransform: 'capitalize',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.location}
                              </Text>

                              <Text
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'post_list_date'
                                  ],
                                  dimensions.width
                                )}
                              >
                                {'Available From: '}
                                {changeDateFormat(
                                  listData?.availability_date,
                                  Constants['AppDateformat']
                                )}
                              </Text>
                              <>
                                {!listData?.expires_at ? null : (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'post_list_date'
                                      ],
                                      dimensions.width
                                    )}
                                  >
                                    {'Available Until: '}
                                    {changeDateFormat(
                                      listData?.expires_at,
                                      Constants['AppDateformat']
                                    )}
                                  </Text>
                                )}
                              </>
                              <>
                                {listData?.expires_at ? null : (
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'post_list_date'
                                      ],
                                      dimensions.width
                                    )}
                                  >
                                    {'Available Until: Always'}
                                  </Text>
                                )}
                              </>
                            </View>
                            <Icon
                              color={theme.colors['Primary']}
                              name={'Feather/chevron-right'}
                              size={24}
                            />
                          </View>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
            {/* empty state */}
            <>
              {!(
                filterPostsByStatusType(All_Posts, 'Expired')?.length === 0
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 2'],
                      { margin: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You don’t have posts yet.\nCreate one by clicking on “Create Post”'
                    }
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* tabbar */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['tabbar'], {
            paddingLeft: { minWidth: Breakpoints.Tablet, value: '20%' },
            paddingRight: { minWidth: Breakpoints.Tablet, value: '20%' },
          }),
          dimensions.width
        )}
      >
        {/* Home */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'HomeScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.Home}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                dimensions.width
              )}
            >
              {'Home'}
            </Text>
          </View>
        </Pressable>
        {/* My Posts */}
        <Pressable
          activeOpacity={0.3}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyPostsSelected}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary Blue'] }
                ),
                dimensions.width
              )}
            >
              {'My Posts'}
            </Text>
            {/* Post_Badge */}
            <>
              {!Constants['matched_posts_count'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Post_Badge'],
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Custom #ffffff'],
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['matched_posts_count']}
                  </Text>
                </View>
              )}
            </>
          </View>
        </Pressable>
        {/* Create Post */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('CreatePostScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, marginTop: -58, width: '24%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                borderColor: theme.colors['Divider'],
                borderTopLeftRadius: 45,
                borderTopRightRadius: 45,
                borderTopWidth: 1,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            <Circle bgColor={theme.colors['Custom Color_25']} size={40}>
              <Icon
                color={theme.colors['Custom #ffffff']}
                name={'Entypo/plus'}
                size={24}
              />
            </Circle>

            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'], marginTop: 7 }
                ),
                dimensions.width
              )}
            >
              {'Create Post'}
            </Text>
          </View>
        </Pressable>
        {/* Favorites */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'FavouritesScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.Favorites}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'Favorites'}
            </Text>
          </View>
        </Pressable>
        {/* My Profile */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'MyProfileScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyProfile}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'My Profile'}
            </Text>
          </View>
        </Pressable>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(MyPostsScreen);
