import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Get_labels_from_bools from '../global-functions/Get_labels_from_bools';
import generateBranchIOLink from '../global-functions/generateBranchIOLink';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

const MyPostsDetailsActiveScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [delete_Confirm, setDelete_Confirm] = React.useState(false);
  const [isFulfilling, setIsFulfilling] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [matchedPostsList, setMatchedPostsList] = React.useState([]);
  const [pin_icon, setPin_icon] = React.useState('');
  const [post_Details, setPost_Details] = React.useState({});
  const [selected_Menu, setSelected_Menu] = React.useState('Matches');
  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };

  const myFunctionName = (dump, position, listItem) => {
    // console.log([position, listItem])

    if (position == 1) {
      if (matchedPostsList.findIndex(item => item.id === listItem.id) === 0)
        return true;
    }
    if (position == 2) {
      if (matchedPostsList.findIndex(item => item.id === listItem.id) === 1)
        return true;
    }
    if (position == 3) {
      if (matchedPostsList.findIndex(item => item.id === listItem.id) === 2)
        return true;
    }

    return false;
  };
  const postsDeleteAPostDELETE = PostsApi.useDeleteAPostDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await PostsApi.postDetailsGET(Constants, {
            posts_id: props.route?.params?.post_id ?? 73,
          })
        )?.json;
        setPost_Details(
          (() => {
            const e = result;
            console.log(e);
            return e;
          })()
        );
        if (result?.type === 'Have') {
          setPin_icon(result?._material_types?.have_icon?.url);
        }
        if (result?.type === 'Need') {
          setPin_icon(result?._material_types?.need_icon?.url);
        }
        const matchedPosts = (
          await PostsApi.matchedPostsGET(Constants, {
            post_id: props.route?.params?.post_id ?? 73,
          })
        )?.json;
        setMatchedPostsList(matchedPosts);
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <>
          {is_Loading ? null : (
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                dimensions.width
              )}
            >
              {post_Details?.type} {addCommasToNumber(post_Details?.quantity)}
              {' CYD - '}
              {post_Details?._material_types?.name}
            </Text>
          )}
        </>
        {/* Edit */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('EditPostDetailsScreen', {
                post_id: props.route?.params?.post_id ?? 73,
                reactivate_Post: false,
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
              { marginRight: 5 }
            ),
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Nav Btn Bigscreen'],
                { flexDirection: 'row' }
              ),
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Feather/edit'}
              size={24}
            />
          </View>
        </Pressable>
      </View>
      {/* Top Menu */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['top menu'], {
            paddingLeft: { minWidth: Breakpoints.BigScreen, value: '20%' },
            paddingRight: { minWidth: Breakpoints.BigScreen, value: '20%' },
          }),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Light Button Color'],
              borderRadius: 9,
              flexDirection: 'row',
              height: 32,
              justifyContent: 'space-between',
              padding: 2,
            },
            dimensions.width
          )}
        >
          {/* Matches */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '48%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Matches') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Matches'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Matches' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Matches');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Matches'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
          {/* Details */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '48%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Details') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Details'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Details' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Details');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Post Details'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
        </View>
      </View>
      {/* Content */}
      <>
        {is_Loading ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Details */}
            <>
              {!(selected_Menu === 'Details') ? null : (
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <ScrollView
                    bounces={true}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { padding: 16, paddingTop: 0 },
                      dimensions.width
                    )}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  >
                    {/* Map View */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 150 },
                        dimensions.width
                      )}
                    >
                      <MapView
                        apiKey={'AIzaSyCqfSy6WqO1ho-saMDNPlLHLgNf9QaOW0g'}
                        latitude={post_Details?.coordinates?.data?.lat}
                        loadingEnabled={true}
                        longitude={post_Details?.coordinates?.data?.lng}
                        provider={'google'}
                        rotateEnabled={true}
                        scrollEnabled={false}
                        showsPointsOfInterest={true}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.MapViewStyles(theme)['Map View'],
                          dimensions.width
                        )}
                        zoom={16}
                        zoomEnabled={false}
                      >
                        <MapMarker
                          description={post_Details?.details}
                          latitude={post_Details?.coordinates?.data?.lat}
                          longitude={post_Details?.coordinates?.data?.lng}
                          pinImage={`${pin_icon}`}
                          pinImageSize={80}
                          title={post_Details?.site_name}
                        />
                      </MapView>
                    </View>
                    {/* Material type */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'row',
                          height: 64,
                          justifyContent: 'space-between',
                          marginTop: 8,
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        {/* Label */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Material Type:'}
                        </Text>
                        {/* Value */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              { color: theme.colors['Medium'] }
                            ),
                            dimensions.width
                          )}
                        >
                          {post_Details?._material_types?.name}
                        </Text>
                      </View>
                      {/* share view */}
                      <>
                        {Platform.OS === 'web' ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                height: 48,
                                justifyContent: 'center',
                                width: 48,
                              },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              activeOpacity={0.3}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const link = await generateBranchIOLink(
                                      post_Details,
                                      props.route?.params?.post_id ?? 73,
                                      'Active'
                                    );
                                    await openShareUtil(`${link}`);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flex: 1,
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Primary Blue']}
                                  name={'MaterialCommunityIcons/share'}
                                  size={24}
                                />
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                        textAlign: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Share'}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Quantity */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Quantity:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            { color: theme.colors['Medium'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {addCommasToNumber(post_Details?.quantity)}
                        {' CYD'}
                      </Text>
                    </View>
                    {/* Location */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 8, minHeight: 64 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Location:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              textTransform: 'capitalize',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {post_Details?.location}
                      </Text>
                    </View>
                    {/* Job site name */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 12, minHeight: 64 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Job Site Name:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              textTransform: 'capitalize',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {post_Details?.site_name}
                      </Text>
                    </View>
                    {/* Description */}
                    <>
                      {!post_Details?.details ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 12, minHeight: 64 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Description:'}
                          </Text>
                          {/* Value */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Label Values'],
                                {
                                  color: theme.colors['Medium'],
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {post_Details?.details}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Available from */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 12 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Available From:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            { color: theme.colors['Medium'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {changeDateFormat(
                          post_Details?.availability_date,
                          Constants['AppDateformat']
                        )}
                      </Text>
                    </View>
                    {/* Available until */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Available Until:'}
                      </Text>
                      {/* Value */}
                      <>
                        {!post_Details?.expires_at ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Label Values'],
                                { color: theme.colors['Medium'] }
                              ),
                              dimensions.width
                            )}
                          >
                            {changeDateFormat(
                              post_Details?.expires_at,
                              Constants['AppDateformat']
                            )}
                          </Text>
                        )}
                      </>
                      {/* Value */}
                      <>
                        {post_Details?.expires_at ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Label Values'],
                                { color: theme.colors['Medium'] }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Always'}
                          </Text>
                        )}
                      </>
                    </View>
                    {/* Price Preference */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Price Preference:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            { color: theme.colors['Medium'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {post_Details?.price_type}
                      </Text>
                    </View>
                    {/* Price */}
                    <>
                      {!(post_Details?.price_type === 'Fixed') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { height: 64, marginTop: 8 },
                            dimensions.width
                          )}
                        >
                          {/* Label */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Price:'}
                          </Text>
                          {/* Value */}
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Label Values'],
                                { color: theme.colors['Medium'] }
                              ),
                              dimensions.width
                            )}
                          >
                            {post_Details?.price}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Proctor?  */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <>
                        {!(post_Details?.type === 'Need') ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Material Proctor Required:'}
                          </Text>
                        )}
                      </>
                      {/* Label */}
                      <>
                        {!(post_Details?.type === 'Have') ? null : (
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Proctor Available:'}
                          </Text>
                        )}
                      </>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Get_labels_from_bools(post_Details?.material_proctor)}
                      </Text>
                    </View>
                    {/* Trucking/ Hauling required? */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Trucking/ Hauling required:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Get_labels_from_bools(
                          post_Details?.is_trucking_required
                        )}
                      </Text>
                    </View>
                    {/* Load Materials */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 64, marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Load Materials:'}
                      </Text>
                      {/* Value */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Get_labels_from_bools(
                          post_Details?.ability_to_load_material
                        )}
                      </Text>
                    </View>
                    {/* Attachment */}
                    <>
                      {!post_Details?._post_attachments ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['Attachment'],
                            dimensions.width
                          )}
                        >
                          <Pressable
                            onPress={() => {
                              try {
                                navigation.navigate('ImageScreen', {
                                  URL: post_Details?._post_attachments?.file
                                    ?.url,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  borderTopWidth: 1,
                                  flexDirection: 'row',
                                  height: 64,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Custom Color_33'],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 17,
                                      textDecorationColor:
                                        theme.colors['Custom Color_33'],
                                      textDecorationLine: 'underline',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'View Attachment'}
                              </Text>
                              <Icon
                                color={theme.colors['Primary Blue']}
                                name={'Entypo/attachment'}
                                size={24}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 12 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Pressable>
                        </View>
                      )}
                    </>
                  </ScrollView>
                  {/* CTAs */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'stretch',
                        borderColor: theme.colors['Divider'],
                        borderTopWidth: 2,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                        padding: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Fulfilled Post */}
                    <Button
                      loading={isFulfilling}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setIsFulfilling(true);
                            const api_Response = (
                              await PostsApi.markPostFulfilledGET(Constants, {
                                posts_id: props.route?.params?.post_id ?? 73,
                              })
                            )?.json;
                            setIsFulfilling(false);
                            if (api_Response?.status === 'Success!') {
                              showAlertUtil({
                                title: 'Fulfilled',
                                message: 'Post has been marked as fulfilled.',
                                buttonText: 'OK',
                              });
                            }
                            if (api_Response?.status === 'Success!') {
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)[
                            'Action Btn Bigscreen'
                          ],
                          { fontFamily: 'Poppins_500Medium', fontSize: 17 }
                        ),
                        dimensions.width
                      )}
                      title={'Fulfilled Post'}
                    />
                    {/* Delete post */}
                    <Button
                      onPress={() => {
                        try {
                          setDelete_Confirm(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)[
                            'Action Btn Bigscreen'
                          ],
                          {
                            backgroundColor: theme.colors['Custom Color_26'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 17,
                            marginTop: 8,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Delete post'}
                    />
                  </View>

                  <ActionSheet visible={delete_Confirm}>
                    {/* Delete */}
                    <ActionSheetItem
                      color={theme.colors.strong}
                      label={'DELETE'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setDelete_Confirm(false);
                            const result = (
                              await postsDeleteAPostDELETE.mutateAsync({
                                posts_id: props.route?.params?.post_id ?? 73,
                              })
                            )?.json;
                            console.log(result);
                            if (result?.status === 'Success!') {
                              showAlertUtil({
                                title: 'Deleted',
                                message: 'Post has been deleted successfully',
                                buttonText: 'Ok',
                              });
                            }
                            if (result?.status === 'Success!') {
                              navigation.goBack();
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActionSheetItemStyles(theme)[
                          'Action Sheet Item'
                        ],
                        dimensions.width
                      )}
                    />
                    {/* Cancel */}
                    <ActionSheetCancel
                      label={'Cancel'}
                      onPress={() => {
                        try {
                          setDelete_Confirm(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </ActionSheet>
                </View>
              )}
            </>
            {/* Matches */}
            <>
              {!(selected_Menu === 'Matches') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  <FlatList
                    data={matchedPostsList}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'7j5KbDh4'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* post block */}
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                navigation.navigate('PostDetailsScreen', {
                                  post_id: listData?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderColor: theme.colors['Divider'],
                                  borderTopRightRadius: 8,
                                  borderTopWidth: 1,
                                  flexDirection: 'row',
                                  height: 118,
                                  justifyContent: 'space-between',
                                  paddingRight: 16,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: 1,
                                    marginLeft: 6,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Poppins_500Medium',
                                        fontSize: 16,
                                        lineHeight: 22,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.type}{' '}
                                  {addCommasToNumber(listData?.quantity)}
                                  {' CYD - '}
                                  {listData?._material_types?.name}
                                </Text>

                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Poppins_300Light',
                                        fontSize: 15,
                                        lineHeight: 24,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.location}
                                </Text>

                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Poppins_300Light' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Available From : '}
                                  {changeDateFormat(
                                    listData?.availability_date,
                                    Constants['AppDateformat']
                                  )}
                                </Text>
                                <>
                                  {!listData?.expires_at ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Poppins_300Light' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Available Until  : '}
                                      {changeDateFormat(
                                        listData?.expires_at,
                                        Constants['AppDateformat']
                                      )}
                                    </Text>
                                  )}
                                </>
                                <>
                                  {listData?.expires_at ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          { fontFamily: 'Poppins_300Light' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Available Until  : Always'}
                                    </Text>
                                  )}
                                </>
                              </View>
                              <>
                                {!myFunctionName(
                                  isFulfilling,
                                  1,
                                  listData
                                ) ? null : (
                                  <Image
                                    resizeMode={'cover'}
                                    source={Images.Medallions}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        {
                                          height: 24,
                                          marginBottom: 50,
                                          marginRight: 10,
                                          width: 24,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <>
                                {!myFunctionName(
                                  isFulfilling,
                                  2,
                                  listData
                                ) ? null : (
                                  <Image
                                    resizeMode={'cover'}
                                    source={Images.Silver}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        {
                                          height: 24,
                                          marginBottom: 50,
                                          marginRight: 10,
                                          width: 24,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <>
                                {!myFunctionName(
                                  isFulfilling,
                                  3,
                                  listData
                                ) ? null : (
                                  <Image
                                    resizeMode={'cover'}
                                    source={Images.Bronze}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        {
                                          height: 24,
                                          marginBottom: 50,
                                          marginRight: 10,
                                          width: 24,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Feather/chevron-right'}
                                size={24}
                              />
                            </View>
                          </Pressable>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                  {/* empty state */}
                  <>
                    {matchedPostsList?.length ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['empty state 4'],
                            {
                              justifyContent: 'flex-start',
                              marginBottom: 20,
                              padding: 20,
                              paddingTop: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 22,
                                lineHeight: 28,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            "You don't have any matches for this post yet. Check back soon!"
                          }
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!matchedPostsList?.length ? null : (
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            navigation.navigate('NearbyBusinessesScreen', {
                              post_id: props.route?.params?.post_id ?? 73,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors['Primary Blue'],
                              borderBottomWidth: 1,
                              borderColor: theme.colors['Custom #acacac'],
                              borderRadius: 5,
                              borderTopWidth: 1,
                              borderWidth: 1,
                              flexDirection: 'row',
                              height: 60,
                              justifyContent: 'space-between',
                              margin: 16,
                              padding: 16,
                              paddingRight: 16,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Custom #ffffff'],
                                  fontFamily: 'Poppins_400Regular',
                                  marginRight: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'See Matches to Local Quarries & Dump Sites'}
                          </Text>
                          <Icon
                            color={theme.colors['Custom #ffffff']}
                            name={'Feather/chevron-right'}
                            size={24}
                            style={StyleSheet.applyWidth(
                              { right: 10 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </Pressable>
                    )}
                  </>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching details...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(MyPostsDetailsActiveScreen);
