import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { Alert, Image, Platform, Text, View } from 'react-native';

const SignupAppPermissionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [locationLoading, setLocationLoading] = React.useState(false);
  const [locationPermissionResponse, setLocationPermissionResponse] =
    React.useState({});
  const [notificationCheckBox, setNotificationCheckBox] = React.useState(false);
  const [pushToken, setPushToken] = React.useState('');
  const registerForPushNotificationsAsync = async () => {
    let token;

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    // if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    } else {
      //  Alert.alert('Already Granted', 'You have already granted permission to Dirt Near Me to send you push notifications!');
    }
    if (finalStatus !== 'granted') {
      alert(
        'Failed to get push token for push notification! Please check the device settings to enable the notifications'
      );
      return;
    }
    // Learn more about projectId:
    // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: '3bf1145e-fdd9-462d-8fd8-14a6e97b8567',
      })
    ).data;
    console.log(token);
    // } else {
    //   alert('Must use physical device for Push Notifications');
    // }

    return token;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Platform.OS === 'web') {
        navigation.navigate('SignupWheretostartScreen');
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold' }
            ),
            dimensions.width
          )}
        >
          {'Permissions'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, padding: 24 },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Custom Color_18'],
              fontFamily: 'Poppins_300Light',
              fontSize: 19,
              lineHeight: 26,
              marginLeft: { minWidth: Breakpoints.Tablet, value: '15%' },
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'For the best experience of the App, kindly grant the following permissions.'
          }
        </Text>
        {/* top logo */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['top logo 2'], {
              marginTop: 25,
            }),
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={Images.DirtNearMe}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                height: 166,
                width: 166,
              }),
              dimensions.width
            )}
          />
        </View>
        {/* Dirt Options */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              marginTop: 25,
              padding: { minWidth: Breakpoints.Tablet, value: '15%' },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 0 },
            },
            dimensions.width
          )}
        >
          {/* Location Access */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 35,
              },
              dimensions.width
            )}
          >
            {/* Checkbox */}
            <Pressable
              activeOpacity={0.3}
              disabled={locationPermissionResponse?.longitude}
              onPress={() => {
                const handler = async () => {
                  try {
                    setLocationLoading(true);
                    const locationResponse = await getLocationUtil();
                    setLocationPermissionResponse(locationResponse);
                    if (!locationResponse?.longitude) {
                      setGlobalVariableValue({
                        key: 'locationGranted',
                        value: 'NO',
                      });
                    } else {
                      setGlobalVariableValue({
                        key: 'myLatitude',
                        value: locationResponse?.latitude,
                      });
                      setGlobalVariableValue({
                        key: 'myLongitude',
                        value: locationResponse?.longitude,
                      });
                    }

                    setLocationLoading(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Primary Blue'],
                    borderRadius: 6,
                    borderWidth: 1,
                    height: 42,
                    justifyContent: 'center',
                    marginRight: 12,
                    width: 42,
                  },
                  dimensions.width
                )}
              >
                <>
                  {!locationPermissionResponse?.longitude ? null : (
                    <Icon
                      color={theme.colors['Primary Blue']}
                      name={'Feather/check'}
                      size={28}
                    />
                  )}
                </>
              </View>
            </Pressable>
            {/* Allow Location Access */}
            <>
              {locationPermissionResponse?.longitude ? null : (
                <Button
                  activeOpacity={0.3}
                  icon={'Ionicons/ios-location-sharp'}
                  loading={locationLoading}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setLocationLoading(true);
                        const locationResponse = await getLocationUtil();
                        console.log(locationResponse);
                        setLocationPermissionResponse(locationResponse);
                        if (locationResponse?.longitude) {
                          setGlobalVariableValue({
                            key: 'myLatitude',
                            value: locationResponse?.latitude,
                          });
                          setGlobalVariableValue({
                            key: 'myLongitude',
                            value: locationResponse?.longitude,
                          });
                        } else {
                          setGlobalVariableValue({
                            key: 'locationGranted',
                            value: 'NO',
                          });
                        }

                        setLocationLoading(false);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Primary'],
                        borderWidth: 1,
                        color: theme.colors['Primary'],
                        fontFamily: 'Poppins_400Regular',
                        marginTop: [
                          { minWidth: Breakpoints.Tablet, value: 50 },
                          { minWidth: Breakpoints.Mobile, value: 0 },
                        ],
                        width: '85%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'  Allow Location Access'}
                />
              )}
            </>
            {/* Allow Location Access - Granted */}
            <>
              {!locationPermissionResponse?.longitude ? null : (
                <Button
                  activeOpacity={1}
                  icon={'Ionicons/ios-location-sharp'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                      {
                        borderColor: theme.colors['Primary'],
                        borderWidth: 1,
                        fontFamily: 'Poppins_400Regular',
                        marginTop: [
                          { minWidth: Breakpoints.Tablet, value: 50 },
                          { minWidth: Breakpoints.Mobile, value: 0 },
                        ],
                        width: '85%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'  Allow Location Access'}
                />
              )}
            </>
          </View>
          {/* Push Notifications */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 35,
              },
              dimensions.width
            )}
          >
            {/* Checkbox */}
            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (pushToken?.length) {
                      setPushToken('');
                    } else {
                      const result = await registerForPushNotificationsAsync();
                      setPushToken(result);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Primary Blue'],
                    borderRadius: 6,
                    borderWidth: 1,
                    height: 42,
                    justifyContent: 'center',
                    marginRight: 12,
                    width: 42,
                  },
                  dimensions.width
                )}
              >
                <>
                  {!pushToken?.length ? null : (
                    <Icon
                      color={theme.colors['Primary Blue']}
                      name={'Feather/check'}
                      size={28}
                    />
                  )}
                </>
              </View>
            </Pressable>
            {/* Allow Push Notifications */}
            <>
              {pushToken?.length ? null : (
                <Button
                  activeOpacity={0.3}
                  icon={'Ionicons/notifications'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const result =
                          await registerForPushNotificationsAsync();
                        setPushToken(
                          (() => {
                            const e = result;
                            console.log(e);
                            return e;
                          })()
                        );
                        setNotificationCheckBox(result?.length);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Primary'],
                        borderWidth: 1,
                        color: theme.colors['Primary'],
                        fontFamily: 'Poppins_400Regular',
                        marginTop: [
                          { minWidth: Breakpoints.Tablet, value: 50 },
                          { minWidth: Breakpoints.Mobile, value: 0 },
                        ],
                        width: '85%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'  Allow Push Notifications'}
                />
              )}
            </>
            {/* Allow Push Notifications - Granted */}
            <>
              {!pushToken?.length ? null : (
                <Button
                  activeOpacity={0.3}
                  icon={'Ionicons/notifications'}
                  onPress={() => {
                    try {
                      setPushToken('');
                      setNotificationCheckBox(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                      {
                        borderColor: theme.colors['Primary'],
                        borderWidth: 1,
                        fontFamily: 'Poppins_400Regular',
                        marginTop: [
                          { minWidth: Breakpoints.Tablet, value: 50 },
                          { minWidth: Breakpoints.Mobile, value: 0 },
                        ],
                        width: '85%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'  Allow Push Notifications'}
                />
              )}
            </>
          </View>
        </View>
        <Button
          onPress={() => {
            try {
              navigation.navigate('SignupWheretostartScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
              {
                borderRadius: 10,
                fontFamily: 'Poppins_500Medium',
                marginBottom: 40,
                marginLeft: { minWidth: Breakpoints.Tablet, value: '14%' },
                marginRight: { minWidth: Breakpoints.Tablet, value: '14%' },
              }
            ),
            dimensions.width
          )}
          title={'DONE'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SignupAppPermissionsScreen);
