import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import React from 'react';
import * as XanoApi from './apis/XanoApi.js';
import * as GlobalVariables from './config/GlobalVariableContext';
import Breakpoints from './utils/Breakpoints';
import showAlertUtil from './utils/showAlert';
import useWindowDimensions from './utils/useWindowDimensions';
import * as CustomTabNavigator from './custom-files/CustomTabNavigator';
import AboutScreensScreen from './screens/AboutScreensScreen';
import BlockedUsersScreen from './screens/BlockedUsersScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import ChatListScreen from './screens/ChatListScreen';
import ChatScreen from './screens/ChatScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import CreatePostScreen from './screens/CreatePostScreen';
import EditMyProfileScreen from './screens/EditMyProfileScreen';
import EditPostDetailsScreen from './screens/EditPostDetailsScreen';
import FavouritesScreen from './screens/FavouritesScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import HomeScreen from './screens/HomeScreen';
import ImageScreen from './screens/ImageScreen';
import LoginScreen from './screens/LoginScreen';
import MyPostsDetailsActiveScreen from './screens/MyPostsDetailsActiveScreen';
import MyPostsDetailsExpiredScreen from './screens/MyPostsDetailsExpiredScreen';
import MyPostsDetailsFulfilledScreen from './screens/MyPostsDetailsFulfilledScreen';
import MyPostsScreen from './screens/MyPostsScreen';
import MyProfileScreen from './screens/MyProfileScreen';
import NearbyBusinessesScreen from './screens/NearbyBusinessesScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import PostDetailsScreen from './screens/PostDetailsScreen';
import PostReviewFilledScreen from './screens/PostReviewFilledScreen';
import PostReviewUnfilledScreen from './screens/PostReviewUnfilledScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import ResetPasswordVerifyOTPScreen from './screens/ResetPasswordVerifyOTPScreen';
import SetaPasswordScreen from './screens/SetaPasswordScreen';
import SettingsScreen from './screens/SettingsScreen';
import SignupAppPermissionsScreen from './screens/SignupAppPermissionsScreen';
import SignupOTPScreen from './screens/SignupOTPScreen';
import SignupScreen from './screens/SignupScreen';
import SignupWheretostartScreen from './screens/SignupWheretostartScreen';
import SocialCompleteProfileScreen from './screens/SocialCompleteProfileScreen';
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen';
import UserProfileScreen from './screens/UserProfileScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function BottomTabNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <Tab.Navigator
      initialRouteName="HomeScreen"
      tabBar={props => <CustomTabNavigator.MyTabBar {...props} />}
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarIconStyle: { display: 'none' },
        tabBarShowLabel: false,
        tabBarStyle: { borderTopColor: 'transparent' },
      })}
    >
      <Tab.Screen
        name="MyPostsScreen"
        component={MyPostsScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon name="" size={25} color={focused ? color : color} />
          ),
          title: 'My Posts',
        })}
      />
      <Tab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon name="" size={25} color={focused ? color : color} />
          ),
          title: 'Home',
        })}
      />
      <Tab.Screen
        name="FavouritesScreen"
        component={FavouritesScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon name="" size={25} color={focused ? color : color} />
          ),
          title: 'Favourites',
        })}
      />
      <Tab.Screen
        name="MyProfileScreen"
        component={MyProfileScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon name="" size={25} color={focused ? color : color} />
          ),
          title: 'My Profile',
        })}
      />
    </Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FBFCFD',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="OnboardingScreen"
        screenOptions={({ navigation }) => ({
          gestureEnabled: false,
          headerMode: 'none',
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={({ navigation }) => ({
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={({ navigation }) => ({
            title: 'Forgot Password',
          })}
        />
        <Stack.Screen
          name="SignupScreen"
          component={SignupScreen}
          options={({ navigation }) => ({
            title: 'Sign up',
          })}
        />
        <Stack.Screen
          name="SignupOTPScreen"
          component={SignupOTPScreen}
          options={({ navigation }) => ({
            title: 'Sign up - OTP',
          })}
        />
        <Stack.Screen
          name="OnboardingScreen"
          component={OnboardingScreen}
          options={({ navigation }) => ({
            title: 'Onboarding',
          })}
        />
        <Stack.Screen
          name="SignupWheretostartScreen"
          component={SignupWheretostartScreen}
          options={({ navigation }) => ({
            title: 'Sign up - Where to start',
          })}
        />
        <Stack.Screen
          name="SocialCompleteProfileScreen"
          component={SocialCompleteProfileScreen}
          options={({ navigation }) => ({
            title: 'SocialCompleteProfile',
          })}
        />
        <Stack.Screen
          name="PostDetailsScreen"
          component={PostDetailsScreen}
          options={({ navigation }) => ({
            title: 'Post Details',
          })}
        />
        <Stack.Screen
          name="SettingsScreen"
          component={SettingsScreen}
          options={({ navigation }) => ({
            title: 'Settings',
          })}
        />
        <Stack.Screen
          name="AboutScreensScreen"
          component={AboutScreensScreen}
          options={({ navigation }) => ({
            title: 'About Screens',
          })}
        />
        <Stack.Screen
          name="ChatListScreen"
          component={ChatListScreen}
          options={({ navigation }) => ({
            title: 'Chat List',
          })}
        />
        <Stack.Screen
          name="NotificationsScreen"
          component={NotificationsScreen}
          options={({ navigation }) => ({
            title: 'Notifications',
          })}
        />
        <Stack.Screen
          name="ChatScreen"
          component={ChatScreen}
          options={({ navigation }) => ({
            title: 'Chat',
          })}
        />
        <Stack.Screen
          name="MyPostsDetailsActiveScreen"
          component={MyPostsDetailsActiveScreen}
          options={({ navigation }) => ({
            title: 'My Posts Details - Active',
          })}
        />
        <Stack.Screen
          name="ChangePasswordScreen"
          component={ChangePasswordScreen}
          options={({ navigation }) => ({
            title: 'Change Password',
          })}
        />
        <Stack.Screen
          name="MyPostsDetailsFulfilledScreen"
          component={MyPostsDetailsFulfilledScreen}
          options={({ navigation }) => ({
            title: 'My Posts Details - Fulfilled',
          })}
        />
        <Stack.Screen
          name="MyPostsDetailsExpiredScreen"
          component={MyPostsDetailsExpiredScreen}
          options={({ navigation }) => ({
            title: 'My Posts Details - Expired',
          })}
        />
        <Stack.Screen
          name="PostReviewUnfilledScreen"
          component={PostReviewUnfilledScreen}
          options={({ navigation }) => ({
            title: 'Post Review - Unfilled',
          })}
        />
        <Stack.Screen
          name="PostReviewFilledScreen"
          component={PostReviewFilledScreen}
          options={({ navigation }) => ({
            title: 'Post Review - Filled',
          })}
        />
        <Stack.Screen
          name="EditPostDetailsScreen"
          component={EditPostDetailsScreen}
          options={({ navigation }) => ({
            title: 'Edit Post Details ',
          })}
        />
        <Stack.Screen
          name="UserProfileScreen"
          component={UserProfileScreen}
          options={({ navigation }) => ({
            title: 'User Profile',
          })}
        />
        <Stack.Screen
          name="EditMyProfileScreen"
          component={EditMyProfileScreen}
          options={({ navigation }) => ({
            title: 'Edit My Profile',
          })}
        />
        <Stack.Screen
          name="CreatePostScreen"
          component={CreatePostScreen}
          options={({ navigation }) => ({
            title: 'Create Post',
          })}
        />
        <Stack.Screen
          name="SetaPasswordScreen"
          component={SetaPasswordScreen}
          options={({ navigation }) => ({
            title: 'Set a Password',
          })}
        />
        <Stack.Screen
          name="ResetPasswordVerifyOTPScreen"
          component={ResetPasswordVerifyOTPScreen}
          options={({ navigation }) => ({
            title: 'Reset Password - Verify OTP',
          })}
        />
        <Stack.Screen
          name="ResetPasswordScreen"
          component={ResetPasswordScreen}
          options={({ navigation }) => ({
            title: 'Reset Password',
          })}
        />
        <Stack.Screen
          name="NearbyBusinessesScreen"
          component={NearbyBusinessesScreen}
          options={({ navigation }) => ({
            title: 'Nearby Businesses',
          })}
        />
        <Stack.Screen
          name="SignupAppPermissionsScreen"
          component={SignupAppPermissionsScreen}
          options={({ navigation }) => ({
            title: 'Sign up - App Permissions',
          })}
        />
        <Stack.Screen
          name="ImageScreen"
          component={ImageScreen}
          options={({ navigation }) => ({
            title: 'Image Screen',
          })}
        />
        <Stack.Screen
          name="BlockedUsersScreen"
          component={BlockedUsersScreen}
          options={({ navigation }) => ({
            title: 'Blocked Users',
          })}
        />
        <Stack.Screen
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
          options={({ navigation }) => ({
            title: 'Privacy Policy',
          })}
        />
        <Stack.Screen
          name="TermsAndConditionsScreen"
          component={TermsAndConditionsScreen}
          options={({ navigation }) => ({
            title: 'Terms and Conditions',
          })}
        />
        <Stack.Screen
          name="ContactUsScreen"
          component={ContactUsScreen}
          options={({ navigation }) => ({
            title: 'Contact Us',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
