import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openCameraUtil from '../utils/openCamera';
import openImagePickerUtil from '../utils/openImagePicker';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Circle,
  DatePicker,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CreatePostScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [action_sheet, setAction_sheet] = React.useState(false);
  const [attachment, setAttachment] = React.useState('');
  const [date_of_availability, setDate_of_availability] = React.useState(
    new Date()
  );
  const [description, setDescription] = React.useState('');
  const [dirt_type, setDirt_type] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [lat, setLat] = React.useState('');
  const [load_materials, setLoad_materials] = React.useState(false);
  const [location, setLocation] = React.useState('');
  const [location_search_text, setLocation_search_text] = React.useState('');
  const [long, setLong] = React.useState('');
  const [material, setMaterial] = React.useState('');
  const [material_proctor_required, setMaterial_proctor_required] =
    React.useState(false);
  const [material_types, setMaterial_types] = React.useState([]);
  const [need_trucking, setNeed_trucking] = React.useState(false);
  const [post_Validity, setPost_Validity] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [price_type, setPrice_type] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [selected_load_material, setSelected_load_material] =
    React.useState('');
  const [selected_material_id, setSelected_material_id] = React.useState(0);
  const [selected_proctor, setSelected_proctor] = React.useState('');
  const [selected_trucking, setSelected_trucking] = React.useState('');
  const [show_Image_Picker, setShow_Image_Picker] = React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_materialtypes_modal, setShow_materialtypes_modal] =
    React.useState(false);
  const [show_pricetype_modal, setShow_pricetype_modal] = React.useState(false);
  const [show_step_number, setShow_step_number] = React.useState(1);
  const [show_validity_modal, setShow_validity_modal] = React.useState(false);
  const [site_name, setSite_name] = React.useState('');
  const screen2_Input_Validations = () => {
    let foundError = false;

    if (price_type.length < 1) {
      setErr_msg('You must complete all fields. Please select price type');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (selected_load_material.length < 1) {
      setErr_msg(
        'You must complete all fields. Please confirm if you can load materials or not'
      );
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (selected_trucking.length < 1) {
      setErr_msg(
        'You must complete all fields. Please confirm if you need trucking'
      );
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (selected_proctor.length < 1) {
      setErr_msg(
        'You must complete all fields. Please confirm if you need material proctor'
      );
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };

  const screen1_Input_Validations = () => {
    let foundError = false;

    if (dirt_type.length < 1) {
      setErr_msg('You must complete all fields. Please select Dirt type');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (material.length < 1) {
      setErr_msg('You must complete all fields. Please select a material type');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (quantity.length < 1) {
      setErr_msg('You must complete all fields. Please enter quantity of dirt');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (location.length < 1) {
      setErr_msg('You must complete all fields. Please enter your Location');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (date_of_availability.length < 1) {
      setErr_msg(
        'You must complete all fields. Please select date of availability'
      );
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (post_Validity.length < 1) {
      setErr_msg(
        'You must complete all fields. Please select when this Post should expire'
      );
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };

  const removeCommas = number => {
    let numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    while (numberString.includes(',')) {
      numberString = numberString.replace(',', '');
    }

    return numberString;
  };

  const addCommasToNumber = number => {
    //replaceAll crashes on Android, so I am using replace function in a while loop
    let numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    while (numberString.includes(',')) {
      numberString = numberString.replace(',', '');
    }

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const getTodaysDate = () => {
    // const today = new Date();
    // return today

    return Date.now();
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await XanoApi.materialTypesGET(Constants))?.json;
        setMaterial_types(result);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const locationTextInputHfzp8j48Ref = React.useRef();

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Step 1 */}
      <>
        {!(show_step_number === 1) ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Top_Header'],
                  { borderBottomWidth: 1, borderColor: theme.colors['Divider'] }
                ),
                dimensions.width
              )}
            >
              {/* Cancel */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                      width: 70,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          lineHeight: 22,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Cancel'}
                  </Text>
                </View>
              </Pressable>
              {/* screen heading */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                  dimensions.width
                )}
              >
                {'Create Post'}
              </Text>
              {/* Next */}
              <Pressable
                activeOpacity={0.3}
                disabled={true}
                onPress={() => {
                  try {
                    setShow_step_number(2);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Custom #ffffff'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          lineHeight: 22,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Next'}
                  </Text>
                  <Icon
                    color={theme.colors['Custom #ffffff']}
                    name={'Feather/chevron-right'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>
            {/* Steps */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 0,
                },
                dimensions.width
              )}
            >
              <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Custom #ffffff'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'1'}
                </Text>
              </Circle>
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              {/* 2 */}
              <Pressable disabled={true}>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Circle bgColor={theme.colors['Custom #ffffff']} size={28}>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderColor: theme.colors['Primary Blue'],
                          borderRadius: 14,
                          borderWidth: 1,
                          height: 28,
                          justifyContent: 'center',
                          width: 28,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 16,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'2'}
                      </Text>
                    </View>
                  </Circle>
                </View>
              </Pressable>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              <Circle bgColor={theme.colors['Custom #ffffff']} size={28}>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary Blue'],
                      borderRadius: 14,
                      borderWidth: 1,
                      height: 28,
                      justifyContent: 'center',
                      width: 28,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'3'}
                  </Text>
                </View>
              </Circle>
            </View>

            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  padding: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Tablet, value: '15%' },
                  ],
                  paddingTop: [
                    { minWidth: Breakpoints.Tablet, value: '10%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                },
                dimensions.width
              )}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
            >
              {/* Do you need or have Dirt? * */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Do you need or have Material?*'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 44,
                      justifyContent: 'space-between',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Have */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-start', width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(dirt_type === 'Have') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Have'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(dirt_type !== 'Have') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setDirt_type('Have');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Have'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* Need */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-end', width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(dirt_type === 'Need') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Yellow'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Need'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(dirt_type !== 'Need') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setDirt_type('Need');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Need'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                </View>
              </View>
              {/* Type of Material* */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Type of Material*'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_materialtypes_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44, marginTop: 6 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {material ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                marginTop: 0,
                                paddingLeft: 8,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Material'}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!material ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 45,
                                marginLeft: 16,
                                marginTop: 0,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {material}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* Quantity (CYD)* */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Quantity (CYD)*'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      {
                        alignItems: 'stretch',
                        flexDirection: 'column',
                        height: 44,
                        marginTop: 6,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    keyboardType={'numeric'}
                    onChangeText={newTextInputValue => {
                      try {
                        setQuantity(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Quantity'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                      dimensions.width
                    )}
                    value={addCommasToNumber(quantity)}
                  />
                </View>
              </View>
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Location*'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_location_modal(true);
                      locationTextInputHfzp8j48Ref.current.focus();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44, marginTop: 6 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {location ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                marginTop: 0,
                                paddingLeft: 8,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Location'}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!location ? null : (
                        <Text
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 45,
                                marginLeft: 16,
                                marginTop: 0,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {location}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* Job Site Name */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Job Site Name'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { height: 44, marginTop: 6 }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    onChangeText={newTextInputValue => {
                      try {
                        setSite_name(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Site Name'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                      dimensions.width
                    )}
                    value={site_name}
                  />
                </View>
              </View>
              {/* Availability Date* */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Availability Date*'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { height: 44, marginTop: 6 }
                    ),
                    dimensions.width
                  )}
                >
                  <DatePicker
                    autoDismissKeyboard={true}
                    borderColor={'"rgba(0, 0, 0, 0)"'}
                    date={date_of_availability}
                    label={'Date'}
                    leftIconMode={'inset'}
                    minimumDate={new Date(getTodaysDate())}
                    mode={'date'}
                    onDateChange={newDatePickerValue => {
                      try {
                        setDate_of_availability(newDatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        fontFamily: 'Poppins_400Regular',
                        height: 44,
                        marginLeft: 4,
                        top: -3,
                      },
                      dimensions.width
                    )}
                    type={'underline'}
                  />
                </View>
              </View>
              {/* Post expires in* */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Post expires in* (Days)'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_validity_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44, marginTop: 6 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {post_Validity ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                marginTop: 0,
                                paddingLeft: 8,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Post Duration'}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!(post_Validity !== '0') ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 45,
                                marginLeft: 16,
                                marginTop: 0,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {post_Validity}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!(post_Validity === '0') ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 45,
                                marginLeft: 16,
                                marginTop: 0,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Never'}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* Error Message */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 15,
                    marginTop: 20,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {err_msg}
              </Text>
              {/* Next */}
              <Button
                onPress={() => {
                  try {
                    const foundError = screen1_Input_Validations();
                    if (!foundError) {
                      setShow_step_number(2);
                    }
                    if (!foundError) {
                      setErr_msg('');
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Tablet'],
                    { fontSize: 20, marginBottom: 50 }
                  ),
                  dimensions.width
                )}
                title={'Next'}
              />
              {/* Location Modal */}
              <>
                {!show_location_modal ? null : (
                  <Modal animationType={'none'}>
                    {/* Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: 12,
                          marginRight: 12,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 50,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 18,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Change Location'}
                      </Text>

                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            setShow_location_modal(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              height: 48,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                  marginRight: 4,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Close'}
                          </Text>
                          <Icon
                            color={theme.colors['Primary Blue']}
                            name={'Ionicons/md-close'}
                            size={24}
                          />
                        </View>
                      </Pressable>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { padding: 20 },
                        dimensions.width
                      )}
                    >
                      {/* location Text input */}
                      <TextInput
                        autoCapitalize={'none'}
                        changeTextDelay={100}
                        onChangeText={newLocationTextInputValue => {
                          try {
                            setLocation_search_text(newLocationTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onChangeTextDelayed={newLocationTextInputValue => {
                          try {
                            setLocation_search_text(newLocationTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Search a location'}
                        placeholderTextColor={theme.colors['Light']}
                        ref={locationTextInputHfzp8j48Ref}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input'],
                            {
                              borderColor: theme.colors['Divider'],
                              borderWidth: 1,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 16,
                              marginBottom: 15,
                              paddingLeft: 16,
                            }
                          ),
                          dimensions.width
                        )}
                        value={location_search_text}
                      />
                      <XanoGooglePlacesApi.FetchSearchGET
                        handlers={{
                          onData: fetchData => {
                            try {
                              locationTextInputHfzp8j48Ref.current.focus();
                            } catch (err) {
                              console.error(err);
                            }
                          },
                        }}
                        search={location_search_text}
                      >
                        {({ loading, error, data, refetchSearch }) => {
                          const fetchData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={fetchData?.predictions}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              listKey={'gyahd2aO'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Pressable
                                    activeOpacity={0.3}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setLocation(listData?.description);
                                          setShow_location_modal(false);
                                          const coords = (
                                            await XanoGooglePlacesApi.getCoordinatesGET(
                                              Constants,
                                              { place_id: listData?.place_id }
                                            )
                                          )?.json;
                                          console.log(coords);
                                          setLat(
                                            coords?.result?.geometry?.location
                                              ?.lat
                                          );
                                          setLong(
                                            coords?.result?.geometry?.location
                                              ?.lng
                                          );
                                          setErr_msg('');
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'row',
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Primary']}
                                        name={'Entypo/location-pin'}
                                        size={24}
                                      />
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Primary Blue'],
                                              fontFamily: 'Poppins_400Regular',
                                              fontSize: 15,
                                              paddingLeft: 12,
                                              paddingRight: 12,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.description}
                                      </Text>
                                    </View>
                                  </Pressable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          );
                        }}
                      </XanoGooglePlacesApi.FetchSearchGET>
                    </View>
                  </Modal>
                )}
              </>
            </KeyboardAwareScrollView>
            {/* material types modal */}
            <>
              {!show_materialtypes_modal ? null : (
                <Modal animationType={'fade'} transparent={true}>
                  {/* Black BG View */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom Color_18'],
                        bottom: 0,
                        left: 0,
                        opacity: 0.5,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                      },
                      dimensions.width
                    )}
                  />
                  {/* Dirt Type List */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 350, width: 250 },
                        dimensions.width
                      )}
                    >
                      <RadioButtonGroup>
                        <FlatList
                          contentContainerStyle={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom #ffffff'],
                              borderRadius: 8,
                              justifyContent: 'center',
                              overflow: 'hidden',
                            },
                            dimensions.width
                          )}
                          data={(() => {
                            const e = material_types;
                            console.log(e);
                            return e;
                          })()}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          listKey={'IKFShAMy'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setMaterial(listData?.label);
                                    setSelected_material_id(listData?.value);
                                    setShow_materialtypes_modal(false);
                                    setErr_msg('');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Primary Yellow'],
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      padding: 12,
                                      paddingLeft: 30,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          flex: 1,
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 18,
                                          marginLeft: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.label}
                                  </Text>
                                </View>
                              </Pressable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      </RadioButtonGroup>
                    </View>
                  </View>
                </Modal>
              )}
            </>
            {/* Post Validity modal */}
            <Modal
              animationType={'fade'}
              transparent={true}
              visible={show_validity_modal}
            >
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* List */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 220, overflow: 'hidden', width: 200 },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup
                    onValueChange={newRadioButtonGroupValue => {
                      try {
                        setPost_Validity(newRadioButtonGroupValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    value={post_Validity}
                  >
                    <FlatList
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderRadius: 8,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                      data={['30', '90', '120', 'Never']}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'jeI31ela'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                if (listData !== 'Never') {
                                  setPost_Validity(listData);
                                }
                                if (listData === 'Never') {
                                  setPost_Validity('0');
                                }
                                setShow_validity_modal(false);
                                setErr_msg('');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Primary Yellow'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  overflow: 'hidden',
                                  padding: 12,
                                  paddingLeft: 30,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </RadioButtonGroup>
                </View>
              </View>
            </Modal>
          </View>
        )}
      </>
      {/* Step 2 */}
      <>
        {!(show_step_number === 2) ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Top_Header'],
                  { borderBottomWidth: 1, borderColor: theme.colors['Divider'] }
                ),
                dimensions.width
              )}
            >
              {/* Back */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setErr_msg('');
                    setShow_step_number(1);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                      width: 70,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Light']}
                    name={'Ionicons/chevron-back'}
                    size={24}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          lineHeight: 22,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Back'}
                  </Text>
                </View>
              </Pressable>
              {/* screen heading */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                  dimensions.width
                )}
              >
                {'Create Post'}
              </Text>
              {/* Next */}
              <Pressable
                activeOpacity={0.3}
                disabled={true}
                onPress={() => {
                  try {
                    setShow_step_number(3);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Custom #ffffff'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          lineHeight: 22,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Next'}
                  </Text>
                  <Icon
                    color={theme.colors['Custom #ffffff']}
                    name={'Feather/chevron-right'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>
            {/* Steps */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 0,
                },
                dimensions.width
              )}
            >
              {/* 1 */}
              <Pressable>
                {/* 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'1'}
                    </Text>
                  </Circle>
                </View>
              </Pressable>
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              {/* 2 */}
              <Pressable>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'2'}
                    </Text>
                  </Circle>
                </View>
              </Pressable>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              {/* 3 */}
              <Pressable disabled={true}>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Circle bgColor={theme.colors['Custom #ffffff']} size={28}>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderColor: theme.colors['Primary Blue'],
                          borderRadius: 14,
                          borderWidth: 1,
                          height: 28,
                          justifyContent: 'center',
                          width: 28,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 16,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'3'}
                      </Text>
                    </View>
                  </Circle>
                </View>
              </Pressable>
            </View>

            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  flex: 1,
                  padding: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Tablet, value: '15%' },
                  ],
                  paddingTop: [
                    { minWidth: Breakpoints.Tablet, value: '10%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                },
                dimensions.width
              )}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
            >
              {/* Price Preference* */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_pricetype_modal(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Price Preference* */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      dimensions.width
                    )}
                  >
                    {'Price Preference*'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { marginTop: 6, minHeight: 44 }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Placeholder */}
                  <>
                    {price_type ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Light'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 17,
                              lineHeight: 55,
                              marginTop: 0,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Select Price Preference'}
                      </Text>
                    )}
                  </>
                  {/* Value */}
                  <>
                    {!price_type ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 17,
                              lineHeight: 45,
                              marginLeft: 16,
                              marginTop: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {price_type}
                      </Text>
                    )}
                  </>
                </View>
              </Pressable>
              {/* Price in USD (/CYD) */}
              <>
                {!(price_type === 'Fixed') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Price in USD (/CYD)*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          { height: 44, marginTop: 6 }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'none'}
                        keyboardType={'numeric'}
                        onChangeText={newTextInputValue => {
                          try {
                            setPrice(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Price'}
                        placeholderTextColor={theme.colors['Light']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Text Input Phone'
                          ],
                          dimensions.width
                        )}
                        value={price}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Can you load the materials? * */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16, minHeight: 75 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Can you load the materials? *'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 44,
                      justifyContent: 'space-between',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Yes */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_load_material === 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_load_material !== 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setLoad_materials(true);
                              setSelected_load_material('yes');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* No */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_load_material === 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_load_material !== 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setLoad_materials(false);
                              setSelected_load_material('no');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                </View>
              </View>
              {/* Do you need trucking / hauling? */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16, minHeight: 75 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {!(dirt_type === 'Need') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Do you need trucking / hauling? *'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(dirt_type === 'Have') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Are you providing trucking/hauling? *'}
                    </Text>
                  )}
                </>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 44,
                      justifyContent: 'space-between',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Yes */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_trucking === 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_trucking !== 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setNeed_trucking(true);
                              setSelected_trucking('yes');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* No */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_trucking === 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_trucking !== 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setNeed_trucking(false);
                              setSelected_trucking('no');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                </View>
              </View>
              {/* Proctor */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginTop: 16, minHeight: 75 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { textTransform: 'none' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Do you '}
                  {dirt_type?.toLowerCase()}
                  {' a proctor? *'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 44,
                      justifyContent: 'space-between',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Yes */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_proctor === 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_proctor !== 'yes') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setMaterial_proctor_required(true);
                              setSelected_proctor('yes');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'YES'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* No */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* selected */}
                    <>
                      {!(selected_proctor === 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary Blue'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Custom #ffffff'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {!(selected_proctor !== 'no') ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setMaterial_proctor_required(false);
                              setSelected_proctor('no');
                              setErr_msg('');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '98%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 13,
                                borderWidth: 1,
                                height: 44,
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 20,
                                    lineHeight: 24,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'NO'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                </View>
              </View>
              {/* Error Message */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 15,
                    marginTop: 20,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {err_msg}
              </Text>
              {/* Next */}
              <Button
                onPress={() => {
                  try {
                    const foundError = screen2_Input_Validations();
                    if (foundError) {
                      return;
                    }
                    if (!foundError) {
                      setShow_step_number(3);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Tablet'],
                    { fontSize: 20, marginBottom: 50 }
                  ),
                  dimensions.width
                )}
                title={'Next'}
              />
            </KeyboardAwareScrollView>
            {/* Price Preferences modal */}
            <Modal
              animationType={'fade'}
              transparent={true}
              visible={show_pricetype_modal}
            >
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* type */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 150, width: 250 },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup
                    onValueChange={newRadioButtonGroupValue => {
                      try {
                        setPrice_type(newRadioButtonGroupValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    value={price_type}
                  >
                    <FlatList
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderRadius: 8,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                      data={['Fixed', 'Negotiable', 'Free']}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'jvVZjbJg'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                setPrice_type(listData);
                                setShow_pricetype_modal(false);
                                setErr_msg('');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Primary Yellow'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  padding: 12,
                                  paddingLeft: 30,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </RadioButtonGroup>
                </View>
              </View>
            </Modal>
          </View>
        )}
      </>
      {/* Step 3 */}
      <>
        {!(show_step_number === 3) ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Top_Header'],
                  { borderBottomWidth: 1, borderColor: theme.colors['Divider'] }
                ),
                dimensions.width
              )}
            >
              {/* Back */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_step_number(2);
                    setErr_msg('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                      width: 70,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Light']}
                    name={'Ionicons/chevron-back'}
                    size={24}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          lineHeight: 22,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Back'}
                  </Text>
                </View>
              </Pressable>
              {/* screen heading */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                    { marginRight: 70 }
                  ),
                  dimensions.width
                )}
              >
                {'Create Post'}
              </Text>
            </View>
            {/* Steps */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 16,
                  paddingBottom: 0,
                },
                dimensions.width
              )}
            >
              <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Custom #ffffff'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'1'}
                </Text>
              </Circle>
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Custom #ffffff'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'2'}
                </Text>
              </Circle>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: theme.colors['Primary Blue'],
                    borderWidth: 1,
                    flex: 1,
                  },
                  dimensions.width
                )}
              />
              <Circle bgColor={theme.colors['Primary Blue']} size={28}>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Custom #ffffff'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'3'}
                </Text>
              </Circle>
            </View>

            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  flex: 1,
                  padding: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Tablet, value: '15%' },
                  ],
                  paddingTop: [
                    { minWidth: Breakpoints.Tablet, value: '10%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                },
                dimensions.width
              )}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
            >
              {/* Additional Post Details */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Additional Post Details\n'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { height: 100, marginTop: 6 }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    multiline={true}
                    numberOfLines={4}
                    onChangeText={newTextAreaValue => {
                      try {
                        setDescription(newTextAreaValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Additional info'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Area'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          height: 100,
                          marginLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                    textAlignVertical={'top'}
                    value={description}
                  />
                </View>
              </View>
              {/* Attachments */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      dimensions.width
                    )}
                  >
                    {'Attachments'}
                  </Text>
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Entypo/attachment'}
                    size={24}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 16 },
                      dimensions.width
                    )}
                  />
                </View>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_Image_Picker(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          height: 120,
                          marginTop: 6,
                          width: 120,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <Icon name={'AntDesign/plussquareo'} size={24} />
                    <Image
                      resizeMode={'cover'}
                      source={{ uri: `${attachment}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            bottom: 0,
                            height: 120,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: 120,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </Pressable>
              </View>
              {/* Error Message */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 15,
                    marginTop: 20,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {err_msg}
              </Text>
              {/* Post */}
              <Button
                loading={is_Loading}
                onPress={() => {
                  const handler = async () => {
                    try {
                      setIs_Loading(true);
                      const result = (
                        await PostsApi.createAPostPOST(Constants, {
                          ability_to_load_material: load_materials,
                          attachment: attachment,
                          availability_date: date_of_availability,
                          details: description,
                          expires_in: post_Validity,
                          is_trucking_required: need_trucking,
                          lat: lat,
                          location: location,
                          long: long,
                          material_proctor: material_proctor_required,
                          material_types_id: selected_material_id,
                          price: price,
                          price_type: price_type,
                          quantity: removeCommas(quantity),
                          site_name: site_name,
                          type: dirt_type,
                        })
                      )?.json;
                      setIs_Loading(false);
                      if (result?.status === 'Success!') {
                        showAlertUtil({
                          title: 'Created',
                          message: 'Post created successfully',
                          buttonText: 'Ok',
                        });
                      }
                      if (result?.status === 'Success!') {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'MyPostsScreen',
                        });
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Tablet'],
                    {
                      fontSize: 20,
                      marginBottom: 50,
                      marginTop: 26,
                      textAlign: 'auto',
                    }
                  ),
                  dimensions.width
                )}
                title={'Post'}
              />
            </KeyboardAwareScrollView>
            {/* Image Picker */}
            <ActionSheet visible={show_Image_Picker}>
              {/* Gallery */}
              <ActionSheetItem
                color={theme.colors.strong}
                label={'Gallery'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const image = await openImagePickerUtil({
                        mediaTypes: 'All',
                        allowsEditing: false,
                        quality: 0.2,
                      });

                      setAttachment(image);
                      setShow_Image_Picker(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActionSheetItemStyles(theme)[
                    'Action Sheet Item'
                  ],
                  dimensions.width
                )}
              />
              {/* Camera */}
              <ActionSheetItem
                color={theme.colors.strong}
                label={'Camera'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const image = await openCameraUtil({
                        mediaTypes: 'Images',
                        allowsEditing: false,
                        cameraType: 'front',
                        videoMaxDuration: undefined,
                        quality: 0.2,
                      });

                      setAttachment(image);
                      setShow_Image_Picker(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActionSheetItemStyles(theme)[
                    'Action Sheet Item'
                  ],
                  dimensions.width
                )}
              />
              {/* Cancel */}
              <ActionSheetCancel
                label={'Cancel'}
                onPress={() => {
                  try {
                    setShow_Image_Picker(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              />
            </ActionSheet>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(CreatePostScreen);
