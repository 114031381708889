import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const SetaPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [confirm_new_password, setConfirm_new_password] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [new_password, setNew_password] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const input_Validations = () => {
    let foundError = false;

    if (new_password.length < 1) {
      setError_msg('Password cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (confirm_new_password.length < 1) {
      setError_msg('Confirm Password cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    return foundError;
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={24}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
            ),
            dimensions.width
          )}
        >
          {'Set a Password'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* Password */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Enter Password'}
          </Text>
          {/* password hidden */}
          <>
            {showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                    { height: 44 }
                  ),
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setNew_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShowPassword(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-off-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
          {/* password visible */}
          <>
            {!showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                    { height: 44 }
                  ),
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setNew_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShowPassword(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
        </View>
        {/* Confirm Password */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Re-Enter Your Password'}
          </Text>
          {/* password hidden */}
          <>
            {showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                    { height: 44 }
                  ),
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setConfirm_new_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Confirm Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={confirm_new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        setShowPassword(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-off-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
          {/* password visible */}
          <>
            {!showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                    { height: 44 }
                  ),
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setConfirm_new_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Confirm Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={confirm_new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        setShowPassword(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
        </View>
        {/* error_msg */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Error'],
              fontFamily: 'Poppins_400Regular',
              marginTop: 16,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {error_msg}
        </Text>
        {/* success_msg */}
        <>
          {!(success === true) ? null : (
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['App Green'],
                  fontFamily: 'Poppins_400Regular',
                  marginTop: 16,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {error_msg}
            </Text>
          )}
        </>
        {/* Submit */}
        <Button
          loading={isLoading}
          onPress={() => {
            const handler = async () => {
              try {
                const foundError = input_Validations();
                if (foundError) {
                  return;
                }
                setError_msg('');
                setIsLoading(true);
                if (new_password !== confirm_new_password) {
                  setError_msg('Passwords does not match');
                }
                const result = await (async () => {
                  if (new_password === confirm_new_password) {
                    return (
                      await XanoApi.setAPasswordPOST(Constants, {
                        password: new_password,
                      })
                    )?.json;
                  }
                })();
                if (result?.status === 'Success!') {
                  setGlobalVariableValue({
                    key: 'IS_EMAIL_VERIFIED',
                    value: true,
                  });
                }
                setIsLoading(false);
                if (result?.message) {
                  setError_msg(result?.message);
                }
                if (result?.status === 'Success!') {
                  navigation.navigate('SignupAppPermissionsScreen');
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
            dimensions.width
          )}
          title={'Submit'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SetaPasswordScreen);
