import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';

const SignupOTPScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [OTP1, setOTP1] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [resent_msg, setResent_msg] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(Constants['IS_EMAIL_VERIFIED']);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold' }
            ),
            dimensions.width
          )}
        >
          {'Verify Account'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 25,
              lineHeight: 30,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {'Enter verification code'}
        </Text>
        {/* Instructions */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_300Light',
              fontSize: 15,
              marginTop: 16,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {
            'We sent an email with a four-digit verification code. Please enter the code below to verify your identity.'
          }
        </Text>
        {/* OTP */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              height: 120,
              justifyContent: 'space-between',
              marginTop: 33,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Divider'], borderRadius: 8 },
              dimensions.width
            )}
          >
            <TextInput
              autoCapitalize={'none'}
              keyboardType={'numeric'}
              maxLength={6}
              onChangeText={newTextInputValue => {
                try {
                  setOTP1(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Verification Code'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['input Font'],
                  {
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 30,
                    height: 72,
                    letterSpacing: 1.5,
                    marginLeft: 5,
                    marginRight: 8,
                    marginTop: 5,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
              value={OTP1}
            />
          </View>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Error'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {err_msg}
          </Text>
        </View>
        {/* Confirm */}
        <Button
          disabled={OTP1?.length === 0}
          loading={is_Loading}
          onPress={() => {
            const handler = async () => {
              try {
                setResent_msg('');
                setErr_msg('');
                setIs_Loading(true);
                const result = (
                  await XanoApi.emailVerificationGET(Constants, {
                    otp_code: OTP1,
                  })
                )?.json;
                setErr_msg(result?.message);
                setIs_Loading(false);
                if (result?.status === 'Success!') {
                  navigation.navigate('SetaPasswordScreen');
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          title={'Confirm'}
        />
        <>
          {!(resent_msg === 'Success!') ? null : (
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Custom #329fb3'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginTop: 12,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'OTP has been re-sent to your email'}
            </Text>
          )}
        </>
        {/* Resend */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['click link'], {
              marginTop: 12,
            }),
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
                lineHeight: 22,
              }),
              dimensions.width
            )}
          >
            {'Code not received? '}
          </Text>

          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              const handler = async () => {
                try {
                  setResent_msg('');
                  const api_Response = (
                    await XanoApi.resendOTPGET(Constants, {
                      email: (() => {
                        const e = Constants['User_Details']?.email;
                        console.log(e);
                        return e;
                      })(),
                    })
                  )?.json;
                  console.log(api_Response);
                  setResent_msg(api_Response?.status);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
          >
            {/* Resend */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 16,
                  lineHeight: 22,
                }),
                dimensions.width
              )}
            >
              {'Resend'}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SignupOTPScreen);
