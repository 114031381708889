import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const authMeGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useAuthMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAuthMeGET', args],
    () => authMeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAuthMeGETS']),
    }
  );
};

export const FetchAuthMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAuthMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuthMe: refetch });
};

export const blockAPostPOST = (Constants, { posts_id }, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/blocked_posts`, {
    body: JSON.stringify({ posts_id: posts_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useBlockAPostPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => blockAPostPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Blocked Posts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Blocked Post');
        queryClient.invalidateQueries('Blocked Posts');
      },
    }
  );
};

export const FetchBlockAPostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  posts_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBlockAPostPOST(
    { posts_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBlockAPost: refetch });
};

export const blockAUserPOST = (Constants, { user_id }, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/blocked_users`, {
    body: JSON.stringify({ user_id: user_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useBlockAUserPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => blockAUserPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Blocked Users', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Blocked User');
        queryClient.invalidateQueries('Blocked Users');
      },
    }
  );
};

export const FetchBlockAUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBlockAUserPOST(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBlockAUser: refetch });
};

export const changePasswordPOST = (
  Constants,
  { existing_password, new_password },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/change_password`,
    {
      body: JSON.stringify({
        existing_password: existing_password,
        new_password: new_password,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useChangePasswordPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoChangePasswordPOST', args],
    () => changePasswordPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoChangePasswordPOSTS']),
    }
  );
};

export const FetchChangePasswordPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  existing_password,
  new_password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useChangePasswordPOST(
    { existing_password, new_password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchChangePassword: refetch });
};

export const deleteUserDELETE = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }).then(res => handleResponse(res, handlers));

export const useDeleteUserDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteUserDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const emailVerificationGET = (Constants, { otp_code }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/email_verification?otp_code=${encodeQueryParam(
      `${
        typeof otp_code === 'string' ? otp_code : JSON.stringify(otp_code ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useEmailVerificationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoEmailVerificationGET', args],
    () => emailVerificationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoEmailVerificationGETS']),
    }
  );
};

export const FetchEmailVerificationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  otp_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEmailVerificationGET(
    { otp_code },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchEmailVerification: refetch });
};

export const favoriteUnfavoriteATruckerPOST = (
  Constants,
  { trucker_id },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/favorite_trucker`, {
    body: JSON.stringify({ trucker_id: trucker_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useFavoriteUnfavoriteATruckerPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      favoriteUnfavoriteATruckerPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Truckers', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Trucker');
        queryClient.invalidateQueries('Truckers');
      },
    }
  );
};

export const FetchFavoriteUnfavoriteATruckerPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  trucker_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useFavoriteUnfavoriteATruckerPOST(
    { trucker_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchFavoriteUnfavoriteATrucker: refetch,
  });
};

export const getBlockedPostsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/blocked_posts`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetBlockedPostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Blocked Posts', args],
    () => getBlockedPostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBlockedPostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBlockedPostsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBlockedPosts: refetch });
};

export const getBlockedUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/blocked_users`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetBlockedUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Blocked Users', args],
    () => getBlockedUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBlockedUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBlockedUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBlockedUsers: refetch });
};

export const getFavoriteTruckersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/favorite_trucker`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetFavoriteTruckersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Truckers', args],
    () => getFavoriteTruckersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFavoriteTruckersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFavoriteTruckersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetFavoriteTruckers: refetch,
  });
};

export const homePOST = (
  Constants,
  { distance, lat, long, material_type, price_type, search, type, user_type },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/home_screen`, {
    body: JSON.stringify({
      distance: distance,
      search: search,
      type: type,
      material_type: material_type,
      user_type: user_type,
      location: { type: 'point', data: { lng: long, lat: lat } },
      price_type: price_type,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useHomePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Homes', args], () => homePOST(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchHomePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  distance,
  lat,
  long,
  material_type,
  price_type,
  search,
  type,
  user_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHomePOST(
    { distance, lat, long, material_type, price_type, search, type, user_type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchHome: refetch });
};

export const loginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const materialTypesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/material_types`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useMaterialTypesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoMaterialTypesGET', args],
    () => materialTypesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoMaterialTypesGETS']),
    }
  );
};

export const FetchMaterialTypesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMaterialTypesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMaterialTypes: refetch });
};

export const resendOTPGET = (Constants, { email }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/resend_otp?email=${encodeQueryParam(
      `${typeof email === 'string' ? email : JSON.stringify(email ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useResendOTPGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoResendOTPGET', args],
    () => resendOTPGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoResendOTPGETS']),
    }
  );
};

export const FetchResendOTPGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResendOTPGET(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResendOTP: refetch });
};

export const setAPasswordPOST = (Constants, { password }, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/set_password`, {
    body: JSON.stringify({ password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSetAPasswordPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoSetAPasswordPOST', args],
    () => setAPasswordPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoSetAPasswordPOSTS']),
    }
  );
};

export const FetchSetAPasswordPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSetAPasswordPOST(
    { password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSetAPassword: refetch });
};

export const signUpPOST = (
  Constants,
  {
    company_type,
    device_type,
    email,
    first_name,
    last_name,
    lat,
    location,
    long,
    phone_number,
    privacy_call,
    privacy_mail,
    privacy_message,
    profile_image,
    referral_details,
    user_type,
  },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/signup`, {
    body: JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      email: email,
      device_type: device_type,
      phone_number: phone_number,
      user_type: user_type,
      location: location,
      coordinates: { type: 'point', data: { lat: lat, lng: long } },
      company_type: company_type,
      profile_image: profile_image,
      referral_details: referral_details,
      privacy_call: privacy_call,
      privacy_message: privacy_message,
      privacy_mail: privacy_mail,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSignUpPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoSignUpPOST', args],
    () => signUpPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoSignUpPOSTS']),
    }
  );
};

export const FetchSignUpPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  company_type,
  device_type,
  email,
  first_name,
  last_name,
  lat,
  location,
  long,
  phone_number,
  privacy_call,
  privacy_mail,
  privacy_message,
  profile_image,
  referral_details,
  user_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignUpPOST(
    {
      company_type,
      device_type,
      email,
      first_name,
      last_name,
      lat,
      location,
      long,
      phone_number,
      privacy_call,
      privacy_mail,
      privacy_message,
      profile_image,
      referral_details,
      user_type,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignUp: refetch });
};

export const socialCompleteProfilePOST = (
  Constants,
  {
    company_type,
    email,
    first_name,
    last_name,
    lat,
    location,
    long,
    phone_number,
    privacy_call,
    privacy_mail,
    privacy_message,
    profile_image,
    referral_details,
    user_type,
  },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/auth/social/complete_profile`,
    {
      body: JSON.stringify({
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        user_type: user_type,
        location: location,
        coordinates: { type: 'point', data: { lat: lat, lng: long } },
        company_type: company_type,
        profile_image: profile_image,
        referral_details: referral_details,
        privacy_call: privacy_call,
        privacy_message: privacy_message,
        privacy_mail: privacy_mail,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useSocialCompleteProfilePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoSocialCompleteProfilePOST', args],
    () => socialCompleteProfilePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoSocialCompleteProfilePOSTS']),
    }
  );
};

export const FetchSocialCompleteProfilePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  company_type,
  email,
  first_name,
  last_name,
  lat,
  location,
  long,
  phone_number,
  privacy_call,
  privacy_mail,
  privacy_message,
  profile_image,
  referral_details,
  user_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSocialCompleteProfilePOST(
    {
      company_type,
      email,
      first_name,
      last_name,
      lat,
      location,
      long,
      phone_number,
      privacy_call,
      privacy_mail,
      privacy_message,
      profile_image,
      referral_details,
      user_type,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSocialCompleteProfile: refetch,
  });
};

export const togglePushNotificationSettingsGET = (
  Constants,
  { push_token },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/toggle_push_notifications?push_token=${encodeQueryParam(
      `${
        typeof push_token === 'string'
          ? push_token
          : JSON.stringify(push_token ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useTogglePushNotificationSettingsGET = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      togglePushNotificationSettingsGET(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Notifications', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Notification');
        queryClient.invalidateQueries('Notifications');
      },
    }
  );
};

export const FetchTogglePushNotificationSettingsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  push_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTogglePushNotificationSettingsGET(
    { push_token },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchTogglePushNotificationSettings: refetch,
  });
};

export const unblockAUserDELETE = (
  Constants,
  { blocked_users_id },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/blocked_users`, {
    body: JSON.stringify({ blocked_users_id: blocked_users_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }).then(res => handleResponse(res, handlers));

export const useUnblockAUserDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      unblockAUserDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Blocked Users', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Blocked User');
        queryClient.invalidateQueries('Blocked Users');
      },
    }
  );
};

export const updateProfilePOST = (
  Constants,
  {
    email,
    lat,
    location,
    long,
    phone_number,
    privacy_call,
    privacy_mail,
    privacy_message,
    profile_id,
    profile_name,
    profile_photo,
    type_of_business,
    user_types_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/profile/${
      typeof profile_id === 'string'
        ? profile_id
        : JSON.stringify(profile_id ?? '')
    }`,
    {
      body: JSON.stringify({
        profile_id: profile_id,
        phone_number: phone_number,
        type_of_business: type_of_business,
        location: location,
        coordinates: { type: 'point', data: { lat: lat, lng: long } },
        user_types_id: user_types_id,
        email: email,
        profile_name: profile_name,
        profile_photo: profile_photo,
        privacy_call: privacy_call,
        privacy_message: privacy_message,
        privacy_mail: privacy_mail,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateProfilePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateProfilePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUpdateProfilePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  lat,
  location,
  long,
  phone_number,
  privacy_call,
  privacy_mail,
  privacy_message,
  profile_id,
  profile_name,
  profile_photo,
  type_of_business,
  user_types_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateProfilePOST(
    {
      email,
      lat,
      location,
      long,
      phone_number,
      privacy_call,
      privacy_mail,
      privacy_message,
      profile_id,
      profile_name,
      profile_photo,
      type_of_business,
      user_types_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateProfile: refetch });
};

export const userProfileGET = (Constants, { profile_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/profile/${
      typeof profile_id === 'string'
        ? profile_id
        : JSON.stringify(profile_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useUserProfileGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserProfileGET', args],
    () => userProfileGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUserProfileGETS']),
    }
  );
};

export const FetchUserProfileGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  profile_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserProfileGET(
    { profile_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserProfile: refetch });
};

export const userTypesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/user_types`, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  }).then(res => handleResponse(res, handlers));

export const useUserTypesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserTypesGET', args],
    () => userTypesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUserTypesGETS']),
    }
  );
};

export const FetchUserTypesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserTypesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserTypes: refetch });
};

export const userTypes2GET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:NkVgLCzr/user_types_for_filter`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useUserTypes2GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserTypes2GET', args],
    () => userTypes2GET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUserTypes2GETS']),
    }
  );
};

export const FetchUserTypes2GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserTypes2GET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserTypes2: refetch });
};
