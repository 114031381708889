import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ResetPasswordApi from '../apis/ResetPasswordApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const ResetPasswordVerifyOTPScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [OTP, setOTP] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [resent_msg, setResent_msg] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={24}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
            ),
            dimensions.width
          )}
        >
          {'Reset Password'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 25,
              lineHeight: 30,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {'Enter verification code'}
        </Text>
        {/* Instructions */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_300Light',
              fontSize: 15,
              marginTop: 16,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {
            'We sent an email with a four-digit verification code. Please enter the code below to verify your identity.'
          }
        </Text>
        {/* OTP */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              height: 120,
              justifyContent: 'space-between',
              marginTop: 33,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Divider'], borderRadius: 8 },
              dimensions.width
            )}
          >
            <TextInput
              autoCapitalize={'none'}
              keyboardType={'numeric'}
              maxLength={6}
              onChangeText={newTextInputValue => {
                try {
                  setOTP(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Verification Code'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['input Font'],
                  {
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 30,
                    height: 72,
                    letterSpacing: 1.5,
                    marginLeft: 5,
                    marginRight: 8,
                    marginTop: 5,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
              value={OTP}
            />
          </View>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Error'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {err_msg}
          </Text>
        </View>
        {/* Confirm */}
        <Button
          disabled={!OTP?.length}
          loading={is_Loading}
          onPress={() => {
            const handler = async () => {
              try {
                setErr_msg('');
                setIs_Loading(true);
                const result = (
                  await ResetPasswordApi.verifyOTPPOST(Constants, {
                    email: props.route?.params?.email ?? '',
                    otp_code: OTP,
                  })
                )?.json;
                setErr_msg(result?.message);
                if (!result?.message) {
                  setGlobalVariableValue({
                    key: 'AUTH_TOKEN',
                    value: 'Bearer ' + result,
                  });
                }
                setIs_Loading(false);
                console.log(result);
                if (!result?.message) {
                  navigation.navigate('ResetPasswordScreen');
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
              { marginTop: 20 }
            ),
            dimensions.width
          )}
          title={'Confirm'}
        />
        <>
          {!(resent_msg === undefined) ? null : (
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Custom #329fb3'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginTop: 12,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'OTP has been re-sent to your email'}
            </Text>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ResetPasswordVerifyOTPScreen);
