import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';

const SignupWheretostartScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold' }
            ),
            dimensions.width
          )}
        >
          {'2 of 2'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, padding: 24 },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Custom Color_18'],
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 25,
              lineHeight: 30,
              marginLeft: { minWidth: Breakpoints.Tablet, value: '15%' },
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Welcome to Dirt Near Me!'}
        </Text>
        {/* top logo */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['top logo 2'], {
              marginTop: 25,
            }),
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={Images.DirtNearMe}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                height: 166,
                width: 166,
              }),
              dimensions.width
            )}
          />
        </View>
        {/* Dirt Options */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              marginTop: 25,
              padding: { minWidth: Breakpoints.Tablet, value: '15%' },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 0 },
            },
            dimensions.width
          )}
        >
          {/* heading */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Medium'],
                fontFamily: 'Poppins_500Medium',
                fontSize: 17,
                lineHeight: 30,
                marginBottom: 15,
                marginLeft: { minWidth: Breakpoints.Tablet, value: '15%' },
                opacity: 0.7,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Where would you like to start?'}
          </Text>
          {/* Create Post */}
          <Button
            icon={'AntDesign/pluscircleo'}
            onPress={() => {
              try {
                navigation.navigate('CreatePostScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                {
                  backgroundColor: theme.colors['Custom #ffffff'],
                  borderColor: theme.colors['Primary'],
                  borderWidth: 1,
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  marginBottom: { minWidth: Breakpoints.Laptop, value: 5 },
                }
              ),
              dimensions.width
            )}
            title={'  Create Post'}
          />
          {/* Browse Map */}
          <Button
            icon={'Ionicons/md-map-outline'}
            onPress={() => {
              try {
                navigation.navigate('BottomTabNavigator', {
                  screen: 'HomeScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                {
                  backgroundColor: theme.colors['Custom #ffffff'],
                  borderColor: theme.colors['Primary'],
                  borderWidth: 1,
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  marginTop: [
                    { minWidth: Breakpoints.Tablet, value: 50 },
                    { minWidth: Breakpoints.Mobile, value: 35 },
                  ],
                }
              ),
              dimensions.width
            )}
            title={'  Browse Map'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SignupWheretostartScreen);
