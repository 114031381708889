import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ChatApi from '../apis/ChatApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, Pressable, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';

const ChatListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [API_Response, setAPI_Response] = React.useState({});
  const [chatList, setChatList] = React.useState([]);
  const [isFulfilling, setIsFulfilling] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [my_profile_ID, setMy_profile_ID] = React.useState(0);
  const [my_user_ID, setMy_user_ID] = React.useState(0);
  const [userID_toPass, setUserID_toPass] = React.useState(0);
  const checkUserIDs = item => {
    if (item.user_id == my_user_ID) return item.user2_id;
    return item.user_id;
  };

  const chatDisplay = (dump, item) => {
    if (item.posts_id) {
      if (!item._posts) return false;
      return true;
    }
    return true;
  };

  const chamgeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };

  const useRelativeTimeGetter = chatTime => {
    const time = chatTime;
    var date = new Date().getTime();
    var elapsed = date - new Date(time).getTime();
    // "time" here is the Parameter name you chose //

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' sec ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' mins ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'around ' + Math.round(elapsed / msPerYear) + ' year ago';
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await ChatApi.getAllChatroomsGET(Constants))?.json;
        setChatList(result);
        setIs_Loading(false);
        setAPI_Response(result);
        setMy_profile_ID(Constants['User_Details']?._profile_of_user?.id);
        setMy_user_ID(Constants['User_Details']?.id);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-sharp'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                  textTransform: 'capitalize',
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { marginRight: 70 }
            ),
            dimensions.width
          )}
        >
          {'Chat'}
        </Text>
      </View>
      {/* Chats */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <>
          {!chatList?.length ? null : (
            <FlatList
              data={(() => {
                const e = chatList;
                console.log(e);
                return e;
              })()}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              listKey={'gIibyr01'}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <>
                    {/* Item */}
                    <>
                      {!chatDisplay(isFulfilling, listData) ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              if (listData?.user2_id !== my_user_ID) {
                                setUserID_toPass(listData?.user2_id);
                              }
                              if (listData?.user_id !== my_user_ID) {
                                setUserID_toPass(listData?.user_id);
                              }
                              navigation.navigate('ChatScreen', {
                                profile_pic:
                                  listData?._profile_of_user2?.profile_image
                                    ?.url,
                                post_id: listData?.posts_id,
                                chatroom_id: listData?.id,
                                profile_id: listData?._profile_of_user2?.id,
                                user_icon:
                                  listData?._profile_of_user2?._user_types
                                    ?.icon_path,
                                Other_user_name:
                                  listData?._profile_of_user2?.profile_name,
                                receiver_id: checkUserIDs(listData),
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {listData?._profile_of_user2?.id ===
                            my_profile_ID ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors['Custom #ffffff'],
                                    flexDirection: 'row',
                                    height: 74,
                                    justifyContent: 'space-between',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!listData?._profile_of_user2
                                    ?.profile_image ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${listData?._profile_of_user2?.profile_image?.url}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 22,
                                            height: 44,
                                            width: 44,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                <>
                                  {listData?._profile_of_user2
                                    ?.profile_image ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${listData?._profile_of_user2?._user_types?.icon_path}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 22,
                                            height: 44,
                                            overflow: 'hidden',
                                            width: 44,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Deleted */}
                                <>
                                  {listData?._profile_of_user2
                                    ?.profile_name ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={Images.Deleted}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 25,
                                            height: 50,
                                            left: 16,
                                            position: 'absolute',
                                            width: 50,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* details */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors['Divider'],
                                      flex: 1,
                                      height: '100%',
                                      justifyContent: 'center',
                                      marginLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* name */}
                                  <Text
                                    numberOfLines={1}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Poppins_500Medium',
                                          fontSize: 16,
                                          lineHeight: 20,
                                          textTransform: 'capitalize',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._profile_of_user2?.profile_name}
                                  </Text>
                                  {/* Deleted User */}
                                  <>
                                    {!!listData?._profile_of_user2?.profile_name
                                      ?.length ? null : (
                                      <Text
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Poppins_500Medium',
                                              fontSize: 16,
                                              lineHeight: 20,
                                              textTransform: 'capitalize',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Deleted User'}
                                      </Text>
                                    )}
                                  </>
                                  {/* message */}
                                  <Text
                                    numberOfLines={2}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Custom Color_22'],
                                          fontFamily: 'Poppins_400Regular',
                                          lineHeight: 18,
                                          marginTop: 2,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._last_message?.message}
                                  </Text>
                                </View>
                                {/* time */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors['Divider'],
                                      flex: 0,
                                      flexDirection: 'row',
                                      height: '100%',
                                      justifyContent: 'flex-end',
                                      minWidth: 100,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* time */}
                                  <>
                                    {!listData?._last_message ? null : (
                                      <Text
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Custom Color_22'],
                                              flex: 1,
                                              fontFamily: 'Poppins_300Light',
                                              fontSize: 11,
                                              marginRight: 7,
                                              textAlign: 'right',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {useRelativeTimeGetter(
                                          listData?._last_message?.created_at
                                        )}
                                      </Text>
                                    )}
                                  </>
                                  <>
                                    {!listData?._unread_count ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              theme.colors['Custom Color_26'],
                                            borderRadius: 10,
                                            justifyContent: 'center',
                                            minWidth: 22,
                                            padding: 3,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Custom #ffffff'
                                                  ],
                                                fontFamily: 'Poppins_500Medium',
                                                fontSize: 11,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?._unread_count}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                  <Icon
                                    color={theme.colors['Custom Color_22']}
                                    name={'Entypo/chevron-right'}
                                    size={24}
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 2 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </View>
                            )}
                          </>
                        </Pressable>
                      )}
                    </>
                    {/* Item 2 */}
                    <>
                      {!chatDisplay(isFulfilling, listData) ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              if (listData?.user2_id !== my_user_ID) {
                                setUserID_toPass(listData?.user2_id);
                              }
                              if (listData?.user_id !== my_user_ID) {
                                setUserID_toPass(listData?.user_id);
                              }
                              navigation.navigate('ChatScreen', {
                                profile_pic:
                                  listData?._profile_of_user?.profile_image
                                    ?.url,
                                post_id: listData?.posts_id,
                                chatroom_id: listData?.id,
                                profile_id: listData?._profile_of_user?.id,
                                user_icon:
                                  listData?._profile_of_user?._user_types
                                    ?.icon_path,
                                Other_user_name:
                                  listData?._profile_of_user?.profile_name,
                                receiver_id: checkUserIDs(listData),
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <>
                            {listData?._profile_of_user?.id ===
                            my_profile_ID ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors['Custom #ffffff'],
                                    flexDirection: 'row',
                                    height: 74,
                                    justifyContent: 'space-between',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!listData?._profile_of_user
                                    ?.profile_image ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${listData?._profile_of_user?.profile_image?.url}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 22,
                                            height: 44,
                                            width: 44,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                <>
                                  {listData?._profile_of_user
                                    ?.profile_image ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${listData?._profile_of_user?._user_types?.icon_path}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 22,
                                            height: 44,
                                            overflow: 'hidden',
                                            width: 44,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* Deleted */}
                                <>
                                  {listData?._profile_of_user
                                    ?.profile_name ? null : (
                                    <Image
                                      resizeMode={'cover'}
                                      source={Images.Deleted}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ],
                                          {
                                            borderRadius: 25,
                                            height: 50,
                                            left: 16,
                                            position: 'absolute',
                                            width: 50,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* details */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors['Divider'],
                                      flex: 1,
                                      height: '100%',
                                      justifyContent: 'center',
                                      marginLeft: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* name */}
                                  <Text
                                    numberOfLines={1}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Poppins_500Medium',
                                          fontSize: 16,
                                          lineHeight: 20,
                                          textTransform: 'capitalize',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._profile_of_user?.profile_name}
                                  </Text>
                                  {/* Deleted User */}
                                  <>
                                    {!!listData?._profile_of_user?.profile_name
                                      ?.length ? null : (
                                      <Text
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              fontFamily: 'Poppins_500Medium',
                                              fontSize: 16,
                                              lineHeight: 20,
                                              textTransform: 'capitalize',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Deleted User'}
                                      </Text>
                                    )}
                                  </>
                                  {/* message */}
                                  <Text
                                    numberOfLines={2}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color:
                                            theme.colors['Custom Color_22'],
                                          fontFamily: 'Poppins_400Regular',
                                          lineHeight: 18,
                                          marginTop: 2,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._last_message?.message}
                                  </Text>
                                </View>
                                {/* time */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors['Divider'],
                                      flex: 0,
                                      flexDirection: 'row',
                                      height: '100%',
                                      justifyContent: 'flex-end',
                                      minWidth: 100,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* time */}
                                  <>
                                    {!listData?._last_message ? null : (
                                      <Text
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Custom Color_22'],
                                              flex: 1,
                                              fontFamily: 'Poppins_300Light',
                                              fontSize: 11,
                                              marginRight: 7,
                                              textAlign: 'right',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {useRelativeTimeGetter(
                                          listData?._last_message?.created_at
                                        )}
                                      </Text>
                                    )}
                                  </>
                                  <>
                                    {!listData?._unread_count ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              theme.colors['Custom Color_26'],
                                            borderRadius: 10,
                                            justifyContent: 'center',
                                            minWidth: 22,
                                            padding: 3,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Custom #ffffff'
                                                  ],
                                                fontFamily: 'Poppins_500Medium',
                                                fontSize: 11,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?._unread_count}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                  <Icon
                                    color={theme.colors['Custom Color_22']}
                                    name={'Entypo/chevron-right'}
                                    size={24}
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 2 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </View>
                            )}
                          </>
                        </Pressable>
                      )}
                    </>
                  </>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            />
          )}
        </>
        {/* Empty State */}
        <>
          {chatList?.length ? null : (
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['empty state'],
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    padding: 20,
                  }
                ),
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 31,
                    marginBottom: 10,
                    opacity: 0.5,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Looks like you don’t have chats yet'}
              </Text>
              <Image
                resizeMode={'cover'}
                source={Images.NoDocs}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: 300,
                    width: 300,
                  }),
                  dimensions.width
                )}
              />
            </View>
          )}
        </>
      </View>
      {/* Loading_View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Loading_View 2'],
                { flex: 1 }
              ),
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 125,
                  width: 125,
                }),
                dimensions.width
              )}
            />
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                }),
                dimensions.width
              )}
            >
              {'Fetching your Chat List...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ChatListScreen);
