import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Pressable,
  ScreenContainer,
  Swiper,
  SwiperItem,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { Image, Text, View } from 'react-native';

const OnboardingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  React.useEffect(() => {
    //setCount(count + 1)

    console.log('I am here');
    if (
      lastNotificationResponse &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER &&
      lastNotificationResponse.notification.request.content.data.deeplink
    ) {
      //const { date } = lastNotificationResponse?.notification
      //const timeStamp = new Date(date.toString().split(".")[0] * 1000)
      const { screen, post_id } =
        lastNotificationResponse.notification.request.content.data;

      //if (lastNotificationResponse.notification.request.content.data.deeplink) {
      //const screenName = lastNotificationResponse.notification.request.content.data.screen

      try {
        props.navigation.navigate('MyPostsDetailsActiveScreen', {
          post_id,
        });
      } catch (err) {
        console.error(err);
      }

      return;
      //}
      // Track when notification doesnt have a deep link
    }
  }, [lastNotificationResponse]);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (Constants['AUTH_TOKEN']?.length) {
          const myResponse = (await XanoApi.authMeGET(Constants))?.json;
          console.log(myResponse);
          if (myResponse?.is_email_verified === true) {
            navigation.navigate('BottomTabNavigator', { screen: 'HomeScreen' });
          } else {
            const result = (
              await XanoApi.resendOTPGET(Constants, {
                email: Constants['User_Details']?.email,
              })
            )?.json;

            showAlertUtil({
              title: 'Verification Pending',
              message:
                'Your email verification is pending. Please verify now to continue using the app.',
              buttonText: 'Verify Now',
            });

            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('SignupOTPScreen');
          }
        } else {
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            backgroundColor: theme.colors['Surface'],
            justifyContent: 'flex-end',
          }),
          dimensions.width
        )}
      >
        {/* Skip */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('SignupScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 18,
                }),
                dimensions.width
              )}
            >
              {'Skip'}
            </Text>
          </View>
        </Pressable>
      </View>

      <View
        style={StyleSheet.applyWidth(
          { flex: 1, flexDirection: 'row' },
          dimensions.width
        )}
      >
        <Swiper
          dotActiveColor={theme.colors['Custom Color_18']}
          dotColor={theme.colors.light}
          dotsTouchable={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.SwiperStyles(theme)['Swiper'], {
              maxHeight: 900,
              minHeight: 700,
              paddingBottom: 50,
            }),
            dimensions.width
          )}
        >
          <SwiperItem
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { height: '100%', justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'flex-start',
                    padding: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Image */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'stretch',
                      borderRadius: 8,
                      justifyContent: 'flex-start',
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.DirtNearMeColorsLOGOTRANSPARENCY5}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderRadius: 8,
                          height: 300,
                          overflow: 'hidden',
                          width: 270,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* Description */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 20,
                      letterSpacing: 0.1,
                      lineHeight: 34,
                      marginTop: 40,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'We connect people that \nhave dirt, with people that \nneed dirt.\n'
                  }
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem>
            <View
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'flex-start',
                    padding: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Image */}
                <View
                  style={StyleSheet.applyWidth(
                    { borderRadius: 8 },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.ArvindMap}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderRadius: 8,
                          height: 380,
                          overflow: 'hidden',
                          width: 320,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* Description */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 20,
                      letterSpacing: 0.1,
                      lineHeight: 30,
                      marginTop: 30,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'Our goal is to help you find the materials you need, when you need it.'
                  }
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem>
            <View
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Image */}
                <View>
                  <Image
                    resizeMode={'contain'}
                    source={Images.ArvindMatching}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderRadius: 8,
                          height: 380,
                          overflow: 'hidden',
                          width: 320,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
                {/* Description */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 20,
                      letterSpacing: 0.1,
                      lineHeight: 30,
                      marginTop: 30,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'Sign up below, create a post & match with others in your area!'
                  }
                </Text>
              </View>
              {/* Footer */}
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 24, marginRight: 24, marginTop: 15 },
                  dimensions.width
                )}
              >
                {/* Sign up */}
                <Button
                  onPress={() => {
                    try {
                      navigation.navigate('SignupScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                      {
                        marginLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign up'}
                />
                {/* Login */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginTop: 16,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'Poppins_400Regular', fontSize: 15 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Already have an account? '}
                  </Text>
                  {/* Login */}
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_600SemiBold', fontSize: 15 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Log in'}
                    </Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </SwiperItem>
        </Swiper>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(OnboardingScreen);
