import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ViewStyles = theme =>
  StyleSheet.create({
    Attachment: { height: 64, marginTop: 10 },
    'Attachment 2': { height: 64, marginTop: 10 },
    'Attachment 3': { height: 64, marginTop: 10 },
    Bigscreen_Input_BG: {
      backgroundColor: theme.colors['Divider'],
      borderRadius: 8,
      height: [
        { minWidth: Breakpoints.Mobile, value: 44 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 75 },
      ],
      justifyContent: 'center',
      marginBottom: { minWidth: Breakpoints.Tablet, value: 20 },
      marginTop: [
        { minWidth: Breakpoints.Mobile, value: 4 },
        { minWidth: Breakpoints.Tablet, value: 10 },
      ],
    },
    Bigscreen_header_btn: {
      alignItems: 'center',
      flexDirection: 'row',
      height: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 80 },
      ],
      justifyContent: 'center',
      width: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 80 },
      ],
    },
    'Err View': {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      bottom: 0,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 50,
    },
    Loading_View: {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      bottom: 0,
      justifyContent: 'center',
      left: 0,
      paddingBottom: 75,
      position: 'absolute',
      right: 0,
      top: 48,
    },
    'Loading_View 2': {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      bottom: 0,
      justifyContent: 'center',
      left: 0,
      paddingBottom: 75,
      position: 'absolute',
      right: 0,
      top: 48,
    },
    'Nav Btn Bigscreen': {
      alignItems: 'center',
      height: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 75 },
      ],
      justifyContent: 'center',
      width: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 75 },
      ],
    },
    'Nav Btn Phone': {
      alignItems: 'center',
      height: 48,
      justifyContent: 'center',
      width: 48,
    },
    'Nav Btn Tablet': {
      alignItems: 'center',
      height: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
      ],
      justifyContent: 'center',
      width: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.Tablet, value: 60 },
      ],
    },
    Phone_Input_BG: {
      backgroundColor: theme.colors['Divider'],
      borderRadius: 8,
      height: 44,
      justifyContent: 'center',
      marginTop: 4,
    },
    Phone_header_btn: {
      alignItems: 'center',
      flexDirection: 'row',
      height: 48,
      justifyContent: 'center',
      width: 48,
    },
    Post_Badge: {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom Color_29'],
      borderRadius: 9,
      height: 18,
      justifyContent: 'center',
      position: 'absolute',
      right: 15,
      width: 18,
    },
    'Post_Badge 2': {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom Color_29'],
      borderRadius: 9,
      height: 18,
      justifyContent: 'center',
      position: 'absolute',
      right: 15,
      width: 18,
    },
    'Post_Badge 3': {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom Color_29'],
      borderRadius: 9,
      height: 18,
      justifyContent: 'center',
      position: 'absolute',
      right: 15,
      width: 18,
    },
    SocialLogins: { marginLeft: 4, marginRight: 4, padding: 20, paddingTop: 8 },
    Tablet_Input_BG: {
      backgroundColor: theme.colors['Divider'],
      borderRadius: 8,
      height: { minWidth: Breakpoints.Tablet, value: 60 },
      justifyContent: 'center',
      marginBottom: { minWidth: Breakpoints.Tablet, value: 20 },
      marginTop: 4,
    },
    Top_Header: {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    'click link': {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
    colorCode: {
      flexDirection: 'row',
      height: '100%',
      marginRight: 5,
      width: 20,
    },
    'colorCode 2': {
      flexDirection: 'row',
      height: '100%',
      marginRight: 5,
      width: 20,
    },
    'colorCode 3': {
      flexDirection: 'row',
      height: '100%',
      marginRight: 5,
      width: 20,
    },
    'colorCode 4': {
      flexDirection: 'row',
      height: '100%',
      marginRight: 5,
      width: 20,
    },
    'colorCode 5': {
      flexDirection: 'row',
      height: '100%',
      marginRight: 5,
      width: 20,
    },
    'empty state': { alignItems: 'center', flex: 1, justifyContent: 'center' },
    'empty state 2': {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    'empty state 3': {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    'empty state 4': {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    'empty state 5': {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    'empty state 6': {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    'location selection View': {
      backgroundColor: theme.colors['Custom #ffffff'],
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    menu_Option_Phone: {
      borderBottomWidth: 1,
      borderColor: theme.colors['Light'],
      height: 50,
      justifyContent: 'center',
      paddingLeft: 16,
    },
    prof2: { flex: 1 },
    referral_details: { flex: 1, marginTop: 16 },
    tabbar: {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      borderColor: theme.colors['Divider'],
      borderTopWidth: 1,
      flexDirection: 'row',
      height: 55,
      justifyContent: 'space-between',
      paddingTop: 6,
    },
    'tabbar 2': {
      alignItems: 'center',
      backgroundColor: theme.colors['Custom #ffffff'],
      borderColor: theme.colors['Divider'],
      borderTopWidth: 1,
      flexDirection: 'row',
      height: 55,
      justifyContent: 'space-between',
      paddingTop: 6,
    },
    'top logo': {
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 15,
    },
    'top logo 2': {
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 15,
    },
    'top menu': {
      alignItems: 'stretch',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 20,
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    'Action Btn Bigscreen': {
      backgroundColor: theme.colors['Primary Blue'],
      borderRadius: 13,
      color: theme.colors['Custom #ffffff'],
      fontFamily: 'Poppins_600SemiBold',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.Tablet, value: 21 },
        { minWidth: Breakpoints.BigScreen, value: 25 },
      ],
      height: [
        { minWidth: Breakpoints.Mobile, value: 44 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 75 },
      ],
      marginTop: 16,
      textAlign: 'center',
    },
    'Action Btn Phone': {
      backgroundColor: theme.colors['Primary Blue'],
      borderRadius: 13,
      color: theme.colors['Custom #ffffff'],
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 16,
      height: 44,
      textAlign: 'center',
    },
    'Action Btn Tablet': {
      backgroundColor: theme.colors['Primary Blue'],
      borderRadius: 13,
      color: theme.colors['Custom #ffffff'],
      fontFamily: 'Poppins_600SemiBold',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.Tablet, value: 21 },
        { minWidth: Breakpoints.BigScreen, value: 25 },
      ],
      height: [
        { minWidth: Breakpoints.Mobile, value: 44 },
        { minWidth: Breakpoints.Tablet, value: 60 },
        { minWidth: Breakpoints.BigScreen, value: 75 },
      ],
      marginTop: 16,
      textAlign: 'center',
    },
    Button: { fontFamily: 'System', fontWeight: '700', textAlign: 'center' },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const SwitchRowStyles = theme =>
  StyleSheet.create({ 'Switch Row': { marginLeft: 0, marginRight: 0 } });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { width: 100 },
    infoImgs: { height: 55, width: 55 },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input': {
      borderRadius: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input Phone': {
      borderBottomWidth: 0,
      borderColor: theme.colors['Primary Blue'],
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      color: theme.colors['Primary Blue'],
      fontFamily: 'Poppins_400Regular',
      fontSize: 17,
      marginLeft: 8,
      marginRight: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'input Font': {
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRadius: 8,
      borderRightWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Poppins_400Regular',
      marginLeft: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { flex: 1 } });

export const TextStyles = theme =>
  StyleSheet.create({
    'Back Phone': {
      color: theme.colors['Primary'],
      fontFamily: 'Poppins_400Regular',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.Tablet, value: 20 },
      ],
    },
    'Back Tablet': {
      color: theme.colors['Primary'],
      fontFamily: 'Poppins_400Regular',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.Tablet, value: 20 },
      ],
    },
    'Back bigscreen': {
      color: theme.colors['Primary'],
      fontFamily: 'Poppins_400Regular',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.Tablet, value: 20 },
        { minWidth: Breakpoints.BigScreen, value: 25 },
      ],
    },
    'Bottom Tab Text': {
      color: theme.colors['Custom Color_23'],
      fontFamily: 'Poppins_500Medium',
      fontSize: 11,
      marginTop: 5,
    },
    'Label Values': {
      color: theme.colors['Custom Color_28'],
      fontFamily: 'Poppins_300Light',
      fontSize: 16,
      marginLeft: 8,
      marginTop: 10,
    },
    'Screen heading Bigscreen': {
      color: theme.colors['Primary Blue'],
      flex: 1,
      fontFamily: 'Poppins_500Medium',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 18 },
        { minWidth: Breakpoints.Tablet, value: 21 },
        { minWidth: Breakpoints.BigScreen, value: 25 },
      ],
      textAlign: 'center',
    },
    'Screen heading Phone': {
      color: theme.colors['Primary Blue'],
      flex: 1,
      fontFamily: 'Poppins_500Medium',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 18 },
        { minWidth: Breakpoints.Tablet, value: 21 },
        { minWidth: Breakpoints.BigScreen, value: 25 },
      ],
      lineHeight: 48,
      textAlign: 'center',
    },
    'Screen heading Tablet': {
      color: theme.colors['Primary Blue'],
      flex: 1,
      fontFamily: 'Poppins_500Medium',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 18 },
        { minWidth: Breakpoints.Tablet, value: 21 },
      ],
      lineHeight: { minWidth: Breakpoints.Tablet, value: 60 },
      marginRight: 48,
      textAlign: 'center',
    },
    bot: {
      color: theme.colors['Primary'],
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
      marginTop: 5,
    },
    detail_heading: {
      color: theme.colors['Primary Blue'],
      fontFamily: 'Poppins_400Regular',
      fontSize: 17,
    },
    info_texts: {
      color: theme.colors['Primary Blue'],
      fontFamily: 'Poppins_500Medium',
      fontSize: 21,
      marginLeft: 30,
    },
    post_list_date: {
      color: theme.colors['Primary Blue'],
      fontFamily: 'Poppins_300Light',
      fontSize: 15,
      lineHeight: 20,
      marginTop: 5,
    },
  });

export const SwiperStyles = theme =>
  StyleSheet.create({ Swiper: { width: '100%' } });

export const SurfaceStyles = theme =>
  StyleSheet.create({ Surface: { minHeight: 40 } });

export const MapViewStyles = theme =>
  StyleSheet.create({ 'Map View': { flex: 1, height: '100%', width: '100%' } });

export const PressableStyles = theme =>
  StyleSheet.create({
    back_btn_Phone: { marginLeft: 5 },
    header_text_btn_phone: { marginLeft: 10, marginRight: 10, minWidth: 48 },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { flex: 1 } });

export const ActionSheetItemStyles = theme =>
  StyleSheet.create({ 'Action Sheet Item': { textAlign: 'center' } });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({ 'Activity Indicator': { height: 36, width: 36 } });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({ 'Checkbox Row': { minHeight: 50 } });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
  });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: { alignItems: 'center', justifyContent: 'center' },
  });
