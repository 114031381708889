import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openCameraUtil from '../utils/openCamera';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  SwitchRow,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const SocialCompleteProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [all_profile_types, setAll_profile_types] = React.useState([]);
  const [call, setCall] = React.useState(true);
  const [chat, setChat] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [first_name, setFirst_name] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [last_name, setLast_name] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [location_search_text, setLocation_search_text] = React.useState('');
  const [mail, setMail] = React.useState(true);
  const [myLat, setMyLat] = React.useState('');
  const [myLong, setMyLong] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [profile_image, setProfile_image] = React.useState('');
  const [referral_details, setReferral_details] = React.useState('');
  const [referral_modal, setReferral_modal] = React.useState(false);
  const [selected_businessClassification, setSelected_businessClassification] =
    React.useState('');
  const [selected_profile_type, setSelected_profile_type] = React.useState('');
  const [selected_profile_type_id, setSelected_profile_type_id] =
    React.useState(0);
  const [show_Image_Picker, setShow_Image_Picker] = React.useState(false);
  const [
    show_businessClassification_Modal,
    setShow_businessClassification_Modal,
  ] = React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_profileType_Modal, setShow_profileType_Modal] =
    React.useState(false);
  const profile_Inputs_Validation = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;

    let foundError = false;
    if (email.length < 1) {
      setErr_msg('missing email');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (phone.length < 1) {
      setErr_msg('missing phone number');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (selected_profile_type.length < 1) {
      setErr_msg('Please select your Profile type');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (location.length < 1) {
      setErr_msg('missing location');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setErr_msg('');
        const result = (await XanoApi.userTypesGET(Constants))?.json;
        setAll_profile_types(result);
        setFirst_name(props.route?.params?.first_name ?? '');
        setLast_name(props.route?.params?.last_name ?? '');
        setEmail(props.route?.params?.email ?? '');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Profile Set up */}
      <View
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['prof2'],
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
              borderBottomWidth: 1,
              borderColor: theme.colors['Divider'],
              height: 48,
              paddingLeft: 10,
              paddingRight: 10,
            }),
            dimensions.width
          )}
        >
          {/* Back */}
          <Pressable
            activeOpacity={0.3}
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Primary']}
                name={'Feather/chevron-left'}
                size={24}
              />
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 16,
                    lineHeight: 48,
                    marginLeft: 2,
                  }),
                  dimensions.width
                )}
              >
                {'Back'}
              </Text>
            </View>
          </Pressable>
          {/* screen heading */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
              ),
              dimensions.width
            )}
          >
            {'1  of 2'}
          </Text>
        </View>

        <KeyboardAwareScrollView
          contentContainerStyle={StyleSheet.applyWidth(
            {
              padding: { minWidth: Breakpoints.Tablet, value: '15%' },
              paddingTop: { minWidth: Breakpoints.Tablet, value: 0 },
            },
            dimensions.width
          )}
          keyboardShouldPersistTaps={'always'}
          showsVerticalScrollIndicator={true}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, paddingTop: 24 },
              dimensions.width
            )}
          >
            {/* Complete Profile */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  paddingBottom: 8,
                  paddingLeft: 24,
                  paddingRight: 24,
                },
                dimensions.width
              )}
            >
              {/* Section heading */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 25,
                    lineHeight: 33,
                    marginBottom: {
                      minWidth: Breakpoints.Tablet,
                      value: '10%',
                    },
                    textAlign: [
                      { minWidth: Breakpoints.Tablet, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                  }),
                  dimensions.width
                )}
              >
                {'Create Your Profile'}
              </Text>
              {/* Profile Image */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginTop: 16 },
                  dimensions.width
                )}
              >
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_Image_Picker(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <>
                    {profile_image?.length ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={Images.ProfileImage}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            { height: 166, width: 166 }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {!profile_image?.length ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{ uri: `${profile_image}` }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            { height: 166, width: 166 }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </Pressable>
              </View>
              {/* First Name */}
              <>
                {Constants['isAppleLogin'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'First Name*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          {
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            height: 44,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'words'}
                        keyboardType={'default'}
                        onChangeText={newTextInputValue => {
                          try {
                            setFirst_name(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter first name'}
                        placeholderTextColor={theme.colors['Primary']}
                        secureTextEntry={false}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Text Input Phone'
                          ],
                          dimensions.width
                        )}
                        textContentType={'name'}
                        value={first_name}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Last Name */}
              <>
                {Constants['isAppleLogin'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Last Name*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          {
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            height: 44,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'words'}
                        keyboardType={'default'}
                        onChangeText={newTextInputValue => {
                          try {
                            setLast_name(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter last name'}
                        placeholderTextColor={theme.colors['Primary']}
                        secureTextEntry={false}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Text Input Phone'
                          ],
                          dimensions.width
                        )}
                        textContentType={'familyName'}
                        value={last_name}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Email */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                    }),
                    dimensions.width
                  )}
                >
                  {'Email*'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      {
                        alignItems: 'stretch',
                        flexDirection: 'column',
                        height: 44,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    keyboardType={'email-address'}
                    onChangeText={newTextInputValue => {
                      try {
                        setEmail(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Input your email'}
                    placeholderTextColor={theme.colors['Primary']}
                    secureTextEntry={false}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                        { height: 44 }
                      ),
                      dimensions.width
                    )}
                    value={email}
                  />
                </View>
              </View>
              {/* Phone Number */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                    }),
                    dimensions.width
                  )}
                >
                  {'Phone Number*'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: 44,
                        justifyContent: 'flex-start',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      keyboardType={'phone-pad'}
                      onChangeText={newTextInputValue => {
                        try {
                          setPhone(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Input your Phone number'}
                      placeholderTextColor={theme.colors['Primary']}
                      secureTextEntry={false}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                        dimensions.width
                      )}
                      value={phone}
                    />
                  </View>
                </View>
              </View>
              {/* Profile Type */}
              <Pressable
                activeOpacity={0.3}
                disabled={false}
                onPress={() => {
                  try {
                    setShow_profileType_Modal(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* Profile Type */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Profile Type*'}
                  </Text>

                  <View
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {selected_profile_type?.length ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 55,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Profile type'}
                        </Text>
                      )}
                    </>
                    {/* Selected Value */}
                    <>
                      {!selected_profile_type?.length ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 44,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {selected_profile_type}
                        </Text>
                      )}
                    </>
                  </View>
                </View>
              </Pressable>
              {/* Business Classfication */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_businessClassification_Modal(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Business Classification'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {selected_businessClassification ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 55,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Business Classification'}
                        </Text>
                      )}
                    </>
                    {/* Selected Value */}
                    <>
                      {!selected_businessClassification ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 44,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {selected_businessClassification}
                        </Text>
                      )}
                    </>
                  </View>
                </View>
              </Pressable>
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 20, marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                    }),
                    dimensions.width
                  )}
                >
                  {'Location*'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setLocation_search_text('');
                      setShow_location_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {location ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 55,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Location'}
                        </Text>
                      )}
                    </>
                    {/* Selected Value */}
                    <>
                      {!location ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                lineHeight: 44,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {location}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* prefs */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 15 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 17,
                      marginTop: 5,
                    }),
                    dimensions.width
                  )}
                >
                  {'Contact Preferences'}
                </Text>
                {/* Call */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginTop: 25,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'MaterialIcons/call'}
                    size={24}
                  />
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <SwitchRow
                      activeTrackColor={theme.colors['Primary Blue']}
                      label={'Call'}
                      onValueChange={newSwitchRowValue => {
                        try {
                          setCall(newSwitchRowValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                          {
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            marginLeft: 15,
                          }
                        ),
                        dimensions.width
                      )}
                      value={call}
                    />
                  </View>
                </View>
                {/* Chat */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginTop: 25,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'MaterialIcons/chat-bubble'}
                    size={24}
                  />
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <SwitchRow
                      activeTrackColor={theme.colors['Primary Blue']}
                      label={'Chat'}
                      onValueChange={newSwitchRowValue => {
                        try {
                          setChat(newSwitchRowValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                          {
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            marginLeft: 15,
                          }
                        ),
                        dimensions.width
                      )}
                      value={chat}
                    />
                  </View>
                </View>
                {/* Email */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginTop: 25,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'MaterialIcons/email'}
                    size={24}
                  />
                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <SwitchRow
                      activeTrackColor={theme.colors['Primary Blue']}
                      label={'Email'}
                      onValueChange={newSwitchRowValue => {
                        try {
                          setMail(newSwitchRowValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                          {
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            marginLeft: 15,
                          }
                        ),
                        dimensions.width
                      )}
                      value={mail}
                    />
                  </View>
                </View>
              </View>
              {/* referral_details */}
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['referral_details'],
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                    }),
                    dimensions.width
                  )}
                >
                  {'How did you discover Dirt Near Me? (Optional)'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setReferral_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                        { height: 44 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Placeholder */}
                    <>
                      {referral_details ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select your answer'}
                        </Text>
                      )}
                    </>
                    {/* Selected Value */}
                    <>
                      {!referral_details ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 17,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {referral_details}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* err message */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 15,
                    marginTop: 16,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {err_msg}
              </Text>
              {/* Submit  */}
              <Button
                loading={is_Loading}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const foundError = profile_Inputs_Validation();
                      if (foundError) {
                        return;
                      }
                      setErr_msg('');
                      setIs_Loading(true);
                      const result = (
                        await XanoApi.socialCompleteProfilePOST(Constants, {
                          company_type: selected_businessClassification,
                          email: email,
                          first_name: first_name,
                          last_name: last_name,
                          lat: myLat,
                          location: location,
                          long: myLong,
                          phone_number: phone,
                          privacy_call: call,
                          privacy_mail: mail,
                          privacy_message: chat,
                          profile_image: profile_image,
                          referral_details: referral_details,
                          user_type: selected_profile_type_id,
                        })
                      )?.json;
                      setGlobalVariableValue({
                        key: 'User_Details',
                        value: result?.user,
                      });
                      setErr_msg(result?.message);
                      setIs_Loading(false);
                      if (result?.user) {
                        navigation.navigate('SignupAppPermissionsScreen');
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                    { fontSize: 18, marginBottom: 16 }
                  ),
                  dimensions.width
                )}
                title={'Submit'}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
        {/* Profile Types Modal */}
        <>
          {!show_profileType_Modal ? null : (
            <Modal animationType={'fade'} transparent={true}>
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* Profile Type List */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 385, justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup>
                    <FlatList
                      data={all_profile_types}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'hyo0qVcQ'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                setShow_profileType_Modal(false);
                                setSelected_profile_type(listData?.label);
                                setSelected_profile_type_id(listData?.value);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Primary Yellow'],
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  padding: 12,
                                  paddingLeft: 30,
                                  width: 300,
                                },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'cover'}
                                source={{ uri: `${listData?.icon_path}` }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image'],
                                    { height: 30, width: 30 }
                                  ),
                                  dimensions.width
                                )}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.label}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      style={StyleSheet.applyWidth(
                        { height: 400 },
                        dimensions.width
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderRadius: 8,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                    />
                  </RadioButtonGroup>
                </View>
              </View>
            </Modal>
          )}
        </>
        {/* Referral Options Modal */}
        <Modal
          animationType={'fade'}
          transparent={true}
          visible={referral_modal}
        >
          {/* Black BG View */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Custom Color_18'],
                bottom: 0,
                left: 0,
                opacity: 0.5,
                position: 'absolute',
                right: 0,
                top: 0,
              },
              dimensions.width
            )}
          />
          {/* List */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { height: 385, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <FlatList
                data={[
                  'Search Engine (Google, etc.)',
                  'Social Media',
                  'Friend',
                  'Other',
                ]}
                keyExtractor={(listData, index) =>
                  listData?.id ?? listData?.uuid ?? index.toString()
                }
                listKey={'YmmN19Yq'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setReferral_details(listData);
                          setReferral_modal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Primary Yellow'],
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 12,
                            paddingLeft: 30,
                            width: 300,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                flex: 1,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 15,
                                marginLeft: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData}
                        </Text>
                      </View>
                    </Pressable>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth({ height: 400 }, dimensions.width)}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderRadius: 8,
                    justifyContent: 'center',
                    overflow: 'hidden',
                  },
                  dimensions.width
                )}
              />
            </View>
          </View>
        </Modal>
        {/* Business Classifications Modal */}
        <>
          {!show_businessClassification_Modal ? null : (
            <Modal animationType={'fade'} transparent={true}>
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* view */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 200 },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup>
                    <FlatList
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderRadius: 8,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                      data={['N/A', 'WBE', 'MBE', 'DBE']}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'IGhZHZWs'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                setSelected_businessClassification(listData);
                                setShow_businessClassification_Modal(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Primary Yellow'],
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  padding: 12,
                                  paddingLeft: 12,
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </RadioButtonGroup>
                </View>
              </View>
            </Modal>
          )}
        </>
      </View>
      {/* Location Modal */}
      <>
        {!show_location_modal ? null : (
          <Modal animationType={'none'}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Divider'],
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 12,
                  marginRight: 12,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 50,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'Change Location'}
              </Text>

              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_location_modal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                          marginRight: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Close'}
                  </Text>
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'Ionicons/md-close'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>

            <View
              style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
            >
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={100}
                onChangeText={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Search a location'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'],
                    {
                      borderColor: theme.colors['Divider'],
                      borderWidth: 1,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginBottom: 15,
                      paddingLeft: 16,
                    }
                  ),
                  dimensions.width
                )}
                value={location_search_text}
              />
              <XanoGooglePlacesApi.FetchSearchGET search={location_search_text}>
                {({ loading, error, data, refetchSearch }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <FlatList
                      data={fetchData?.predictions}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'l8ar8Jhe'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const coords = (
                                    await XanoGooglePlacesApi.getCoordinatesGET(
                                      Constants,
                                      { place_id: listData?.place_id }
                                    )
                                  )?.json;
                                  setLocation(listData?.description);
                                  setMyLat(
                                    (() => {
                                      const e =
                                        coords?.result?.geometry?.location?.lat;
                                      console.log(e);
                                      return e;
                                    })()
                                  );
                                  setMyLong(
                                    (() => {
                                      const e =
                                        coords?.result?.geometry?.location?.lng;
                                      console.log(e);
                                      return e;
                                    })()
                                  );
                                  setShow_location_modal(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  paddingBottom: 10,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Entypo/location-pin'}
                                size={24}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.description}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XanoGooglePlacesApi.FetchSearchGET>
            </View>
          </Modal>
        )}
      </>
      {/* Image Picker */}
      <ActionSheet visible={show_Image_Picker}>
        {/* Gallery */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Gallery'}
          onPress={() => {
            const handler = async () => {
              try {
                const image = await openImagePickerUtil({
                  mediaTypes: 'All',
                  allowsEditing: false,
                  quality: 0.2,
                });

                setProfile_image(image);
                setShow_Image_Picker(false);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Camera */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Camera'}
          onPress={() => {
            const handler = async () => {
              try {
                const image = await openCameraUtil({
                  mediaTypes: 'Images',
                  allowsEditing: false,
                  cameraType: 'front',
                  videoMaxDuration: undefined,
                  quality: 0.2,
                });

                setProfile_image(image);
                setShow_Image_Picker(false);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Cancel */}
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setShow_Image_Picker(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
    </ScreenContainer>
  );
};

export default withTheme(SocialCompleteProfileScreen);
