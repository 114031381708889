import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NotificationsApi from '../apis/NotificationsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const NotificationsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [curPage, setCurPage] = React.useState(1);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [nextPage, setNextPage] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  const [prevPage, setPrevPage] = React.useState(0);
  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const notificationsMarkSeenPOST = NotificationsApi.useMarkSeenPOST();

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            const handler = async () => {
              try {
                (await notificationsMarkSeenPOST.mutateAsync())?.json;
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-sharp'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                  textTransform: 'capitalize',
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* Notifications */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { marginRight: 80 }
            ),
            dimensions.width
          )}
        >
          {'Notifications'}
        </Text>
      </View>
      {/* Notifications */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <NotificationsApi.FetchGetNotificationGET
          handlers={{
            onData: fetchData => {
              try {
                setNextPage(fetchData?.nextPage);
                setPrevPage(fetchData?.prevPage);
                setIs_Loading(false);
              } catch (err) {
                console.error(err);
              }
            },
          }}
          page={curPage}
        >
          {({ loading, error, data, refetchGetNotification }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <View>
                  <FlatList
                    data={fetchData?.items}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'zbPbwhfK'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* Item */}
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                if (listData?.type === 'match') {
                                  navigation.navigate(
                                    'MyPostsDetailsActiveScreen',
                                    { post_id: listData?.posts_id }
                                  );
                                }
                                if (listData?.type === 'chat') {
                                  navigation.navigate('ChatScreen');
                                }
                                if (listData?.type === 'expiry') {
                                  navigation.navigate(
                                    'MyPostsDetailsExpiredScreen',
                                    { post_id: listData?.posts_id }
                                  );
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  height: 98,
                                  justifyContent: 'space-between',
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'cover'}
                                source={Images.Icon}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image'],
                                    { height: 40, width: 40 }
                                  ),
                                  dimensions.width
                                )}
                              />
                              {/* details */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: 1,
                                    height: '100%',
                                    justifyContent: 'center',
                                    marginLeft: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Notification */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary Blue'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 17,
                                        lineHeight: 20,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.notification}
                                </Text>
                                {/* time */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary Blue'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                        marginTop: 8,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {changeDateFormat(
                                    listData?.created_at,
                                    'MMM DD, YYYY'
                                  )}
                                  {' at '}
                                  {changeDateFormat(
                                    listData?.created_at,
                                    'hh:mm A'
                                  )}
                                </Text>
                              </View>
                              <>
                                {listData?.seen ? null : (
                                  <Circle
                                    size={10}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CircleStyles(theme)[
                                          'Circle'
                                        ],
                                        {
                                          backgroundColor:
                                            theme.colors['Primary Blue'],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </Pressable>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Previous */}
                    <>
                      {!prevPage ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setCurPage(prevPage);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Divider'],
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_500Medium',
                                width: '35%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Previous'}
                        />
                      )}
                    </>
                    {/* Next */}
                    <>
                      {!nextPage ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setCurPage(nextPage);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: theme.colors['Divider'],
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_500Medium',
                                width: '35%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Next'}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* Empty State */}
                <>
                  {fetchData?.items?.length ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 22,
                              lineHeight: 28,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Looks like you don’t have notifications yet'}
                      </Text>
                    </View>
                  )}
                </>
              </>
            );
          }}
        </NotificationsApi.FetchGetNotificationGET>
      </View>
      {/* LoadingView */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                bottom: 0,
                flex: 1,
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 10,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching the notifications...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(NotificationsScreen);
