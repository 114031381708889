import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const FavouritesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [All_Posts, setAll_Posts] = React.useState([]);
  const [Fav_Truckers, setFav_Truckers] = React.useState([]);
  const [isFulfilling, setIsFulfilling] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [selected_Menu, setSelected_Menu] = React.useState('Posts');
  const postDisplay = (dump, item) => {
    if (item.posts_id) {
      if (!item._posts) return false;
      return true;
    }
    return true;
  };

  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (await PostsApi.getFavoritePostsGET(Constants))?.json;
        setAll_Posts(result);
        const api_Response_Truckers = (
          await XanoApi.getFavoriteTruckersGET(Constants)
        )?.json;
        setFav_Truckers(api_Response_Truckers);
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Matched Posts Count */}
      <PostsApi.FetchMatchedPostsCountGET
        handlers={{
          onData: matchedPostsCountData => {
            try {
              setGlobalVariableValue({
                key: 'matched_posts_count',
                value: matchedPostsCountData?.count,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        refetchInterval={Constants['polling_interval']}
      >
        {({ loading, error, data, refetchMatchedPostsCount }) => {
          const matchedPostsCountData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </PostsApi.FetchMatchedPostsCountGET>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
            height: 48,
            paddingLeft: 8,
            paddingRight: 8,
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Screen heading Phone'],
            dimensions.width
          )}
        >
          {'Favorite Posts'}
        </Text>
      </View>
      {/* Top Menu */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 10,
            padding: 20,
            paddingBottom: 8,
            paddingTop: 8,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Light Button Color'],
              borderRadius: 9,
              flexDirection: 'row',
              height: 32,
              justifyContent: 'center',
              padding: 2,
              width: '60%',
            },
            dimensions.width
          )}
        >
          {/* Posts */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '50%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Posts') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Posts'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Posts' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Posts');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Posts'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
          {/* Truckers */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'stretch', justifyContent: 'center', width: '50%' },
              dimensions.width
            )}
          >
            {/* selected */}
            <>
              {!(selected_Menu === 'Truck') ? null : (
                <Pressable activeOpacity={0.3}>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Primary Blue'],
                        borderRadius: 7,
                        height: 28,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Custom #ffffff'],
                            fontFamily: 'Poppins_500Medium',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Truckers'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* unselected */}
            <>
              {selected_Menu === 'Truck' ? null : (
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setSelected_Menu('Truck');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Truckers'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
          </View>
        </View>
      </View>
      {/* Posts */}
      <>
        {!(selected_Menu === 'Posts') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Custom #ffffff'],
                flex: 1,
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            <>
              {!(All_Posts?.length > 0) ? null : (
                <FlatList
                  data={(() => {
                    const e = All_Posts;
                    console.log(e);
                    return e;
                  })()}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'AijrnHQb'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {!postDisplay(isFulfilling, listData) ? null : (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                navigation.navigate('PostDetailsScreen', {
                                  post_id: listData?.posts_id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* colorCode */}
                              <View
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ViewStyles(theme)['colorCode 5'],
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!(
                                    listData?._posts?.type === 'Need'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Primary Yellow'],
                                          height: '100%',
                                          width: 15,
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                <>
                                  {!(
                                    listData?._posts?.type === 'Have'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Primary Blue'],
                                          height: '100%',
                                          width: 15,
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors['Divider'],
                                    flex: 1,
                                    flexDirection: 'row',
                                    height: 120,
                                    justifyContent: 'space-between',
                                    paddingRight: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Details */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      marginLeft: 6,
                                      paddingRight: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    numberOfLines={1}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Primary Blue'],
                                          fontFamily: 'Poppins_500Medium',
                                          fontSize: 17,
                                          lineHeight: 22,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._posts?.type}{' '}
                                    {addCommasToNumber(
                                      listData?._posts?.quantity
                                    )}
                                    {' CYD - '}
                                    {listData?._posts?._material_types?.name}
                                  </Text>

                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Primary Blue'],
                                          fontFamily: 'Poppins_300Light',
                                          fontSize: 15,
                                          lineHeight: 24,
                                          marginTop: 2,
                                          textTransform: 'capitalize',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?._posts?.location}
                                  </Text>

                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: theme.colors['Primary Blue'],
                                          fontFamily: 'Poppins_300Light',
                                          fontSize: 15,
                                          lineHeight: 20,
                                          marginTop: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Available From  : '}
                                    {changeDateFormat(
                                      listData?._posts?.availability_date,
                                      Constants['AppDateformat']
                                    )}
                                  </Text>
                                  <>
                                    {!(
                                      listData?.post?.post_expired_at !==
                                      'Never'
                                    ) ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Primary Blue'],
                                              fontFamily: 'Poppins_300Light',
                                              fontSize: 15,
                                              lineHeight: 20,
                                              marginTop: 5,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Available Until   : '}
                                        {changeDateFormat(
                                          listData?._posts?.expires_at,
                                          Constants['AppDateformat']
                                        )}
                                      </Text>
                                    )}
                                  </>
                                  <>
                                    {!(
                                      listData?.post?.post_expired_at ===
                                      'Never'
                                    ) ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color:
                                                theme.colors['Primary Blue'],
                                              fontFamily: 'Poppins_300Light',
                                              fontSize: 15,
                                              lineHeight: 20,
                                              marginTop: 5,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Available Until: Always'}
                                      </Text>
                                    )}
                                  </>
                                </View>
                                <Icon
                                  color={theme.colors['Primary']}
                                  name={'Feather/chevron-right'}
                                  size={24}
                                />
                              </View>
                            </View>
                          </Pressable>
                        )}
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
            {/* empty state */}
            <>
              {!(All_Posts?.length === 0) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 5'],
                      { padding: 20 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'You don’t have favorites'}
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Truckers */}
      <>
        {!(selected_Menu === 'Truck') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors['Custom #ffffff'], flex: 1 },
              dimensions.width
            )}
          >
            <>
              {!Fav_Truckers ? null : (
                <FlatList
                  contentContainerStyle={StyleSheet.applyWidth(
                    { backgroundColor: theme.colors['Custom #ffffff'] },
                    dimensions.width
                  )}
                  data={Fav_Truckers}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'xhhGaIdu'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            navigation.navigate('UserProfileScreen', {
                              profile_id: (() => {
                                const e = listData?.trucker_id;
                                console.log(e);
                                return e;
                              })(),
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              height: 75,
                              justifyContent: 'space-between',
                              paddingLeft: 15,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!listData?._profile?.profile_image ? null : (
                              <Image
                                resizeMode={'cover'}
                                source={{
                                  uri: `${listData?._profile?.profile_image?.url}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image'],
                                    { borderRadius: 8, height: 55, width: 55 }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <>
                            {listData?._profile?.profile_image ? null : (
                              <Image
                                resizeMode={'cover'}
                                source={{
                                  uri: 'http://www.dirtnearme.com/images/usertype/dump-truck.png',
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image'],
                                    { height: 55, width: 55 }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          {/* Details */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Divider'],
                                flex: 1,
                                height: 75,
                                justifyContent: 'center',
                                marginLeft: 20,
                                paddingRight: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              numberOfLines={1}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                    lineHeight: 22,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?._profile?.profile_name}
                            </Text>

                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_300Light',
                                    fontSize: 15,
                                    lineHeight: 24,
                                    marginTop: 2,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?._profile?.location}
                            </Text>
                          </View>
                          <Icon
                            color={theme.colors['Primary']}
                            name={'Feather/chevron-right'}
                            size={24}
                          />
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              )}
            </>
            {/* empty state */}
            <>
              {Fav_Truckers?.length ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 5'],
                      {
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 0,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'You don’t have favorites'}
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                bottom: 0,
                justifyContent: 'center',
                left: 0,
                paddingBottom: 75,
                position: 'absolute',
                right: 0,
                top: 48,
              },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching your Favorites...'}
            </Text>
          </View>
        )}
      </>
      {/* tabbar */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['tabbar'], {
            paddingLeft: { minWidth: Breakpoints.Tablet, value: '20%' },
            paddingRight: { minWidth: Breakpoints.Tablet, value: '20%' },
          }),
          dimensions.width
        )}
      >
        {/* Home */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'HomeScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.Home}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'Home'}
            </Text>
          </View>
        </Pressable>
        {/* My Posts */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'MyPostsScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyPosts}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'My Posts'}
            </Text>
            {/* Post_Badge */}
            <>
              {!Constants['matched_posts_count'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Post_Badge 2'],
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Custom #ffffff'],
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['matched_posts_count']}
                  </Text>
                </View>
              )}
            </>
          </View>
        </Pressable>
        {/* Create Post */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('CreatePostScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, marginTop: -58, width: '24%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                borderColor: theme.colors['Divider'],
                borderTopLeftRadius: 45,
                borderTopRightRadius: 45,
                borderTopWidth: 1,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            <Circle bgColor={theme.colors['Custom Color_25']} size={40}>
              <Icon
                color={theme.colors['Custom #ffffff']}
                name={'Entypo/plus'}
                size={24}
              />
            </Circle>

            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'], marginTop: 7 }
                ),
                dimensions.width
              )}
            >
              {'Create Post'}
            </Text>
          </View>
        </Pressable>
        {/* Favorites */}
        <Pressable
          activeOpacity={0.3}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.FavSelected}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary Blue'] }
                ),
                dimensions.width
              )}
            >
              {'Favorites'}
            </Text>
          </View>
        </Pressable>
        {/* My Profile */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'MyProfileScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyProfile}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'My Profile'}
            </Text>
          </View>
        </Pressable>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(FavouritesScreen);
