import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CustomCode from '../custom-files/CustomCode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Platform, Text, View } from 'react-native';

const LoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [email, setEmail] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [first_name, setFirst_name] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [last_name, setLast_name] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const inputValidation = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
    const passwordExpr = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!$#%]{8,}$/;

    let foundError = false;

    if (email.length < 1) {
      setError_msg('Email cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (!expr.test(email)) {
      setError_msg('Please enter a valid email');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (password.length < 1) {
      setError_msg('Password cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    return foundError;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Platform.OS === 'ios') {
        setGlobalVariableValue({
          key: 'deviceType',
          value: 'iOS',
        });
      }
      if (Platform.OS === 'android') {
        setGlobalVariableValue({
          key: 'deviceType',
          value: 'Android',
        });
      }
      if (Platform.OS === 'web') {
        setGlobalVariableValue({
          key: 'deviceType',
          value: 'Web',
        });
      }
      setError_msg('');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            height: 48,
            paddingLeft: 10,
            paddingRight: 10,
          }),
          dimensions.width
        )}
      >
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              {
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 17,
                marginLeft: 80,
                marginRight: 80,
              }
            ),
            dimensions.width
          )}
        >
          {'Log in'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: { minWidth: Breakpoints.Tablet, value: '15%' },
            paddingTop: 24,
          },
          dimensions.width
        )}
      >
        {/* Image */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 15,
            },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={Images.DirtNearMe}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                height: 166,
                width: 166,
              }),
              dimensions.width
            )}
          />
        </View>
        {/* Log In form */}
        <View
          style={StyleSheet.applyWidth(
            { paddingBottom: 8, paddingLeft: 24, paddingRight: 24 },
            dimensions.width
          )}
        >
          {/* Email */}
          <View>
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['detail_heading'],
                dimensions.width
              )}
            >
              {'Email*'}
            </Text>

            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                  {
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 44 },
                      { minWidth: Breakpoints.BigScreen, value: 75 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              <TextInput
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                onChangeText={newTextInputValue => {
                  try {
                    setEmail(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Input your email'}
                placeholderTextColor={theme.colors['Primary']}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                  dimensions.width
                )}
                value={email}
              />
            </View>
          </View>
          {/* Password */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
          >
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['detail_heading'],
                dimensions.width
              )}
            >
              {'Password*'}
            </Text>
            {/* password visible */}
            <>
              {!showPassword ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { height: 44 }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    onChangeText={newTextInputValue => {
                      try {
                        setPassword(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Input your password'}
                    placeholderTextColor={theme.colors['Primary']}
                    secureTextEntry={false}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                      dimensions.width
                    )}
                    value={password}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      { position: 'absolute', right: 10 },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShowPassword(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 48,
                            justifyContent: 'center',
                            width: 48,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Primary']}
                          name={'MaterialCommunityIcons/eye-outline'}
                          size={24}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              )}
            </>
            {/* password hidden */}
            <>
              {showPassword ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                      { height: 44 }
                    ),
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    onChangeText={newTextInputValue => {
                      try {
                        setPassword(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Input your password'}
                    placeholderTextColor={theme.colors['Primary']}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                      dimensions.width
                    )}
                    value={password}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      { position: 'absolute', right: 10 },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShowPassword(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 48,
                            justifyContent: 'center',
                            width: 48,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Primary']}
                          name={'MaterialCommunityIcons/eye-off-outline'}
                          size={24}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              )}
            </>
          </View>
          {/* Forgot Password */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 16,
                marginTop: 16,
              },
              dimensions.width
            )}
          >
            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('ForgotPasswordScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Text  */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                  }),
                  dimensions.width
                )}
              >
                {'Forgot Password?'}
              </Text>
            </Pressable>
          </View>
          {/* Error Msg */}
          <>
            {!error_msg ? null : (
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 6,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {error_msg}
              </Text>
            )}
          </>
          {/* Log in */}
          <Button
            loading={isLoading}
            onPress={() => {
              const handler = async () => {
                try {
                  const foundError = inputValidation();
                  if (foundError) {
                    return;
                  }
                  setError_msg('');
                  setIsLoading(true);
                  const result = (
                    await XanoApi.loginPOST(Constants, {
                      email: email,
                      password: password,
                    })
                  )?.json;
                  setIsLoading(false);
                  if (result?.authToken?.length) {
                    if (result?.user?.is_email_verified === true) {
                      setGlobalVariableValue({
                        key: 'AUTH_TOKEN',
                        value: 'Bearer ' + result?.authToken,
                      });
                      setGlobalVariableValue({
                        key: 'User_Details',
                        value: (() => {
                          const e = result?.user;
                          console.log(e);
                          return e;
                        })(),
                      });
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'HomeScreen',
                      });
                    } else {
                      showAlertUtil({
                        title: 'Verification Pending',
                        message:
                          'Please verify your account to continue using the app',
                        buttonText: 'Verify Now',
                      });

                      const rslt = (
                        await XanoApi.resendOTPGET(Constants, { email: email })
                      )?.json;
                      if (rslt) {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('SignupOTPScreen');
                      }
                    }
                  } else {
                    setError_msg(result?.message);
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Action Btn Tablet'],
                { marginBottom: 20, marginTop: 0 }
              ),
              dimensions.width
            )}
            title={'Log in'}
          />
          {/* Sign up */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Custom Color_27'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 22,
                }),
                dimensions.width
              )}
            >
              {'Don’t have an account?'}
            </Text>

            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('SignupScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Text 2 */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Custom Color_18'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                    lineHeight: 22,
                  }),
                  dimensions.width
                )}
              >
                {' Sign up'}
              </Text>
            </Pressable>
          </View>
        </View>
        {/* SocialLogins */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ViewStyles(theme)['SocialLogins'], {
              marginBottom: 20,
              marginLeft: 20,
              marginRight: 20,
            }),
            dimensions.width
          )}
        >
          {/* section header */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Divider */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Light'],
                  borderTopWidth: 1,
                  flex: 1,
                  height: 1,
                },
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  paddingLeft: 10,
                  paddingRight: 10,
                }),
                dimensions.width
              )}
            >
              {'Or Log In with'}
            </Text>
            {/* Divider */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Light'],
                  borderTopWidth: 1,
                  flex: 1,
                  height: 1,
                },
                dimensions.width
              )}
            />
          </View>
          {/* GoogleAuth */}
          <Utils.CustomCodeErrorBoundary>
            <AuthComponents.GoogleWrapper
              navigation={props.navigation}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setFirst_name={setFirst_name}
              setLast_name={setLast_name}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Primary Blue'],
                    borderRadius: 8,
                    borderWidth: 1,
                    flexDirection: 'row',
                    height: 44,
                    justifyContent: 'center',
                    marginBottom: 8,
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Primary Blue']}
                  name={'AntDesign/google'}
                  size={24}
                  style={StyleSheet.applyWidth(
                    { marginRight: 6 },
                    dimensions.width
                  )}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_400Regular',
                      lineHeight: 22,
                    }),
                    dimensions.width
                  )}
                >
                  {'Continue with Google'}
                </Text>
              </View>
            </AuthComponents.GoogleWrapper>
          </Utils.CustomCodeErrorBoundary>
          <>
            {Platform.OS === 'web' ? null : (
              <View>
                {/* AppleAuth */}
                <Utils.CustomCodeErrorBoundary>
                  <AuthComponents.AppleWrapper
                    navigation={props.navigation}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setErrorMessage={setError_msg}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderColor: theme.colors['Primary Blue'],
                          borderRadius: 8,
                          borderWidth: 1,
                          flexDirection: 'row',
                          height: 44,
                          justifyContent: 'center',
                          marginBottom: 8,
                          marginTop: 8,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary Blue']}
                        name={'AntDesign/apple1'}
                        size={24}
                        style={StyleSheet.applyWidth(
                          { marginRight: 6 },
                          dimensions.width
                        )}
                      />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_400Regular',
                              lineHeight: 22,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Continue with Apple'}
                      </Text>
                    </View>
                  </AuthComponents.AppleWrapper>
                </Utils.CustomCodeErrorBoundary>
              </View>
            )}
          </>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
