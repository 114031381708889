import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Get_labels_from_bools from '../global-functions/Get_labels_from_bools';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  DatePicker,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const EditPostDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Reactivate_Post, setReactivate_Post] = React.useState(false);
  const [action_sheet, setAction_sheet] = React.useState(false);
  const [avilibality_date, setAvilibality_date] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [descriptions, setDescriptions] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [fulfilled, setFulfilled] = React.useState(false);
  const [is_Deleting, setIs_Deleting] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [location, setLocation] = React.useState('');
  const [location_search_text, setLocation_search_text] = React.useState('');
  const [material, setMaterial] = React.useState('');
  const [material_Protector, setMaterial_Protector] = React.useState(false);
  const [material_Protector_actionSheet, setMaterial_Protector_actionSheet] =
    React.useState(false);
  const [material_types, setMaterial_types] = React.useState([]);
  const [need_proctor_actionSheet, setNeed_proctor_actionSheet] =
    React.useState(false);
  const [pin_icon, setPin_icon] = React.useState('');
  const [post_expiry, setPost_expiry] = React.useState('');
  const [post_lat, setPost_lat] = React.useState('');
  const [post_long, setPost_long] = React.useState('');
  const [post_type, setPost_type] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [price_Preference, setPrice_Preference] = React.useState('');
  const [price_Preference_actionSheet, setPrice_Preference_actionSheet] =
    React.useState(false);
  const [proctor, setProctor] = React.useState('');
  const [quantity, setQuantity] = React.useState(0);
  const [selected_material_id, setSelected_material_id] = React.useState(0);
  const [show_delete_confirmation_popup, setShow_delete_confirmation_popup] =
    React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_material_types_modal, setShow_material_types_modal] =
    React.useState(false);
  const [show_post_expiry_options, setShow_post_expiry_options] =
    React.useState(false);
  const [site_name, setSite_name] = React.useState('');
  const [trucking_Required, setTrucking_Required] = React.useState(false);
  const [trucking_actionSheet, setTrucking_actionSheet] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };

  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const inputValidation = () => {
    let foundError = false;

    if (avilibality_date.length < 1) {
      setError_msg('Please select the date of availability');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (post_expiry.length < 1) {
      setError_msg('Please select the Post validity');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    return foundError;
  };

  const convertToDays = (expiry, availability) => {
    if (Reactivate_Post) {
      return expiry;
    } else if (expiry > 0) {
      const diffInMs = new Date(expiry) - new Date(availability);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      console.log(diffInDays);
      return diffInDays;
    }
    return 0;
  };
  const postsUpdatePostPOST = PostsApi.useUpdatePostPOST();
  const postsDeleteAPostDELETE = PostsApi.useDeleteAPostDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setReactivate_Post(props.route?.params?.reactivate_Post ?? false);
        const api_Response = (
          await PostsApi.postDetailsGET(Constants, {
            posts_id: props.route?.params?.post_id ?? 25,
          })
        )?.json;
        const materials = (await XanoApi.materialTypesGET(Constants))?.json;
        setMaterial_types(materials);
        setMaterial(api_Response?._material_types?.name);
        setQuantity(api_Response?.quantity);
        setSite_name(api_Response?.site_name);
        setDescriptions(api_Response?.details);
        if (!(props.route?.params?.reactivate_Post ?? false)) {
          setAvilibality_date(
            changeDateFormat(
              api_Response?.availability_date,
              Constants['AppDateformat']
            )
          );
        }
        undefined;
        setMaterial_Protector(api_Response?.material_proctor);
        setTrucking_Required(api_Response?.is_trucking_required);
        setPrice_Preference(api_Response?.price_type);
        setPost_type(api_Response?.type);
        setLocation(api_Response?.location);
        if (!(props.route?.params?.reactivate_Post ?? false)) {
          setPost_expiry(api_Response?.expires_at);
        }
        if (api_Response?.type === 'Have') {
          setPin_icon(api_Response?._material_types?.have_icon?.url);
        }
        if (api_Response?.type === 'Need') {
          setPin_icon(api_Response?._material_types?.need_icon?.url);
        }
        setPost_lat(api_Response?.coordinates?.data?.lat);
        setPost_long(api_Response?.coordinates?.data?.lng);
        setPrice(api_Response?.price);
        setFulfilled(api_Response?.fulfilled);
        setSelected_material_id(api_Response?.material_types_id);
        setPrice(api_Response?.price);
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <>
        {is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
                borderBottomWidth: 1,
                borderColor: theme.colors['Divider'],
              }),
              dimensions.width
            )}
          >
            {/* Cancel */}
            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 48,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 15,
                    }),
                    dimensions.width
                  )}
                >
                  {'Cancel'}
                </Text>
              </View>
            </Pressable>
            {/* screen heading */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                dimensions.width
              )}
            >
              {post_type} {addCommasToNumber(quantity)}
              {' CYD - '}
              {material}
            </Text>
            {/* Save */}
            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                const handler = async () => {
                  try {
                    const foundError = (() => {
                      if (props.route?.params?.reactivate_Post ?? false) {
                        return inputValidation();
                      }
                    })();
                    if (foundError) {
                      return;
                    }
                    setIs_Loading(true);
                    const api_Response = (
                      await postsUpdatePostPOST.mutateAsync({
                        ability_to_load_material: true,
                        availability_date: avilibality_date,
                        creator: Constants['User_Details']?.id,
                        details: descriptions,
                        expires_in: parseInt(
                          convertToDays(post_expiry, avilibality_date),
                          10
                        ),
                        fulfilled: fulfilled,
                        is_material_protection_required: material_Protector,
                        is_trucking_required: trucking_Required,
                        lat: post_lat,
                        lng: post_long,
                        location: location,
                        material_types_id: selected_material_id,
                        posts_id: props.route?.params?.post_id ?? 25,
                        price: price,
                        price_type: price_Preference,
                        quantity: quantity,
                        site_name: site_name,
                      })
                    )?.json;
                    setIs_Loading(false);
                    if (api_Response?.status === 'Success!') {
                      showAlertUtil({
                        title: 'Updated',
                        message: 'Changes are updated successfully',
                        buttonText: 'Ok',
                      });
                    }
                    if (api_Response?.status === 'Success!') {
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 48,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <>
                  {!is_Loading ? null : (
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ],
                        dimensions.width
                      )}
                    />
                  )}
                </>
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 15,
                    }),
                    dimensions.width
                  )}
                >
                  {'Save'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      <>
        {is_Loading ? null : (
          <KeyboardAwareScrollView
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
          >
            <ScrollView
              bounces={true}
              contentContainerStyle={StyleSheet.applyWidth(
                { padding: 16, paddingBottom: 25 },
                dimensions.width
              )}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Map View */}
              <View
                style={StyleSheet.applyWidth({ height: 120 }, dimensions.width)}
              >
                <MapView
                  apiKey={'AIzaSyCqfSy6WqO1ho-saMDNPlLHLgNf9QaOW0g'}
                  latitude={post_lat}
                  loadingEnabled={true}
                  longitude={post_long}
                  provider={'google'}
                  rotateEnabled={true}
                  scrollEnabled={true}
                  showsPointsOfInterest={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.MapViewStyles(theme)['Map View'],
                    dimensions.width
                  )}
                  zoom={10}
                  zoomEnabled={true}
                >
                  <MapMarker
                    latitude={post_lat}
                    longitude={post_long}
                    pinImage={`${pin_icon}`}
                    pinImageSize={80}
                    title={location}
                  />
                </MapView>
              </View>
              {/* Material type */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Material Type:'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_material_types_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderRadius: 8,
                        height: 44,
                        justifyContent: 'center',
                        marginTop: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Placehoolder */}
                    <>
                      {material ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Material Type'}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!material ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 16,
                                textTransform: 'capitalize',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {material}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* Quantity */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Quantity:'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Divider'],
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 44,
                      justifyContent: 'flex-start',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'none'}
                    onChangeText={newTextInputValue => {
                      try {
                        setQuantity(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Quantity'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          paddingLeft: 15,
                          width: 75,
                        }
                      ),
                      dimensions.width
                    )}
                    value={addCommasToNumber(quantity)}
                  />
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'], marginTop: 0 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'CYD'}
                  </Text>
                </View>
              </View>
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Location:'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setShow_location_modal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderRadius: 8,
                        height: 44,
                        justifyContent: 'center',
                        marginTop: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Placehoolder */}
                    <>
                      {location ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Light'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Location'}
                        </Text>
                      )}
                    </>
                    {/* Value */}
                    <>
                      {!location ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 16,
                                textTransform: 'capitalize',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {location}
                        </Text>
                      )}
                    </>
                  </View>
                </Pressable>
              </View>
              {/* Job site name */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Job Site Name:'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Divider'],
                      borderRadius: 8,
                      height: 44,
                      justifyContent: 'center',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    autoCapitalize={'words'}
                    onChangeText={newTextInputValue => {
                      try {
                        setSite_name(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Site name'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          paddingLeft: 15,
                          textTransform: 'capitalize',
                        }
                      ),
                      dimensions.width
                    )}
                    value={site_name}
                  />
                </View>
              </View>
              {/* Description */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 130, marginBottom: 20, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Description'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Divider'],
                      borderRadius: 8,
                      justifyContent: 'center',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <TextInput
                    multiline={true}
                    numberOfLines={4}
                    onChangeText={newTextAreaValue => {
                      const textInputValue = newTextAreaValue;
                      try {
                        setDescriptions(newTextAreaValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Post description'}
                    placeholderTextColor={theme.colors['Light']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Area'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          minHeight: 100,
                          paddingLeft: 15,
                        }
                      ),
                      dimensions.width
                    )}
                    textAlignVertical={'top'}
                    value={descriptions}
                  />
                </View>
              </View>
              {/* Available from */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 90, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {props.route?.params?.reactivate_Post ?? false ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Available From:'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(props.route?.params?.reactivate_Post ?? false) ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Available From:'}
                    </Text>
                  )}
                </>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Divider'],
                      borderRadius: 8,
                      height: 44,
                      justifyContent: 'center',
                      marginTop: 6,
                      overflow: 'hidden',
                    },
                    dimensions.width
                  )}
                >
                  {/* Value */}
                  <>
                    {props.route?.params?.reactivate_Post ?? false ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Label Values'],
                            {
                              color: theme.colors['Medium'],
                              marginTop: 0,
                              paddingLeft: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {avilibality_date}
                      </Text>
                    )}
                  </>
                  <>
                    {!(props.route?.params?.reactivate_Post ?? false) ? null : (
                      <DatePicker
                        autoDismissKeyboard={true}
                        borderColor={'"rgba(0, 0, 0, 0)"'}
                        date={avilibality_date}
                        label={'Date'}
                        leftIconMode={'inset'}
                        mode={'date'}
                        onDateChange={newDatePickerValue => {
                          try {
                            setAvilibality_date(newDatePickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          { fontFamily: 'Poppins_400Regular', marginLeft: 4 },
                          dimensions.width
                        )}
                        type={'underline'}
                      />
                    )}
                  </>
                </View>
              </View>
              {/* Available until */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 90, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {props.route?.params?.reactivate_Post ?? false ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Available Until:'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(props.route?.params?.reactivate_Post ?? false) ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { color: theme.colors['Error'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Available Until: (In Days)'}
                    </Text>
                  )}
                </>
                <>
                  {props.route?.params?.reactivate_Post ?? false ? null : (
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_post_expiry_options(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Divider'],
                            borderRadius: 8,
                            height: 44,
                            justifyContent: 'center',
                            marginTop: 6,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Value */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                color: theme.colors['Medium'],
                                marginTop: 0,
                                paddingLeft: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {changeDateFormat(
                            post_expiry,
                            Constants['AppDateformat']
                          )}
                        </Text>
                      </View>
                    </Pressable>
                  )}
                </>
                <>
                  {!(props.route?.params?.reactivate_Post ?? false) ? null : (
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_post_expiry_options(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Divider'],
                            borderRadius: 8,
                            height: 44,
                            justifyContent: 'center',
                            marginTop: 6,
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Value */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Label Values'],
                              {
                                color: theme.colors['Medium'],
                                marginTop: 0,
                                paddingLeft: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {post_expiry}
                        </Text>
                      </View>
                    </Pressable>
                  )}
                </>
              </View>
              {/* Price Preference */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Price Preference:'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setPrice_Preference_actionSheet(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderRadius: 8,
                        height: 44,
                        justifyContent: 'center',
                        marginTop: 6,
                        overflow: 'hidden',
                        paddingLeft: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'], marginTop: 0 }
                        ),
                        dimensions.width
                      )}
                    >
                      {price_Preference}
                    </Text>
                  </View>
                </Pressable>
              </View>
              {/* Price */}
              <>
                {!(price_Preference !== 'Free') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 75, marginTop: 12 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Price:'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 8,
                          height: 44,
                          justifyContent: 'center',
                          marginTop: 6,
                        },
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'none'}
                        keyboardType={'numeric'}
                        onChangeText={newTextInputValue => {
                          try {
                            setPrice(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Price'}
                        placeholderTextColor={theme.colors['Light']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['Text Input'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 16,
                              paddingLeft: 15,
                              textTransform: 'capitalize',
                            }
                          ),
                          dimensions.width
                        )}
                        value={price}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Trucking/ Hauling required? */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['detail_heading'],
                    dimensions.width
                  )}
                >
                  {'Trucking/ Hauling required:'}
                </Text>

                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setTrucking_actionSheet(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderRadius: 8,
                        height: 44,
                        justifyContent: 'center',
                        marginTop: 6,
                        overflow: 'hidden',
                        paddingLeft: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'], marginTop: 0 }
                        ),
                        dimensions.width
                      )}
                    >
                      {Get_labels_from_bools(trucking_Required)}
                    </Text>
                  </View>
                </Pressable>
              </View>
              {/* Material Proctor required? */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 75, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {!(post_type === 'Need') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Material Proctor Required:'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(post_type === 'Have') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Proctor Available:'}
                    </Text>
                  )}
                </>
                <Pressable
                  activeOpacity={0.3}
                  onPress={() => {
                    try {
                      setMaterial_Protector_actionSheet(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        borderRadius: 8,
                        height: 44,
                        justifyContent: 'center',
                        marginTop: 6,
                        overflow: 'hidden',
                        paddingLeft: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'], marginTop: 0 }
                        ),
                        dimensions.width
                      )}
                    >
                      {Get_labels_from_bools(material_Protector)}
                    </Text>
                  </View>
                </Pressable>
              </View>
            </ScrollView>
          </KeyboardAwareScrollView>
        )}
      </>
      {/* CTAs */}
      <>
        {is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'stretch' },
                  { minWidth: Breakpoints.Desktop, value: 'center' },
                ],
                borderColor: theme.colors['Divider'],
                borderTopWidth: 2,
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: 16,
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Error'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginBottom: 15,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {error_msg}
            </Text>
            {/* Delete post */}
            <Button
              loading={is_Deleting}
              onPress={() => {
                try {
                  setShow_delete_confirmation_popup(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                  {
                    backgroundColor: theme.colors['Custom Color_5'],
                    fontSize: 17,
                    width: { minWidth: Breakpoints.Desktop, value: '70%' },
                  }
                ),
                dimensions.width
              )}
              title={'Delete post'}
            />
          </View>
        )}
      </>
      {/* Delete Confirmation Action Sheet */}
      <ActionSheet visible={show_delete_confirmation_popup}>
        {/* DELETE */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'DELETE'}
          onPress={() => {
            const handler = async () => {
              try {
                setShow_delete_confirmation_popup(false);
                setIs_Deleting(true);
                const api_Response = (
                  await postsDeleteAPostDELETE.mutateAsync({
                    posts_id: props.route?.params?.post_id ?? 25,
                  })
                )?.json;
                setIs_Deleting(false);
                if (api_Response?.status === 'Success!') {
                  showAlertUtil({
                    title: 'Deleted',
                    message: 'Post has been deleted successfully.',
                    buttonText: 'Ok',
                  });
                }
                if (api_Response?.status === 'Success!') {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyPostsScreen',
                  });
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Cancel */}
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setShow_delete_confirmation_popup(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Price Preference: */}
      <ActionSheet visible={price_Preference_actionSheet}>
        {/* Fixed */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Fixed'}
          onPress={() => {
            try {
              setPrice_Preference('Fixed');
              setPrice_Preference_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Negotiable */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Negotiable'}
          onPress={() => {
            try {
              setPrice_Preference('Negotiable');
              setPrice_Preference_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Free */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Free'}
          onPress={() => {
            try {
              setPrice_Preference('Free');
              setPrice_Preference_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
      </ActionSheet>
      {/* Proctor? */}
      <ActionSheet visible={need_proctor_actionSheet}>
        {/* YES */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'YES'}
          onPress={() => {
            try {
              setProctor('YES');
              setNeed_proctor_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* NO */}
        <ActionSheetCancel
          label={'NO'}
          onPress={() => {
            try {
              setProctor('NO');
              setNeed_proctor_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Trucking/ Hauling required? */}
      <ActionSheet visible={trucking_actionSheet}>
        {/* YES */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'YES'}
          onPress={() => {
            try {
              setTrucking_Required(true);
              setTrucking_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* NO */}
        <ActionSheetCancel
          label={'NO'}
          onPress={() => {
            try {
              setTrucking_Required(false);
              setTrucking_actionSheet(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Material Types */}
      <>
        {!show_material_types_modal ? null : (
          <Modal animationType={'fade'} transparent={true}>
            {/* Black BG View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_18'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.5,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            {/* List */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { height: 400, width: 250 },
                  dimensions.width
                )}
              >
                <RadioButtonGroup
                  onValueChange={newRadioButtonGroupValue => {
                    try {
                      setMaterial(newRadioButtonGroupValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={material}
                >
                  <FlatList
                    contentContainerStyle={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderRadius: 8,
                        justifyContent: 'center',
                        overflow: 'hidden',
                      },
                      dimensions.width
                    )}
                    data={(() => {
                      const e = material_types;
                      console.log(e);
                      return e;
                    })()}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'5z8wu7Da'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setMaterial(listData?.label);
                              setShow_material_types_modal(false);
                              setSelected_material_id(listData?.value);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Primary Yellow'],
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: 12,
                                paddingLeft: 30,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    flex: 1,
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 18,
                                    marginLeft: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.label}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </RadioButtonGroup>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Post Expiry Modal */}
      <Modal
        animationType={'fade'}
        transparent={true}
        visible={show_post_expiry_options}
      >
        {/* Black BG View */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_18'],
              bottom: 0,
              left: 0,
              opacity: 0.5,
              position: 'absolute',
              right: 0,
              top: 0,
            },
            dimensions.width
          )}
        />
        {/*  List */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', flex: 1, justifyContent: 'center' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth({ height: 250 }, dimensions.width)}
          >
            <RadioButtonGroup
              onValueChange={newRadioButtonGroupValue => {
                try {
                  setPost_expiry(newRadioButtonGroupValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              value={post_expiry}
            >
              <FlatList
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderRadius: 8,
                    justifyContent: 'center',
                    overflow: 'hidden',
                  },
                  dimensions.width
                )}
                data={['30', '90', '120', 'Never']}
                keyExtractor={(listData, index) =>
                  listData?.id ?? listData?.uuid ?? index.toString()
                }
                listKey={'JsUsbiVx'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setPost_expiry(listData);
                          setShow_post_expiry_options(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Primary Yellow'],
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 12,
                            paddingLeft: 30,
                            width: 200,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                flex: 1,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 18,
                                marginLeft: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData}
                        </Text>
                      </View>
                    </Pressable>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            </RadioButtonGroup>
          </View>
        </View>
      </Modal>
      {/* Location Modal */}
      <>
        {!show_location_modal ? null : (
          <Modal animationType={'none'}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Divider'],
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 12,
                  marginRight: 12,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 50,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'Change Location'}
              </Text>

              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_location_modal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                          marginRight: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Close'}
                  </Text>
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'Ionicons/md-close'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>

            <View
              style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
            >
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={100}
                onChangeText={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Search a location'}
                placeholderTextColor={theme.colors['Light']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'],
                    {
                      borderColor: theme.colors['Divider'],
                      borderWidth: 1,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginBottom: 15,
                      paddingLeft: 16,
                    }
                  ),
                  dimensions.width
                )}
                value={location_search_text}
              />
              <XanoGooglePlacesApi.FetchSearchGET search={location_search_text}>
                {({ loading, error, data, refetchSearch }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <FlatList
                      data={fetchData?.predictions}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'4S9VugoP'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const coords = (
                                    await XanoGooglePlacesApi.getCoordinatesGET(
                                      Constants,
                                      { place_id: listData?.place_id }
                                    )
                                  )?.json;
                                  console.log(coords);
                                  setLocation(listData?.description);
                                  setShow_location_modal(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  paddingBottom: 10,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Entypo/location-pin'}
                                size={24}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.description}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XanoGooglePlacesApi.FetchSearchGET>
            </View>
          </Modal>
        )}
      </>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(EditPostDetailsScreen);
