/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: Platform.OS === 'web' ? false : true,
  prefixes: [prefix],
  config: {
    screens: {
      MyPostsDetailsActiveScreen: {
        screens: {
          MyPostsDetailsActiveScreen: {
            path: 'MyPostsDetailsActiveScreen/:post_id?',
          },
        },
      },

      MyPostsDetailsExpiredScreen: {
        screens: {
          MyPostsDetailsExpiredScreen: {
            path: 'MyPostsDetailsExpiredScreen/:post_id?',
          },
        },
      },

      MyPostsDetailsFulfilledScreen: {
        screens: {
          MyPostsDetailsFulfilledScreen: {
            path: 'MyPostsDetailsFulfilledScreen/:post_id?',
          },
        },
      },

      PostDetailsScreen: {
        screens: {
          PostDetailsScreen: {
            path: 'PostDetailsScreen/:post_id?',
          },
        },
      },

      SocialCompleteProfileScreen: {
        screens: {
          SocialCompleteProfileScreen: {
            path: 'SocialCompleteProfileScreen',
          },
        },
      },

      BottomTabNavigator: { screens: {} },
    },
  },
};

export default linking;
