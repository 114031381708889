import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Get_labels_from_bools from '../global-functions/Get_labels_from_bools';
import generateBranchIOLink from '../global-functions/generateBranchIOLink';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

const MyPostsDetailsExpiredScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [delete_confirmation, setDelete_confirmation] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [pin_icon, setPin_icon] = React.useState('');
  const [post_Details, setPost_Details] = React.useState({});
  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const postsDeleteAPostDELETE = PostsApi.useDeleteAPostDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await PostsApi.postDetailsGET(Constants, {
            posts_id: props.route?.params?.post_id ?? '',
          })
        )?.json;
        setPost_Details(result);
        if (result?.type === 'Have') {
          setPin_icon(result?._material_types?.have_icon?.url);
        }
        if (result?.type === 'Need') {
          setPin_icon(result?._material_types?.need_icon?.url);
        }
        setIs_Loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <>
          {!post_Details?.type ? null : (
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                  { marginRight: 70 }
                ),
                dimensions.width
              )}
            >
              {post_Details?.type} {addCommasToNumber(post_Details?.quantity)}
              {' CYD - '}
              {post_Details?._material_types?.name}
            </Text>
          )}
        </>
      </View>
      {/* Details */}
      <>
        {is_Loading ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            <ScrollView
              bounces={true}
              contentContainerStyle={StyleSheet.applyWidth(
                { padding: 16, paddingTop: 0 },
                dimensions.width
              )}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Map View */}
              <View
                style={StyleSheet.applyWidth({ height: 150 }, dimensions.width)}
              >
                <MapView
                  apiKey={'AIzaSyCqfSy6WqO1ho-saMDNPlLHLgNf9QaOW0g'}
                  latitude={post_Details?.coordinates?.data?.lat}
                  loadingEnabled={true}
                  longitude={post_Details?.coordinates?.data?.lng}
                  provider={'google'}
                  rotateEnabled={true}
                  scrollEnabled={true}
                  showsPointsOfInterest={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.MapViewStyles(theme)['Map View'],
                    dimensions.width
                  )}
                  zoomEnabled={true}
                >
                  <MapMarker
                    description={post_Details?.details}
                    latitude={post_Details?.coordinates?.data?.lat}
                    longitude={post_Details?.coordinates?.data?.lng}
                    onPress={(latitude, longitude) => {
                      try {
                        console.log(pin_icon);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    pinImage={`${pin_icon}`}
                    pinImageSize={80}
                    title={post_Details?.site_name}
                  />
                </MapView>
              </View>
              {/* Material type */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    height: 64,
                    justifyContent: 'space-between',
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                <View>
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Material Type:'}
                  </Text>
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {post_Details?._material_types?.name}
                  </Text>
                </View>
                {/* share view */}
                <>
                  {Platform.OS === 'web' ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 48,
                          justifyContent: 'center',
                          width: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const link = await generateBranchIOLink(
                                post_Details,
                                props.route?.params?.post_id ?? '',
                                'Expired'
                              );
                              await openShareUtil(`${link}`);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Primary Blue']}
                            name={'MaterialCommunityIcons/share'}
                            size={24}
                          />
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Share'}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
              </View>
              {/* Quantity  */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Quantity:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {addCommasToNumber(post_Details?.quantity)}
                  {' CYD'}
                </Text>
              </View>
              {/* Location */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 8, minHeight: 64 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Location:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'capitalize',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {post_Details?.location}
                </Text>
              </View>
              {/* Job site name */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 12, minHeight: 64 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Job Site Name:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'capitalize',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {post_Details?.site_name}
                </Text>
              </View>
              {/* Description */}
              <>
                {!post_Details?.details ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 12, minHeight: 64 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Description:'}
                    </Text>
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          {
                            color: theme.colors['Medium'],
                            textTransform: 'capitalize',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {post_Details?.details}
                    </Text>
                  </View>
                )}
              </>
              {/* Available from */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 12 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Available From:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {changeDateFormat(
                    post_Details?.availability_date,
                    Constants['AppDateformat']
                  )}
                </Text>
              </View>
              {/* Available until */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Available Until:'}
                </Text>
                {/* Value */}
                <>
                  {!post_Details?.expires_at ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {changeDateFormat(
                        post_Details?.expires_at,
                        Constants['AppDateformat']
                      )}
                    </Text>
                  )}
                </>
                {/* Value */}
                <>
                  {post_Details?.expires_at ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Always'}
                    </Text>
                  )}
                </>
              </View>
              {/* Price Preference */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Price Preference:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      { color: theme.colors['Medium'] }
                    ),
                    dimensions.width
                  )}
                >
                  {post_Details?.price_type}
                </Text>
              </View>
              {/* Price */}
              <>
                {!(post_Details?.price_type === 'Fixed') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 64, marginTop: 8 },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Price:'}
                    </Text>
                    {/* Value */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {post_Details?.price}
                    </Text>
                  </View>
                )}
              </>
              {/* Proctor?  */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <>
                  {!(post_Details?.type === 'Need') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Material Proctor Required:'}
                    </Text>
                  )}
                </>
                {/* Label */}
                <>
                  {!(post_Details?.type === 'Have') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['detail_heading'],
                          { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Proctor Available:'}
                    </Text>
                  )}
                </>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(post_Details?.material_proctor)}
                </Text>
              </View>
              {/* Trucking/ Hauling required? */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Trucking/ Hauling required:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(post_Details?.is_trucking_required)}
                </Text>
              </View>
              {/* Load Materials */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 64, marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      { fontFamily: 'Poppins_500Medium', fontSize: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Load Materials:'}
                </Text>
                {/* Value */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Label Values'],
                      {
                        color: theme.colors['Medium'],
                        textTransform: 'uppercase',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Get_labels_from_bools(
                    post_Details?.ability_to_load_material
                  )}
                </Text>
              </View>
              {/* Attachment */}
              <>
                {!post_Details?._post_attachments ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['Attachment 2'],
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('ImageScreen', {
                            URL: post_Details?._post_attachments?.file?.url,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            height: 64,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Custom Color_33'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 17,
                                textDecorationColor:
                                  theme.colors['Custom Color_33'],
                                textDecorationLine: 'underline',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'View Attachment'}
                        </Text>
                        <Icon
                          color={theme.colors['Primary Blue']}
                          name={'Entypo/attachment'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 12 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Pressable>
                  </View>
                )}
              </>
            </ScrollView>
            {/* CTAs */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'stretch',
                  borderColor: theme.colors['Divider'],
                  borderTopWidth: 2,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  padding: 16,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Reactivate Post */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('EditPostDetailsScreen', {
                      post_id: props.route?.params?.post_id ?? '',
                      reactivate_Post: true,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                    { fontFamily: 'Poppins_500Medium', fontSize: 17 }
                  ),
                  dimensions.width
                )}
                title={'Reactivate Post'}
              />
              {/* Delete post */}
              <Button
                onPress={() => {
                  try {
                    setDelete_confirmation(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
                    {
                      backgroundColor: theme.colors['Custom Color_26'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 17,
                      marginTop: 8,
                    }
                  ),
                  dimensions.width
                )}
                title={'Delete post'}
              />
            </View>
          </View>
        )}
      </>
      <ActionSheet visible={delete_confirmation}>
        {/* Delete */}
        <ActionSheetItem
          color={theme.colors.strong}
          label={'Delete'}
          onPress={() => {
            const handler = async () => {
              try {
                setDelete_confirmation(false);
                const api_Response = (
                  await postsDeleteAPostDELETE.mutateAsync({
                    posts_id: props.route?.params?.post_id ?? '',
                  })
                )?.json;
                /* 'Conditional Stop' action requires configuration: select Check Value */
                if (api_Response?.status === 'Success!') {
                  showAlertUtil({
                    title: 'Deleted',
                    message: 'Post has been deleted successfuly',
                    buttonText: 'Ok',
                  });
                }
                if (api_Response?.status === 'Success!') {
                  navigation.goBack();
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item'],
            dimensions.width
          )}
        />
        {/* Cancel */}
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setDelete_confirmation(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching the post details...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(MyPostsDetailsExpiredScreen);
