import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { ActivityIndicator, Image, ScrollView, Text, View } from 'react-native';

const UserProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [err_msg, setErr_msg] = React.useState('');
  const [isBlocking, setIsBlocking] = React.useState(false);
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [myProfileId, setMyProfileId] = React.useState('');
  const [showBlockUserConfirmation, setShowBlockUserConfirmation] =
    React.useState(false);
  const [user_details, setUser_details] = React.useState({});
  const xanoFavoriteUnfavoriteATruckerPOST =
    XanoApi.useFavoriteUnfavoriteATruckerPOST();
  const xanoBlockAUserPOST = XanoApi.useBlockAUserPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await XanoApi.userProfileGET(Constants, {
            profile_id: props.route?.params?.profile_id ?? 197,
          })
        )?.json;
        setUser_details(
          (() => {
            const e = result;
            console.log(e);
            return e;
          })()
        );
        setIs_Loading(false);
        setMyProfileId(Constants['User_Details']?._profile_of_user?.id);
        setErr_msg(result?.message);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
            marginRight: 8,
          }),
          dimensions.width
        )}
      >
        {/* back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          numberOfLines={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              {
                marginLeft: 22,
                paddingLeft: 10,
                paddingRight: 10,
                textTransform: 'capitalize',
              }
            ),
            dimensions.width
          )}
        >
          {user_details?.profile_name}
        </Text>
        {/* Favorite */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['Nav Btn Phone'],
            dimensions.width
          )}
        >
          <>
            {!(user_details?._user_types?.name === 'Trucking') ? null : (
              <Checkbox
                checkedIcon={'AntDesign/heart'}
                color={theme.colors['Custom Color_18']}
                defaultValue={user_details?._is_favorite_of_user}
                onCheck={() => {
                  const handler = async () => {
                    const checkboxValue = undefined;
                    try {
                      const api_Response = (
                        await xanoFavoriteUnfavoriteATruckerPOST.mutateAsync({
                          trucker_id: user_details?.id,
                        })
                      )?.json;
                      const api_Response2 = showAlertUtil({
                        title: JSON.stringify(api_Response?.status),
                        message: 'Trucker has been added to your favorites.',
                        buttonText: 'OK',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                onUncheck={() => {
                  const handler = async () => {
                    const checkboxValue = undefined;
                    try {
                      const api_Response3 = (
                        await xanoFavoriteUnfavoriteATruckerPOST.mutateAsync({
                          trucker_id: user_details?.id,
                        })
                      )?.json;

                      showAlertUtil({
                        title: JSON.stringify(api_Response3?.status),
                        message: 'Trucker removed from your favorites',
                        buttonText: 'OK',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                uncheckedColor={theme.colors['Custom Color_18']}
                uncheckedIcon={'AntDesign/hearto'}
              />
            )}
          </>
        </View>
        {/* Report User */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              setShowBlockUserConfirmation(true);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'MaterialCommunityIcons/alert-octagon-outline'}
              size={28}
            />
          </View>
        </Pressable>
      </View>
      <>
        {!!is_Loading ? null : (
          <ScrollView
            bounces={true}
            contentContainerStyle={StyleSheet.applyWidth(
              {
                padding: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Tablet, value: '15%' },
                ],
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 0 },
                paddingTop: [
                  { minWidth: Breakpoints.Tablet, value: '10%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
              },
              dimensions.width
            )}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            {/* Image */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                },
                dimensions.width
              )}
            >
              <>
                {!user_details?.profile_image ? null : (
                  <Image
                    resizeMode={'cover'}
                    source={{ uri: `${user_details?.profile_image?.url}` }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { borderRadius: 8, height: 200, width: 200 }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              <>
                {user_details?.profile_image ? null : (
                  <Image
                    resizeMode={'cover'}
                    source={{ uri: `${user_details?._user_types?.icon_path}` }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { borderRadius: 8, height: 200, width: 200 }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
            {/* Business Classification */}
            <>
              {!user_details?.type_of_business ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { height: 64, marginTop: 8 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      dimensions.width
                    )}
                  >
                    {'Business Classification'}
                  </Text>
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {user_details?.type_of_business}
                  </Text>
                </View>
              )}
            </>
            {/* Profile Type */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Profile Type'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {user_details?._user_types?.name}
              </Text>
            </View>
            {/* Location */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Location'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {user_details?.location}
              </Text>
            </View>
            {/* Phone Number */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Phone Number'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {'+1'}
                {user_details?.phone_number}
              </Text>
            </View>
            {/* Email Address */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Email Address'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {user_details?.email}
              </Text>
            </View>
            {/* Contact Preferences */}
            <View
              style={StyleSheet.applyWidth(
                {
                  height: 84,
                  marginBottom: 20,
                  marginTop: 20,
                  paddingBottom: 15,
                },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Contact Preferences'}
              </Text>
              {/* Call */}
              <>
                {!user_details?._permissions_of_user?.privacy_call ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {'• Call'}
                  </Text>
                )}
              </>
              {/* Chat */}
              <>
                {!user_details?._permissions_of_user
                  ?.privacy_messenger ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'], marginTop: 4 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'• Chat'}
                  </Text>
                )}
              </>
              {/* Email */}
              <>
                {!user_details?._permissions_of_user?.privacy_mail ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'], marginTop: 4 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'• Email'}
                  </Text>
                )}
              </>
            </View>
          </ScrollView>
        )}
      </>
      {/* CTAs */}
      <>
        {!(user_details?.id !== myProfileId) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderColor: theme.colors['Divider'],
                borderTopWidth: 2,
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Desktop, value: '15%' },
                  { minWidth: Breakpoints.Laptop, value: '15%' },
                ],
                paddingBottom: [
                  { minWidth: Breakpoints.Desktop, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 16 },
                ],
                paddingTop: [
                  { minWidth: Breakpoints.Desktop, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 16 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Call */}
            <>
              {!user_details?._permissions_of_user?.privacy_call ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, paddingRight: 6 },
                    dimensions.width
                  )}
                >
                  {/* Call */}
                  <Button
                    icon={'Ionicons/md-call-outline'}
                    onPress={() => {
                      try {
                        Linking.openURL(`tel: ${user_details?.phone_number}`);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Action Btn Phone'],
                        { fontSize: 17 }
                      ),
                      dimensions.width
                    )}
                    title={'  Call'}
                  />
                </View>
              )}
            </>
            {/* Chat */}
            <>
              {!(user_details?.user_id !== 32) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* Chat */}
                  <>
                    {!user_details?._permissions_of_user
                      ?.privacy_messenger ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1, paddingLeft: 6, paddingRight: 6 },
                          dimensions.width
                        )}
                      >
                        {/* Chat */}
                        <Button
                          icon={'Ionicons/md-chatbubble-outline'}
                          onPress={() => {
                            try {
                              navigation.navigate('ChatScreen', {
                                profile_pic: user_details?.profile_image?.url,
                                profile_id: user_details?.id,
                                Other_user_name: user_details?.profile_name,
                                receiver_id: user_details?.user_id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)[
                                'Action Btn Phone'
                              ],
                              { fontSize: 17 }
                            ),
                            dimensions.width
                          )}
                          title={'  Chat'}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
            {/* Email */}
            <>
              {!(user_details?.user_id !== 32) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* Email */}
                  <>
                    {!user_details?._permissions_of_user
                      ?.privacy_mail ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1, paddingLeft: 6 },
                          dimensions.width
                        )}
                      >
                        {/* Email */}
                        <Button
                          icon={'MaterialCommunityIcons/email-outline'}
                          onPress={() => {
                            try {
                              Linking.openURL(`mailto: ${user_details?.email}`);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)[
                                'Action Btn Phone'
                              ],
                              { fontSize: 17 }
                            ),
                            dimensions.width
                          )}
                          title={'  Email'}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ],
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please Wait\nFetching the user details...'}
            </Text>
          </View>
        )}
      </>
      {/* Error View */}
      <>
        {!err_msg?.length ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                bottom: 0,
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 50,
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Some error occurred. Please try again later'}
            </Text>
          </View>
        )}
      </>
      {/* Block User Confirmation */}
      <>
        {!showBlockUserConfirmation ? null : (
          <View
            style={StyleSheet.applyWidth(
              { bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 },
              dimensions.width
            )}
          >
            {/* BG */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.3,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            {/* Pop up View */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Pop up */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderColor: theme.colors['Divider'],
                    borderRadius: 10,
                    borderWidth: 1,
                    marginLeft: 30,
                    marginRight: 30,
                    paddingTop: 18,
                    width: '80%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 17,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Report and Block!'}
                </Text>

                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary Blue'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 17,
                      marginTop: 10,
                      paddingLeft: 15,
                      paddingRight: 15,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Are you sure to report and block the user?'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Divider'],
                      borderTopWidth: 2,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* Cancel, */}
                  <Button
                    onPress={() => {
                      try {
                        setShowBlockUserConfirmation(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderColor: theme.colors['Divider'],
                          borderRightWidth: 1,
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          height: 50,
                          width: '50%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                  {/* Block */}
                  <Button
                    loading={isBlocking}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setIsBlocking(true);
                          const api_Response = (
                            await xanoBlockAUserPOST.mutateAsync({
                              user_id: user_details?.user_id,
                            })
                          )?.json;
                          setIsBlocking(false);
                          setShowBlockUserConfirmation(false);
                          console.log(api_Response);
                          if (api_Response?.status === 'Success!') {
                            navigation.navigate('BottomTabNavigator', {
                              screen: 'HomeScreen',
                            });
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderColor: theme.colors['Divider'],
                          borderLeftWidth: 1,
                          borderRadius: 0,
                          color: theme.colors['Error'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 17,
                          height: 50,
                          width: '50%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Block'}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(UserProfileScreen);
