import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ChatApi from '../apis/ChatApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import relativeTime from '../global-functions/relativeTime';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ChatScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ChatRoom_ID, setChatRoom_ID] = React.useState(0);
  const [callEndpoint, setCallEndpoint] = React.useState(false);
  const [chat_data, setChat_data] = React.useState([]);
  const [is_Loading, setIs_Loading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [my_id, setMy_id] = React.useState(0);
  const [post, setPost] = React.useState({});
  const [profileID_forFetch, setProfileID_forFetch] = React.useState(0);
  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const chatSendAMessagePOST = ChatApi.useSendAMessagePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setCallEndpoint(false);
      setMy_id(Constants['User_Details']?.id);
      if ((props.route?.params?.chatroom_id ?? '')?.length) {
        setChatRoom_ID(props.route?.params?.chatroom_id ?? '');
      }
      if ((props.route?.params?.post_id ?? '')?.length) {
        setProfileID_forFetch('');
      }
      if (!(props.route?.params?.post_id ?? '')?.length) {
        setProfileID_forFetch(props.route?.params?.profile_id ?? '');
      }
      setCallEndpoint(true);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              setCallEndpoint(false);
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-sharp'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                  textTransform: 'capitalize',
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          numberOfLines={1}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { paddingLeft: 10, paddingRight: 10, textTransform: 'capitalize' }
            ),
            dimensions.width
          )}
        >
          {props.route?.params?.Other_user_name ?? 'Deleted User'}
        </Text>
        {/* Profile */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('UserProfileScreen', {
                profile_id: props.route?.params?.profile_id ?? '',
              });
              setCallEndpoint(false);
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <>
            {!(
              (props.route?.params?.Other_user_name ?? 'Deleted User') !==
              'Deleted User'
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Nav Btn Phone'],
                    {
                      flexDirection: 'row',
                      height: [
                        { minWidth: Breakpoints.Tablet, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 80 },
                      ],
                      width: [
                        { minWidth: Breakpoints.Tablet, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 80 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                <>
                  {(props.route?.params?.profile_pic ?? '')?.length ? null : (
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: `${props.route?.params?.user_icon ?? ''}`,
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            borderRadius: 16,
                            height: [
                              { minWidth: Breakpoints.Mobile, value: 32 },
                              { minWidth: Breakpoints.Tablet, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                            ],
                            width: [
                              { minWidth: Breakpoints.Mobile, value: 32 },
                              { minWidth: Breakpoints.Tablet, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  )}
                </>
                <>
                  {!(props.route?.params?.profile_pic ?? '')?.length ? null : (
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: `${props.route?.params?.profile_pic ?? ''}`,
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            borderRadius: 16,
                            height: [
                              { minWidth: Breakpoints.Mobile, value: 32 },
                              { minWidth: Breakpoints.Tablet, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                            ],
                            width: [
                              { minWidth: Breakpoints.Mobile, value: 32 },
                              { minWidth: Breakpoints.Tablet, value: 50 },
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  )}
                </>
              </View>
            )}
          </>
        </Pressable>
      </View>
      <>
        {!callEndpoint ? null : (
          <KeyboardAwareScrollView
            contentContainerStyle={StyleSheet.applyWidth(
              { flex: 1 },
              dimensions.width
            )}
            keyboardShouldPersistTaps={'always'}
            showsVerticalScrollIndicator={true}
          >
            <>
              {props.route?.params?.chatroom_id ?? '' ? null : (
                <ChatApi.FetchGetChatroomAllGET
                  chatroom_id={''}
                  handlers={{
                    onData: fetchData => {
                      try {
                        setChat_data(fetchData?._messages);
                        setPost(fetchData?._posts);
                        if (!ChatRoom_ID?.length) {
                          setChatRoom_ID(fetchData?.id);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  post_id={(() => {
                    const e = props.route?.params?.post_id ?? '';
                    console.log(e);
                    return e;
                  })()}
                  profile_id={(() => {
                    const e = profileID_forFetch;
                    console.log(e);
                    return e;
                  })()}
                  refetchInterval={5000}
                >
                  {({ loading, error, data, refetchGetChatroomAll }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Chats */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <>
                            {!post?.type ? null : (
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setCallEndpoint(false);
                                    navigation.navigate('PostDetailsScreen', {
                                      post_id:
                                        props.route?.params?.post_id ?? '',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Quoted message */}
                                <>
                                  {!post ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'stretch',
                                          backgroundColor:
                                            theme.colors['Light Button Color'],
                                          borderBottomWidth: 2,
                                          borderColor: theme.colors['Divider'],
                                          borderRadius: 17,
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* details */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              theme.colors['Custom #ffffff'],
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: 6,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View>
                                          {/* Post */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                  fontSize: 16,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {post?.quantity}
                                            {' CYD - '}
                                            {post?._material_types?.name}
                                          </Text>
                                          {/* Location */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_300Light',
                                                  fontSize: 14,
                                                  textTransform: 'capitalize',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {post?.location}
                                          </Text>
                                          {/* Date */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_300Light',
                                                  fontSize: 14,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'From: '}
                                            {changeDateFormat(
                                              post?.availability_date,
                                              Constants['AppDateformat']
                                            )}
                                            {' To: '}
                                            {changeDateFormat(
                                              post?.expires_at,
                                              Constants['AppDateformat']
                                            )}
                                          </Text>
                                        </View>
                                        <Icon
                                          color={theme.colors['Primary Blue']}
                                          name={'Feather/chevron-right'}
                                          size={24}
                                        />
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Pressable>
                            )}
                          </>
                          <FlatList
                            data={chat_data}
                            inverted={true}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            listKey={'HV2tNMIY'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <>
                                  {/* other user messages */}
                                  <>
                                    {!(listData?.sender_id !== my_id) ? null : (
                                      <Pressable>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-start',
                                              flexDirection: 'column',
                                              marginBottom: 8,
                                              marginRight: '20%',
                                              marginTop: 4,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* message */}
                                          <>
                                            {(
                                              listData?.sender_id ===
                                              Constants['User_Details']
                                            )?.id ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors[
                                                        'Light Button Color'
                                                      ],
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 17,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    marginTop: 16,
                                                    padding: 8,
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* message */}
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Primary Blue'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 15,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?.message}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      </Pressable>
                                    )}
                                  </>
                                  {/* my messages */}
                                  <>
                                    {!(listData?.sender_id === my_id) ? null : (
                                      <Pressable>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-end',
                                              flexDirection: 'column',
                                              marginBottom: 8,
                                              marginLeft: '20%',
                                              marginTop: 4,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Delivered Message */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-end',
                                                marginTop: 16,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* message */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  borderBottomWidth: 1,
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  borderRadius: 17,
                                                  flexDirection: 'row',
                                                  justifyContent: 'center',
                                                  marginLeft: 12,
                                                  padding: 8,
                                                  paddingLeft: 16,
                                                  paddingRight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* message */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      color:
                                                        theme.colors[
                                                          'Custom #ffffff'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.message}
                                              </Text>
                                            </View>
                                            {/* time */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent: 'flex-end',
                                                  marginTop: 4,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* time */}
                                              <>
                                                {!listData?.seen_at ? null : (
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          color:
                                                            theme.colors[
                                                              'Custom Color_22'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 12,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Read '}
                                                  </Text>
                                                )}
                                              </>
                                            </View>
                                          </View>
                                        </View>
                                      </Pressable>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        </View>
                        {/* Send Message View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              backgroundColor: theme.colors['Custom Color_24'],
                              flexDirection: 'row',
                              height: 60,
                              justifyContent: 'space-between',
                              paddingLeft: 6,
                              paddingRight: 6,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Custom #ffffff'],
                                borderRadius: 8,
                                flex: 1,
                                marginRight: 12,
                                overflow: 'hidden',
                              },
                              dimensions.width
                            )}
                          >
                            <TextInput
                              autoCapitalize={'none'}
                              onChangeText={newTextInputValue => {
                                try {
                                  setMessage(newTextInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Send Message...'}
                              placeholderTextColor={theme.colors['Light']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ],
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    height: 40,
                                    paddingLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                              value={message}
                            />
                          </View>

                          <Pressable
                            activeOpacity={0.3}
                            disabled={!message}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setIs_Loading(true);
                                  const result = (
                                    await chatSendAMessagePOST.mutateAsync({
                                      chatrooms_id: ChatRoom_ID,
                                      message: message,
                                      receiver_id:
                                        props.route?.params?.receiver_id ?? '',
                                    })
                                  )?.json;
                                  console.log(result);
                                  setMessage('');
                                  await refetchGetChatroomAll();
                                  setIs_Loading(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 40,
                                  justifyContent: 'center',
                                  paddingTop: 6,
                                  width: 40,
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {is_Loading ? null : (
                                  <Circle
                                    bgColor={theme.colors['Primary Blue']}
                                    size={29}
                                  >
                                    <Icon
                                      color={theme.colors['Custom #ffffff']}
                                      name={'Feather/send'}
                                      size={16}
                                    />
                                  </Circle>
                                )}
                              </>
                              <>
                                {!is_Loading ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'],
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </Pressable>
                        </View>
                      </>
                    );
                  }}
                </ChatApi.FetchGetChatroomAllGET>
              )}
            </>
            {/* Fetch 2 */}
            <>
              {!(props.route?.params?.chatroom_id ?? '') ? null : (
                <ChatApi.FetchGetChatroomAllGET
                  chatroom_id={props.route?.params?.chatroom_id ?? ''}
                  handlers={{
                    onData: fetch2Data => {
                      try {
                        setChat_data(fetch2Data?._messages);
                        setPost(fetch2Data?._posts);
                        if (!ChatRoom_ID?.length) {
                          setChatRoom_ID(fetch2Data?.id);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  post_id={''}
                  profile_id={''}
                  refetchInterval={5000}
                >
                  {({ loading, error, data, refetchGetChatroomAll }) => {
                    const fetch2Data = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Chats */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <>
                            {!post?.type ? null : (
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setCallEndpoint(false);
                                    navigation.navigate('PostDetailsScreen', {
                                      post_id:
                                        props.route?.params?.post_id ?? '',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Quoted message */}
                                <>
                                  {!post ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'stretch',
                                          backgroundColor:
                                            theme.colors['Light Button Color'],
                                          borderBottomWidth: 2,
                                          borderColor: theme.colors['Divider'],
                                          borderRadius: 17,
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* details */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              theme.colors['Custom #ffffff'],
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            padding: 6,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View>
                                          {/* Post */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                  fontSize: 16,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {post?.quantity}
                                            {' CYD - '}
                                            {post?._material_types?.name}
                                          </Text>
                                          {/* Location */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_300Light',
                                                  fontSize: 14,
                                                  textTransform: 'capitalize',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {post?.location}
                                          </Text>
                                          {/* Date */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_300Light',
                                                  fontSize: 14,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'From: '}
                                            {changeDateFormat(
                                              post?.availability_date,
                                              Constants['AppDateformat']
                                            )}
                                            {' To: '}
                                            {changeDateFormat(
                                              post?.expires_at,
                                              Constants['AppDateformat']
                                            )}
                                          </Text>
                                        </View>
                                        <Icon
                                          color={theme.colors['Primary Blue']}
                                          name={'Feather/chevron-right'}
                                          size={24}
                                        />
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Pressable>
                            )}
                          </>
                          <FlatList
                            data={chat_data}
                            inverted={true}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            listKey={'h0zQkRF6'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <>
                                  {/* other user messages */}
                                  <>
                                    {!(listData?.sender_id !== my_id) ? null : (
                                      <Pressable>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-start',
                                              flexDirection: 'column',
                                              marginBottom: 8,
                                              marginRight: '20%',
                                              marginTop: 4,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* message */}
                                          <>
                                            {(
                                              listData?.sender_id ===
                                              Constants['User_Details']
                                            )?.id ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors[
                                                        'Light Button Color'
                                                      ],
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 17,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    marginTop: 16,
                                                    padding: 8,
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* message */}
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Primary Blue'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 15,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?.message}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      </Pressable>
                                    )}
                                  </>
                                  {/* my messages */}
                                  <>
                                    {!(listData?.sender_id === my_id) ? null : (
                                      <Pressable>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-end',
                                              flexDirection: 'column',
                                              marginBottom: 8,
                                              marginLeft: '20%',
                                              marginTop: 4,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Delivered Message */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-end',
                                                marginTop: 16,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* message */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Primary Blue'
                                                    ],
                                                  borderBottomWidth: 1,
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  borderRadius: 17,
                                                  flexDirection: 'row',
                                                  justifyContent: 'center',
                                                  marginLeft: 12,
                                                  padding: 8,
                                                  paddingLeft: 16,
                                                  paddingRight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* message */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      color:
                                                        theme.colors[
                                                          'Custom #ffffff'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.message}
                                              </Text>
                                            </View>
                                            {/* time */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent: 'flex-end',
                                                  marginTop: 4,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* time */}
                                              <>
                                                {!listData?.seen_at ? null : (
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          color:
                                                            theme.colors[
                                                              'Custom Color_22'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 12,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Read '}
                                                  </Text>
                                                )}
                                              </>
                                            </View>
                                          </View>
                                        </View>
                                      </Pressable>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        </View>
                        {/* Send Message View */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              backgroundColor: theme.colors['Custom Color_24'],
                              flexDirection: 'row',
                              height: 60,
                              justifyContent: 'space-between',
                              paddingLeft: 6,
                              paddingRight: 6,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Custom #ffffff'],
                                borderRadius: 8,
                                flex: 1,
                                marginRight: 12,
                                overflow: 'hidden',
                              },
                              dimensions.width
                            )}
                          >
                            <TextInput
                              autoCapitalize={'none'}
                              onChangeText={newTextInputValue => {
                                try {
                                  setMessage(newTextInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              placeholder={'Send Message...'}
                              placeholderTextColor={theme.colors['Light']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ],
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    height: 40,
                                    paddingLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                              value={message}
                            />
                          </View>

                          <Pressable
                            activeOpacity={0.3}
                            disabled={!message}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setIs_Loading(true);
                                  const result = (
                                    await chatSendAMessagePOST.mutateAsync({
                                      chatrooms_id: ChatRoom_ID,
                                      message: message,
                                      receiver_id:
                                        props.route?.params?.receiver_id ?? '',
                                    })
                                  )?.json;
                                  console.log(result);
                                  setMessage('');
                                  await refetchGetChatroomAll();
                                  setIs_Loading(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 40,
                                  justifyContent: 'center',
                                  paddingTop: 6,
                                  width: 40,
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {is_Loading ? null : (
                                  <Circle
                                    bgColor={theme.colors['Primary Blue']}
                                    size={29}
                                  >
                                    <Icon
                                      color={theme.colors['Custom #ffffff']}
                                      name={'Feather/send'}
                                      size={16}
                                    />
                                  </Circle>
                                )}
                              </>
                              <>
                                {!is_Loading ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'],
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </Pressable>
                        </View>
                      </>
                    );
                  }}
                </ChatApi.FetchGetChatroomAllGET>
              )}
            </>
          </KeyboardAwareScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ChatScreen);
