import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ResetPasswordApi from '../apis/ResetPasswordApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const ForgotPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [API_response, setAPI_response] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const input_Validations = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;

    let foundError = false;

    if (email.length < 1) {
      setErr_msg('Email cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (!expr.test(email)) {
      setErr_msg('Please enter a valid email');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Cancel'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
            ),
            dimensions.width
          )}
        >
          {'Forgot Password'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 25,
              lineHeight: 30,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {'Trouble with logging in?'}
        </Text>
        {/* Instructions */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_300Light',
              fontSize: 15,
              marginTop: 16,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {
            "Enter your Email Address, and we'll send you a link to get back into your account."
          }
        </Text>
        {/* Email */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
              }),
              dimensions.width
            )}
          >
            {'Email*'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                { marginBottom: 16 }
              ),
              dimensions.width
            )}
          >
            <TextInput
              autoCapitalize={'none'}
              onChangeText={newTextInputValue => {
                try {
                  setEmail(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Input your email'}
              placeholderTextColor={theme.colors['Primary']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                dimensions.width
              )}
              value={email}
            />
          </View>
        </View>
        {/* error_msg */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Error'],
              fontFamily: 'Poppins_300Light',
              fontSize: 15,
              textAlign: 'center',
              textTransform: 'capitalize',
            }),
            dimensions.width
          )}
        >
          {err_msg}
        </Text>
        {/* success_msg */}
        <>
          {!(API_response?.success === true) ? null : (
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['App Green'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 15,
                  textAlign: 'left',
                  textTransform: 'capitalize',
                }),
                dimensions.width
              )}
            >
              {API_response?.message}
            </Text>
          )}
        </>
        {/* Submit */}
        <Button
          loading={isLoading}
          onPress={() => {
            const handler = async () => {
              try {
                const foundError = input_Validations();
                if (foundError) {
                  return;
                }
                setErr_msg('');
                setIsLoading(true);
                setAPI_response('');
                const result = (
                  await ResetPasswordApi.initiatePasswordResetGET(Constants, {
                    email: email,
                  })
                )?.json;
                setAPI_response(result);
                setIsLoading(false);
                setErr_msg(result?.message);
                if (result?.status === 'Success!') {
                  navigation.navigate('ResetPasswordVerifyOTPScreen', {
                    email: email,
                  });
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
            dimensions.width
          )}
          title={'Submit'}
        />
        {/* Resend */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['click link'],
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
                lineHeight: 22,
              }),
              dimensions.width
            )}
          >
            {'Didn’t received an email?'}
          </Text>

          <Pressable activeOpacity={0.3}>
            {/* Resend */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 16,
                  lineHeight: 22,
                }),
                dimensions.width
              )}
            >
              {' Resend'}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ForgotPasswordScreen);
