import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Pressable,
  ScreenContainer,
  StarRating,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const PostReviewFilledScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [starRatingValue, setStarRatingValue] = React.useState(3);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [ratingValue, setRatingValue] = React.useState(undefined);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Cancel */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Cancel'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold' }
            ),
            dimensions.width
          )}
        >
          {'Fulfilled Post'}
        </Text>
        {/* Save */}
        <Pressable
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Nav Btn Bigscreen'],
                { marginRight: 10, minWidth: 48 }
              ),
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Save'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, padding: 24 },
          dimensions.width
        )}
      >
        {/* heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 25,
              lineHeight: 30,
              textAlign: 'left',
            }),
            dimensions.width
          )}
        >
          {'Please, complete this form to review your experience'}
        </Text>
        {/* Ratings */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
        >
          {/* heading */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Custom Color_27'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
              }),
              dimensions.width
            )}
          >
            {'Rank your experience'}
          </Text>
          <StarRating
            activeColor={theme.colors['Primary Blue']}
            inactiveColor={theme.colors.divider}
            isEditable={false}
            maxStars={5}
            onPress={newStarRatingValue => {
              const ratingValue = newStarRatingValue;
              try {
                setStarRatingValue(newStarRatingValue);
              } catch (err) {
                console.error(err);
              }
            }}
            rating={starRatingValue}
            starSize={60}
            style={StyleSheet.applyWidth(
              { marginTop: 15, width: 300 },
              dimensions.width
            )}
          />
        </View>
        {/* Comments */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginTop: 40 },
            dimensions.width
          )}
        >
          {/* heading */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Custom Color_27'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
              }),
              dimensions.width
            )}
          >
            {'Comments'}
          </Text>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary Blue'],
                fontFamily: 'Poppins_400Regular',
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            }
          </Text>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PostReviewFilledScreen);
