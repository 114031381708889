import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  Switch,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Alert, Text, View } from 'react-native';

const SettingsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [profileResponse, setProfileResponse] = React.useState({});
  const [push_token, setPush_token] = React.useState('');
  const [show_DeleteAccount_View, setShow_DeleteAccount_View] =
    React.useState(false);
  const [show_LogOut_View, setShow_LogOut_View] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const DeleteAccount = (title, message) => {
    Alert.alert(title, message, [
      {
        text: 'Cancel',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: 'Delete',
        onPress: () => {},
      },
    ]);
  };

  const confirmationAlert = async (setGlobalVariableValue, title, message) => {
    Alert.alert(title, message, [
      {
        text: 'Cancel',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: 'Log Out',
        onPress: () => {
          setGlobal({
            key: 'AUTH_TOKEN',
            value: '',
          });
          navigation.navigate('LoginScreen');
        },
      },
    ]);
  };
  const xanoTogglePushNotificationSettingsGET =
    XanoApi.useTogglePushNotificationSettingsGET();
  const xanoDeleteUserDELETE = XanoApi.useDeleteUserDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const api_Response = (await XanoApi.authMeGET(Constants))?.json;
        setPush_token(api_Response?.push_token);
        if (api_Response) {
          setProfileResponse(
            (() => {
              const e = api_Response;
              console.log(e);
              return e;
            })()
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { marginRight: 48, paddingRight: 30 }
            ),
            dimensions.width
          )}
        >
          {'Settings'}
        </Text>
      </View>
      {/* Menu */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* Push Notifications */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 16,
                paddingRight: 16,
              }
            ),
            dimensions.width
          )}
        >
          {/* Menu Name */}
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
              }),
              dimensions.width
            )}
          >
            {'Allow Push Notifications'}
          </Text>
          <>
            {!profileResponse?.id ? null : (
              <Switch
                activeTrackColor={theme.colors['Primary Blue']}
                defaultValue={push_token}
                onValueChange={newSwitchValue => {
                  const handler = async () => {
                    try {
                      setSwitchValue(newSwitchValue);
                      const newToken = await (async () => {
                        if (newSwitchValue) {
                          return await getPushTokenUtil({
                            failMessage:
                              'Please turn on notifications in your phone"s settings.',
                            deviceMessage:
                              'Must use physical device for Push Notifications',
                          });
                        }
                      })();
                      const api_Response = await (async () => {
                        if (newSwitchValue) {
                          return (
                            await xanoTogglePushNotificationSettingsGET.mutateAsync(
                              { push_token: newToken }
                            )
                          )?.json;
                        }
                      })();
                      const result = await (async () => {
                        if (!newSwitchValue) {
                          return (
                            await xanoTogglePushNotificationSettingsGET.mutateAsync(
                              { push_token: '' }
                            )
                          )?.json;
                        }
                      })();
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              />
            )}
          </>
          <>
            {profileResponse?.id ? null : (
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ],
                  dimensions.width
                )}
              />
            )}
          </>
        </View>
        {/* Change Password */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('ChangePasswordScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Change Password'}
            </Text>
          </View>
        </Pressable>
        {/* Blocked Users */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BlockedUsersScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Error'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Blocked Users'}
            </Text>
          </View>
        </Pressable>
        {/* Terms Conditions */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('TermsAndConditionsScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Terms & Conditions'}
            </Text>
          </View>
        </Pressable>
        {/* Privacy Policy */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('PrivacyPolicyScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Privacy Policy'}
            </Text>
          </View>
        </Pressable>
        {/* Log Out */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              setShow_LogOut_View(true);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Log Out'}
            </Text>
          </View>
        </Pressable>
        {/* Delete Account */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              setShow_DeleteAccount_View(true);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['menu_Option_Phone'],
              dimensions.width
            )}
          >
            {/* Menu Name */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                }),
                dimensions.width
              )}
            >
              {'Delete Account'}
            </Text>
          </View>
        </Pressable>
      </View>
      {/* Delete Account View */}
      <>
        {!show_DeleteAccount_View ? null : (
          <BlurView
            intensity={21}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'stretch',
                  bottom: 0,
                  flex: 1,
                  justifyContent: 'center',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }
              ),
              dimensions.width
            )}
            tint={'light'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom #ffffff'],
                  borderColor: theme.colors['Divider'],
                  borderRadius: 10,
                  borderWidth: 1,
                  marginLeft: 30,
                  marginRight: 30,
                  paddingTop: 25,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 17,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Are you sure you want to delete \nyour account?'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 17,
                    marginTop: 10,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'All process will be lost.'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Divider'],
                    borderTopWidth: 2,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Cancel, */}
                <Button
                  onPress={() => {
                    try {
                      setShow_DeleteAccount_View(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Divider'],
                        borderRightWidth: 1,
                        color: theme.colors['Primary Blue'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 16,
                        height: 50,
                        width: '50%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
                {/* Delete */}
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setShow_DeleteAccount_View(false);
                        const Delete_Response = (
                          await xanoDeleteUserDELETE.mutateAsync()
                        )?.json;
                        if (Delete_Response?.status === 'Success!') {
                          showAlertUtil({
                            title: 'Deleted',
                            message:
                              'Your account has been deleted successfully',
                            buttonText: 'Ok',
                          });
                        }
                        if (Delete_Response?.status === 'Success!') {
                          setGlobalVariableValue({
                            key: 'AUTH_TOKEN',
                            value: '',
                          });
                        }
                        if (Delete_Response?.status === 'Success!') {
                          navigation.navigate('LoginScreen');
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Divider'],
                        borderLeftWidth: 1,
                        borderRadius: 0,
                        color: theme.colors['Error'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 17,
                        height: 50,
                        width: '50%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Delete'}
                />
              </View>
            </View>
          </BlurView>
        )}
      </>
      {/* Logout View */}
      <>
        {!show_LogOut_View ? null : (
          <BlurView
            intensity={21}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                {
                  alignItems: 'stretch',
                  bottom: 0,
                  flex: 1,
                  justifyContent: 'center',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }
              ),
              dimensions.width
            )}
            tint={'light'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom #ffffff'],
                  borderColor: theme.colors['Divider'],
                  borderRadius: 10,
                  borderWidth: 1,
                  marginLeft: 30,
                  marginRight: 30,
                  paddingTop: 25,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 17,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Are you sure you want to\nLog Out?'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderColor: theme.colors['Divider'],
                    borderTopWidth: 2,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Cancel, */}
                <Button
                  onPress={() => {
                    try {
                      setShow_LogOut_View(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Divider'],
                        borderRightWidth: 1,
                        color: theme.colors['Primary Blue'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 16,
                        height: 50,
                        width: '50%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
                {/* Log Out */}
                <Button
                  onPress={() => {
                    try {
                      setShow_LogOut_View(false);
                      setGlobalVariableValue({
                        key: 'AUTH_TOKEN',
                        value: '',
                      });
                      navigation.navigate('LoginScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderColor: theme.colors['Divider'],
                        borderLeftWidth: 1,
                        borderRadius: 0,
                        color: theme.colors['Primary Blue'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 17,
                        height: 50,
                        width: '50%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Log Out'}
                />
              </View>
            </View>
          </BlurView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(SettingsScreen);
