import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const createAPostPOST = (
  Constants,
  {
    ability_to_load_material,
    attachment,
    availability_date,
    details,
    expires_in,
    is_trucking_required,
    lat,
    location,
    long,
    material_proctor,
    material_types_id,
    price,
    price_type,
    quantity,
    site_name,
    type,
  },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/posts`, {
    body: JSON.stringify({
      type: type,
      quantity: quantity,
      location: location,
      coordinates: { type: 'point', data: { lat: lat, lng: long } },
      availability_date: availability_date,
      site_name: site_name,
      ability_to_load_material: ability_to_load_material,
      is_trucking_required: is_trucking_required,
      material_proctor: material_proctor,
      price_type: price_type,
      price: price,
      expires_in: expires_in,
      status: 'Pending',
      details: details,
      material_types_id: material_types_id,
      attachment: attachment,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateAPostPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['postsCreateAPostPOST', args],
    () => createAPostPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['postsCreateAPostPOSTS']),
    }
  );
};

export const FetchCreateAPostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ability_to_load_material,
  attachment,
  availability_date,
  details,
  expires_in,
  is_trucking_required,
  lat,
  location,
  long,
  material_proctor,
  material_types_id,
  price,
  price_type,
  quantity,
  site_name,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreateAPostPOST(
    {
      ability_to_load_material,
      attachment,
      availability_date,
      details,
      expires_in,
      is_trucking_required,
      lat,
      location,
      long,
      material_proctor,
      material_types_id,
      price,
      price_type,
      quantity,
      site_name,
      type,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAPost: refetch });
};

export const deleteAPostDELETE = (Constants, { posts_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/posts/${
      typeof posts_id === 'string' ? posts_id : JSON.stringify(posts_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteAPostDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteAPostDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Posts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Post');
        queryClient.invalidateQueries('Posts');
      },
    }
  );
};

export const favoriteUnfavoriteAPostPOST = (
  Constants,
  { posts_id },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/favorite_posts`, {
    body: JSON.stringify({ posts_id: posts_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useFavoriteUnfavoriteAPostPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      favoriteUnfavoriteAPostPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Posts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Post');
        queryClient.invalidateQueries('Posts');
      },
    }
  );
};

export const FetchFavoriteUnfavoriteAPostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  posts_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useFavoriteUnfavoriteAPostPOST(
    { posts_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchFavoriteUnfavoriteAPost: refetch,
  });
};

export const getFavoritePostsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/favorite_posts`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetFavoritePostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Posts', args],
    () => getFavoritePostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFavoritePostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFavoritePostsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFavoritePosts: refetch });
};

export const markPostFulfilledGET = (Constants, { posts_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/mark_fulfilled/${
      typeof posts_id === 'string' ? posts_id : JSON.stringify(posts_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useMarkPostFulfilledGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['postsMarkPostFulfilledGET', args],
    () => markPostFulfilledGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['postsMarkPostFulfilledGETS']),
    }
  );
};

export const FetchMarkPostFulfilledGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  posts_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMarkPostFulfilledGET(
    { posts_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMarkPostFulfilled: refetch });
};

export const matchedPostsGET = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/get_matched_posts?post_id=${encodeQueryParam(
      `${typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useMatchedPostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Matched Posts', args],
    () => matchedPostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchMatchedPostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMatchedPostsGET(
    { post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMatchedPosts: refetch });
};

export const matchedPostsCountGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/get_matched_posts_count`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useMatchedPostsCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Matched Post Count', args],
    () => matchedPostsCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Matched Post Counts']),
    }
  );
};

export const FetchMatchedPostsCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMatchedPostsCountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMatchedPostsCount: refetch });
};

export const myPostsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/my_posts`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useMyPostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Posts', args],
    () => myPostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchMyPostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMyPostsGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMyPosts: refetch });
};

export const nearbyBusinessesGET = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/get_locals?post_id=${encodeQueryParam(
      `${typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useNearbyBusinessesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['postsNearbyBusinessesGET', args],
    () => nearbyBusinessesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['postsNearbyBusinessesGETS']),
    }
  );
};

export const FetchNearbyBusinessesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNearbyBusinessesGET(
    { post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNearbyBusinesses: refetch });
};

export const postDetailsGET = (Constants, { posts_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/posts/${
      typeof posts_id === 'string' ? posts_id : JSON.stringify(posts_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const usePostDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['postsPostDetailsGET', args],
    () => postDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['postsPostDetailsGETS']),
    }
  );
};

export const FetchPostDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  posts_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePostDetailsGET(
    { posts_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPostDetails: refetch });
};

export const updatePostPOST = (
  Constants,
  {
    ability_to_load_material,
    availability_date,
    creator,
    details,
    expires_in,
    fulfilled,
    is_material_protection_required,
    is_trucking_required,
    lat,
    lng,
    location,
    material_types_id,
    posts_id,
    price,
    price_type,
    quantity,
    site_name,
  },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:OEFR61Ae/posts/${
      typeof posts_id === 'string' ? posts_id : JSON.stringify(posts_id ?? '')
    }`,
    {
      body: JSON.stringify({
        posts_id: posts_id,
        quantity: quantity,
        location: location,
        coordinates: { type: 'point', data: { lng: lng, lat: lat } },
        availability_date: availability_date,
        site_name: site_name,
        ability_to_load_material: ability_to_load_material,
        is_trucking_required: is_trucking_required,
        is_material_protection_required: is_material_protection_required,
        price_type: price_type,
        price: price,
        details: details,
        material_types_id: material_types_id,
        creator: creator,
        fulfilled: fulfilled,
        expires_in: expires_in,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdatePostPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updatePostPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Posts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Post');
        queryClient.invalidateQueries('Posts');
      },
    }
  );
};

export const FetchUpdatePostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  ability_to_load_material,
  availability_date,
  creator,
  details,
  expires_in,
  fulfilled,
  is_material_protection_required,
  is_trucking_required,
  lat,
  lng,
  location,
  material_types_id,
  posts_id,
  price,
  price_type,
  quantity,
  site_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdatePostPOST(
    {
      ability_to_load_material,
      availability_date,
      creator,
      details,
      expires_in,
      fulfilled,
      is_material_protection_required,
      is_trucking_required,
      lat,
      lng,
      location,
      material_types_id,
      posts_id,
      price,
      price_type,
      quantity,
      site_name,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdatePost: refetch });
};
