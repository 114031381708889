import React from 'react';

import { CountryPicker } from 'react-native-country-codes-picker';

export function Index({ show, setShow, countryCode, setCountryCode }) {
  return (
    // For showing picker just put show state to show prop
    <CountryPicker
      show={show}
      style={{
        modal: {
          height: 500,
        },

        // Styles for modal backdrop [View]
        backdrop: {},
        // Styles for bottom input line [View]
        line: {},
        // Styles for list of countries [FlatList]
        itemsList: {},
        // Styles for input [TextInput]
        textInput: {},
        // Styles for country button [TouchableOpacity]
        countryButtonStyles: {},
        // Styles for search message [Text]
        searchMessageText: {},
        // Styles for search message container [View]
        countryMessageContainer: {},
        // Flag styles [Text]
        flag: {},
        // Dial code styles [Text]
        dialCode: {},
        // Country name styles [Text]
        countryName: {},
      }}
      initialState={countryCode}
      // when picker button press you will get the country object with dial code
      pickerButtonOnPress={item => {
        setCountryCode(item.dial_code);
        setShow(false);
      }}
    />
  );
}
