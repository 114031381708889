import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, ScrollView, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const MyProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [err_msg, setErr_msg] = React.useState('');
  const [is_Loading, setIs_Loading] = React.useState(true);
  const [myProfile, setMyProfile] = React.useState({});
  const useRelativeTimeGetter = messageTime => {
    const time = messageTime;
    var date = new Date().getTime();
    var elapsed = date - new Date(time).getTime();
    // "time" here is the Parameter name you chose //

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'around ' + Math.round(elapsed / msPerYear) + ' year ago';
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setErr_msg('');
        const result = (
          await XanoApi.userProfileGET(Constants, {
            profile_id: Constants['User_Details']?._profile_of_user?.id,
          })
        )?.json;
        setMyProfile(
          (() => {
            const e = result;
            console.log(e);
            return e;
          })()
        );
        setIs_Loading(true);
        setIs_Loading(false);
        setErr_msg(result?.message);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Matched_posts_count */}
      <PostsApi.FetchMatchedPostsCountGET
        handlers={{
          onData: matchedPostsCountData => {
            try {
              setGlobalVariableValue({
                key: 'matched_posts_count',
                value: matchedPostsCountData?.count,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        refetchInterval={Constants['polling_interval']}
      >
        {({ loading, error, data, refetchMatchedPostsCount }) => {
          const matchedPostsCountData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </PostsApi.FetchMatchedPostsCountGET>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Settings */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('SettingsScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
              { marginLeft: 5, marginRight: 5 }
            ),
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Strong']}
              name={'Feather/settings'}
              size={28}
            />
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Screen heading Phone'],
            dimensions.width
          )}
        >
          {myProfile?.profile_name}
        </Text>
        {/* Edit */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('EditMyProfileScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Strong']}
              name={'Feather/edit'}
              size={28}
            />
          </View>
        </Pressable>
      </View>
      <>
        {is_Loading ? null : (
          <ScrollView
            bounces={true}
            contentContainerStyle={StyleSheet.applyWidth(
              { padding: 16, paddingBottom: 65 },
              dimensions.width
            )}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            {/* Image */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                },
                dimensions.width
              )}
            >
              <>
                {myProfile?.profile_image ? null : (
                  <Image
                    resizeMode={'cover'}
                    source={{ uri: `${myProfile?._user_types?.icon_path}` }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 200, width: 200 }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              <>
                {!myProfile?.profile_image ? null : (
                  <Image
                    resizeMode={'cover'}
                    source={{ uri: `${myProfile?.profile_image?.url}` }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { borderRadius: 8, height: 200, width: 200 }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
            {/* Full Name */}
            <>
              {!myProfile?.profile_name ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { height: 64, marginTop: 8 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      dimensions.width
                    )}
                  >
                    {'Full Name'}
                  </Text>
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { textTransform: 'capitalize' }
                      ),
                      dimensions.width
                    )}
                  >
                    {myProfile?.profile_name} {myProfile?.last_name}
                  </Text>
                </View>
              )}
            </>
            {/* Email Address */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Email Address'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {myProfile?.email}
              </Text>
            </View>
            {/* Phone Number */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Phone Number'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {'+1'}
                {myProfile?.phone_number}
              </Text>
            </View>
            {/* Profile Type */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Profile Type'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    { color: theme.colors['Medium'] }
                  ),
                  dimensions.width
                )}
              >
                {myProfile?._user_types?.name}
              </Text>
            </View>
            {/* Business Classification */}
            <>
              {!myProfile?.type_of_business ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { height: 64, marginTop: 20 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['detail_heading'],
                      dimensions.width
                    )}
                  >
                    {'Business Classification'}
                  </Text>
                  {/* Value */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Label Values'],
                        { color: theme.colors['Medium'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {myProfile?.type_of_business}
                  </Text>
                </View>
              )}
            </>
            {/* Location */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Location'}
              </Text>
              {/* Value */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Label Values'],
                    {
                      color: theme.colors['Medium'],
                      textTransform: 'capitalize',
                    }
                  ),
                  dimensions.width
                )}
              >
                {myProfile?.location}
              </Text>
            </View>
            {/* Contact Preferences */}
            <View
              style={StyleSheet.applyWidth(
                { height: 64, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['detail_heading'],
                  dimensions.width
                )}
              >
                {'Contact Preferences'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-start', flexDirection: 'column' },
                  dimensions.width
                )}
              >
                {/* Call */}
                <>
                  {!myProfile?._permissions_of_user?.privacy_call ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'• Call'}
                    </Text>
                  )}
                </>
                {/* messenger */}
                <>
                  {!myProfile?._permissions_of_user
                    ?.privacy_messenger ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'• Text'}
                    </Text>
                  )}
                </>
                {/* Email */}
                <>
                  {!myProfile?._permissions_of_user?.privacy_mail ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Label Values'],
                          { color: theme.colors['Medium'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {'• Email\n\n'}
                    </Text>
                  )}
                </>
              </View>
            </View>
          </ScrollView>
        )}
      </>
      {/* Loading View */}
      <>
        {!is_Loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'large'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ],
                  { height: 65, width: 65 }
                ),
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Please wait...\nFetching your details'}
            </Text>
          </View>
        )}
      </>
      {/* Err View */}
      <>
        {!err_msg?.length ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Err View'],
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {'Some error occurred. Please try again later'}
            </Text>
          </View>
        )}
      </>
      {/* tabbar */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['tabbar 2'], {
            paddingLeft: { minWidth: Breakpoints.Tablet, value: '20%' },
            paddingRight: { minWidth: Breakpoints.Tablet, value: '20%' },
          }),
          dimensions.width
        )}
      >
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'HomeScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.Home}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'Home'}
            </Text>
          </View>
        </Pressable>

        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'MyPostsScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyPosts}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  {
                    color: theme.colors['Primary'],
                    fontFamily: 'Poppins_400Regular',
                  }
                ),
                dimensions.width
              )}
            >
              {'My Posts'}
            </Text>
            {/* Post_Badge */}
            <>
              {!Constants['matched_posts_count'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['Post_Badge 3'],
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Custom #ffffff'],
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['matched_posts_count']}
                  </Text>
                </View>
              )}
            </>
          </View>
        </Pressable>

        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('CreatePostScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, marginTop: -58, width: '24%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                borderColor: theme.colors['Divider'],
                borderTopLeftRadius: 45,
                borderTopRightRadius: 45,
                borderTopWidth: 1,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            <Circle bgColor={theme.colors['Custom Color_25']} size={40}>
              <Icon
                color={theme.colors['Custom #ffffff']}
                name={'Entypo/plus'}
                size={24}
              />
            </Circle>

            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'], marginTop: 7 }
                ),
                dimensions.width
              )}
            >
              {'Create Post'}
            </Text>
          </View>
        </Pressable>

        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'FavouritesScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.Favorites}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary'] }
                ),
                dimensions.width
              )}
            >
              {'Favorites'}
            </Text>
          </View>
        </Pressable>

        <Pressable
          activeOpacity={0.3}
          style={StyleSheet.applyWidth(
            { height: 49, width: '19%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: 49 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={Images.MyProfileSelected}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 20,
                  marginTop: 4,
                  width: 25,
                }),
                dimensions.width
              )}
            />
            <Text
              allowFontScaling={false}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                  { color: theme.colors['Primary Blue'] }
                ),
                dimensions.width
              )}
            >
              {'My Profile'}
            </Text>
          </View>
        </Pressable>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(MyProfileScreen);
