export default {
  DirtNearMe: require('../assets/images/DirtNearMe.png'),
  Onboarding2: require('../assets/images/Onboarding2.png'),
  Onboarding3: require('../assets/images/Onboarding3.png'),
  MyProfile: require('../assets/images/MyProfile.png'),
  Favorites: require('../assets/images/Favorites.png'),
  MyPosts: require('../assets/images/MyPosts.png'),
  HomeSelected: require('../assets/images/HomeSelected.png'),
  Pin: require('../assets/images/Pin.png'),
  Icon: require('../assets/images/Icon.png'),
  Home: require('../assets/images/Home.png'),
  MyPostsSelected: require('../assets/images/MyPostsSelected.png'),
  Medallions: require('../assets/images/Medallions.png'),
  ProfileImage: require('../assets/images/ProfileImage.png'),
  FavSelected: require('../assets/images/FavSelected.png'),
  ImageSurface: require('../assets/images/ImageSurface.png'),
  MyProfileSelected: require('../assets/images/MyProfileSelected.png'),
  Grass: require('../assets/images/Grass.png'),
  Recycle: require('../assets/images/Recycle.png'),
  Paving: require('../assets/images/Paving.png'),
  DumpTruck: require('../assets/images/DumpTruck.png'),
  Shovel: require('../assets/images/Shovel.png'),
  AnimationLklfy4abSmall: require('../assets/images/AnimationLklfy4abSmall.gif'),
  ArvindMap: require('../assets/images/ArvindMap.png'),
  ArvindMatching: require('../assets/images/ArvindMatching.png'),
  Silver: require('../assets/images/Silver.png'),
  Bronze: require('../assets/images/Bronze.png'),
  NoInternet: require('../assets/images/NoInternet.gif'),
  NoDocs: require('../assets/images/NoDocs.gif'),
  Chat: require('../assets/images/Chat.png'),
  Notif: require('../assets/images/Notif.png'),
  DirtNearMeColorsLOGOTRANSPARENCY5: require('../assets/images/DirtNearMeColorsLOGOTRANSPARENCY(5).png'),
  Search: require('../assets/images/Search.png'),
  SearchIcon: require('../assets/images/SearchIcon.png'),
  SearchIcon2: require('../assets/images/SearchIcon2.png'),
  Search3: require('../assets/images/Search3.png'),
  Deleted: require('../assets/images/Deleted.jpeg'),
};
