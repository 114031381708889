import React from 'react';
import * as CustomCode from '../custom-files/CustomCode';

const generateBranchIOLink = async (postDetail, postId, postStatus) => {
  console.log(
    'generateBranchIOLink postDetail?.creator : ',
    postDetail?.creator
  );
  const branch = CustomCode.branch;
  let buo;

  // Share image url also
  if (postDetail?._post_attachments?.file?.url) {
    buo = await branch.createBranchUniversalObject(`/${postId}`, {
      title:
        postDetail.type +
        ' ' +
        postDetail.quantity +
        ' CYD - ' +
        postDetail._material_types.name,
      contentDescription: postDetail.details,
      contentImageUrl: postDetail?._post_attachments?.file?.url,
      contentMetadata: {
        customMetadata: {
          postId: String(postId),
          postStatus,
          postCreatorId: String(postDetail.creator),
        },
      },
    });
  } else {
    buo = await branch.createBranchUniversalObject(`/${postId}`, {
      title:
        postDetail.type +
        ' ' +
        postDetail.quantity +
        ' CYD - ' +
        postDetail._material_types.name,
      contentDescription: postDetail.details,
      contentMetadata: {
        customMetadata: {
          postId: String(postId),
          postStatus,
          postCreatorId: String(postDetail.creator),
        },
      },
    });
  }

  let linkProperties = {
    feature: 'sharing',
    channel: 'facebook',
  };

  let controlParams = {};

  let { url } = await buo.generateShortUrl(linkProperties, controlParams);
  return url;
};

export default generateBranchIOLink;
