import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getAllChatroomsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/chatrooms`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllChatroomsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['chatGetAllChatroomsGET', args],
    () => getAllChatroomsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['chatGetAllChatroomsGETS']),
    }
  );
};

export const FetchGetAllChatroomsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllChatroomsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllChatrooms: refetch });
};

export const getChatroomAllGET = (
  Constants,
  { chatroom_id, post_id, profile_id },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/get_chatroom_all?post_id=${encodeQueryParam(
      `${typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')}`
    )}&profile_id=${encodeQueryParam(
      `${
        typeof profile_id === 'string'
          ? profile_id
          : JSON.stringify(profile_id ?? '')
      }`
    )}&chatroom_id=${encodeQueryParam(
      `${
        typeof chatroom_id === 'string'
          ? chatroom_id
          : JSON.stringify(chatroom_id ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetChatroomAllGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Get Chatroom All', args],
    () => getChatroomAllGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Get Chatroom Alls']),
    }
  );
};

export const FetchGetChatroomAllGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  chatroom_id,
  post_id,
  profile_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetChatroomAllGET(
    { chatroom_id, post_id, profile_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetChatroomAll: refetch });
};

export const getChatroomProfileGET = (
  Constants,
  { profile_id },
  handlers = {}
) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/get_chatroom_profile?profile_id=${encodeQueryParam(
      `${
        typeof profile_id === 'string'
          ? profile_id
          : JSON.stringify(profile_id ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetChatroomProfileGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['chatGetChatroomProfileGET', args],
    () => getChatroomProfileGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['chatGetChatroomProfileGETS']),
    }
  );
};

export const FetchGetChatroomProfileGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  profile_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetChatroomProfileGET(
    { profile_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetChatroomProfile: refetch });
};

export const getUnreadMessagesCountGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/get_all_unread_messages_count`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUnreadMessagesCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Chat Messages Count', args],
    () => getUnreadMessagesCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Chat Messages Counts']),
    }
  );
};

export const FetchGetUnreadMessagesCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUnreadMessagesCountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUnreadMessagesCount: refetch,
  });
};

export const getAChatRoomGET = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/get_chatroom?post_id=${encodeQueryParam(
      `${typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['AUTH_TOKEN'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAChatRoomGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['chatGetAChatRoomGET', args],
    () => getAChatRoomGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['chatGetAChatRoomGETS']),
    }
  );
};

export const FetchGetAChatRoomGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAChatRoomGET(
    { post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAChatRoom: refetch });
};

export const sendAMessagePOST = (
  Constants,
  { chatrooms_id, message, receiver_id },
  handlers = {}
) =>
  fetch(`https://xy5t-co72-skd0.n7c.xano.io/api:Ma2ydkb0/messages`, {
    body: JSON.stringify({
      chatrooms_id: chatrooms_id,
      receiver_id: receiver_id,
      message: message,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSendAMessagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => sendAMessagePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Chat', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Chat');
        queryClient.invalidateQueries('Chats');
      },
    }
  );
};

export const FetchSendAMessagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  chatrooms_id,
  message,
  receiver_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendAMessagePOST(
    { chatrooms_id, message, receiver_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSendAMessage: refetch });
};
