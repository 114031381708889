import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, Pressable, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Image, Text, View } from 'react-native';

const NearbyBusinessesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [All_businesses, setAll_businesses] = React.useState([]);
  const [is_loading, setIs_loading] = React.useState(true);
  const [selected_Menu, setSelected_Menu] = React.useState('Active');
  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const result = (
          await PostsApi.nearbyBusinessesGET(Constants, {
            post_id: props.route?.params?.post_id ?? 25,
          })
        )?.json;
        setAll_businesses(result);
        setIs_loading(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary Blue']}
              name={'Ionicons/chevron-back-outline'}
              size={24}
            />
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { marginRight: 48 }
            ),
            dimensions.width
          )}
        >
          {'Nearby Businesses'}
        </Text>
      </View>
      {/* Businesses */}
      <>
        {!(selected_Menu === 'Active') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Custom #ffffff'],
                flex: 1,
                marginTop: 5,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            <FlatList
              data={All_businesses}
              keyExtractor={(listData, index) =>
                listData?.id ?? listData?.uuid ?? index.toString()
              }
              listKey={'DQTyTc6d'}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        navigation.navigate('UserProfileScreen', {
                          profile_id: listData?.id,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Divider'],
                          flexDirection: 'row',
                          height: 85,
                          justifyContent: 'flex-start',
                          paddingRight: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={{
                            uri: `${listData?._user_types?.icon_path}`,
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              { borderRadius: 8, height: 60, width: 60 }
                            ),
                            dimensions.width
                          )}
                        />
                        {/* Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, paddingLeft: 25, paddingRight: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 17,
                                  lineHeight: 22,
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.profile_name}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Primary Blue'],
                                  fontFamily: 'Poppins_300Light',
                                  fontSize: 15,
                                  lineHeight: 24,
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?._user_types?.name}
                          </Text>

                          <Text
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'post_list_date'
                                ],
                                { marginTop: 2, textTransform: 'capitalize' }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.location}
                          </Text>
                        </View>
                        <Icon
                          color={theme.colors['Primary']}
                          name={'Feather/chevron-right'}
                          size={24}
                        />
                      </View>
                    </View>
                  </Pressable>
                );
              }}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            />
            {/* empty state */}
            <>
              {All_businesses ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['empty state 2'],
                      { margin: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 22,
                          lineHeight: 28,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You don’t have posts yet.\nCreate one by clicking on “Create Post”'
                    }
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Loading_View */}
      <>
        {!is_loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Loading_View'],
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 125,
                  width: 125,
                }),
                dimensions.width
              )}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary Blue'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 21,
                  marginTop: 12,
                }),
                dimensions.width
              )}
            >
              {'Fetching Details...'}
            </Text>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(NearbyBusinessesScreen);
