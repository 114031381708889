import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, Pressable, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';

const ImageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Top_Header'],
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                height: 48,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={28}
            />
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Back bigscreen'],
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { marginRight: 70 }
            ),
            dimensions.width
          )}
        >
          {'Image'}
        </Text>
      </View>
      <Image
        resizeMode={'contain'}
        source={{ uri: `${props.route?.params?.URL ?? ''}` }}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
            height: '80%',
            width: '100%',
          }),
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(ImageScreen);
