import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ChatApi from '../apis/ChatApi.js';
import * as GeoCodeApi from '../apis/GeoCodeApi.js';
import * as PostsApi from '../apis/PostsApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import useIsOnline from '../utils/useIsOnline';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapCallout, MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  Surface,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const HomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Company_types, setCompany_types] = React.useState([]);
  const [Material_types, setMaterial_types] = React.useState([
    'Dirt',
    'Gravel',
    'Stone',
    'Sand',
  ]);
  const [PostsList, setPostsList] = React.useState([]);
  const [Static_Materials, setStatic_Materials] = React.useState([]);
  const [callout_width, setCallout_width] = React.useState(0);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  const [distance, setDistance] = React.useState(50);
  const [distance_filter, setDistance_filter] = React.useState([
    '25',
    '50',
    '100',
    '0',
  ]);
  const [filter_byUsers, setFilter_byUsers] = React.useState([]);
  const [have_selected, setHave_selected] = React.useState(true);
  const [location_search_text, setLocation_search_text] = React.useState('');
  const [myLocation, setMyLocation] = React.useState('');
  const [my_latitude, setMy_latitude] = React.useState(0);
  const [my_longitude, setMy_longitude] = React.useState(0);
  const [need_selected, setNeed_selected] = React.useState(true);
  const [place_id, setPlace_id] = React.useState('ChIJSTKCCzZwQIYRPN4IGI8c6xY');
  const [price_Pref_Array, setPrice_Pref_Array] = React.useState([
    { label: 'Fixed', value: '1', isSelected: true },
    { label: 'Negotiable', value: '2', isSelected: true },
    { label: 'Free', value: '3', isSelected: true },
  ]);
  const [price_type, setPrice_type] = React.useState('');
  const [pricingGroup, setPricingGroup] = React.useState('');
  const [pricing_options, setPricing_options] = React.useState([
    'Negotiable',
    'Fixed',
    'Free',
  ]);
  const [search, setSearch] = React.useState('');
  const [selectedPost, setSelectedPost] = React.useState({});
  const [selected_CompanyType, setSelected_CompanyType] = React.useState('');
  const [selected_Material_type, setSelected_Material_type] =
    React.useState('');
  const [selected_UserTypes, setSelected_UserTypes] = React.useState([]);
  const [selected_material_Ids, setSelected_material_Ids] = React.useState([]);
  const [selected_price_types, setSelected_price_types] = React.useState([
    'Fixed',
    'Negotiable',
    'Free',
  ]);
  const [show_Filters, setShow_Filters] = React.useState(false);
  const [show_Full_PostsView, setShow_Full_PostsView] = React.useState(false);
  const [show_all_filters, setShow_all_filters] = React.useState(false);
  const [show_info_Modal, setShow_info_Modal] = React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_selectedPost, setShow_selectedPost] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [type, setType] = React.useState('');
  const [unread_messages_count, setUnread_messages_count] = React.useState(0);
  const [zoomLevel, setZoomLevel] = React.useState(10);
  const set_UserTypes_Filter = userTypes => {
    let newArr = userTypes;
    var item = userTypes[0];

    if (!item.hasOwnProperty('status')) {
      newArr = newArr.map(v => ({ ...v, isSelected: true }));
    }

    return newArr;
  };

  const toggleUserTypes = value => {
    return filter_byUsers.map(item => {
      if (item.value === value) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
  };

  const selected_UserTypes_Ids = list => {
    let id_Arr = [];
    let filteredArr = list.filter(item => item.isSelected == true);
    filteredArr.forEach(element => id_Arr.push(parseInt(element.value)));
    return id_Arr;
  };

  const set_MaterialTypes_Filter = list => {
    let newArr = list;
    var item = list[0];
    if (!item.hasOwnProperty('status')) {
      newArr = newArr.map(v => ({ ...v, isSelected: true }));
    }

    return newArr;
  };

  const selected_MaterialType_Ids = list => {
    let id_Arr = [];
    let filteredArr = list.filter(item => item.isSelected == true);
    filteredArr.forEach(element => id_Arr.push(parseInt(element.value)));
    return id_Arr;
  };

  const toggle_Price_Preferences = value => {
    return price_Pref_Array.map(item => {
      if (item.value === value) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
  };

  const selected_price_prefs = list => {
    let id_Arr = [];
    let filteredArr = list.filter(item => item.isSelected == true);
    filteredArr.forEach(element => id_Arr.push(element.label));
    return id_Arr;
  };

  const getCalloutWidth = item => {
    return item.length * 10;
  };

  // Selects one of the Static_Materials items selected true and the rest false
  const toggleStaticMaterial = id => {
    return Static_Materials.map(item => {
      if (item.value === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
  };

  const addCommasToNumber = number => {
    const numberString = String(number); // Convert the number to a string
    let result = ''; // Initialize the result string

    // Iterate over the characters in reverse order
    for (let i = numberString.length - 1; i >= 0; i--) {
      result = numberString[i] + result; // Prepend the current character

      // Add a comma after every third character (except for the last character)
      if ((numberString.length - i) % 3 === 0 && i > 0) {
        result = ',' + result; // Prepend a comma
      }
    }

    return result;
  };

  const changeDateFormat = (dateString, format) => {
    return CustomCode.moment(dateString).format(format);
  };

  const getZoomLevel = item => {
    if (item == '25') {
      return 12;
    }

    if (item == '50') {
      return 10;
    }

    if (item == '100') {
      return 5;
    }

    return 4;
  };

  const checkPostType = item => {
    if (item.type == 'Have') return item._material_types.have_icon.url;

    return item._material_types.need_icon.url;
  };
  const isOnline = useIsOnline();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (myLocation?.length) {
          return;
        }
        if (Constants['locationGranted'] === 'NO') {
          setMyLocation(Constants['User_Details']?._profile_of_user?.location);
        }
        if (Constants['locationGranted'] === 'NO') {
          setMy_latitude(
            Constants['User_Details']?._profile_of_user?.coordinates?.data?.lat
          );
        }
        if (Constants['locationGranted'] === 'NO') {
          setMy_longitude(
            Constants['User_Details']?._profile_of_user?.coordinates?.data?.lng
          );
        }
        const material_type_results = (
          await XanoApi.materialTypesGET(Constants)
        )?.json;
        const updated_materialTypes = set_MaterialTypes_Filter(
          material_type_results
        );
        setStatic_Materials(updated_materialTypes);
        const default_MaterialIds = selected_MaterialType_Ids(
          updated_materialTypes
        );
        setSelected_material_Ids(default_MaterialIds);
        const users = (await XanoApi.userTypes2GET(Constants))?.json;
        const newUserTypes = set_UserTypes_Filter(users);
        if (!filter_byUsers?.length) {
          setFilter_byUsers(newUserTypes);
        }
        const default_UserIds = selected_UserTypes_Ids(newUserTypes);
        setSelected_UserTypes(default_UserIds);
        if (Constants['locationGranted'] === 'NO') {
          return;
        }
        const myLocationResult = await getLocationUtil();
        setMy_longitude(myLocationResult?.longitude);
        setMy_latitude(myLocationResult?.latitude);
        const reverseLocation = (
          await GeoCodeApi.reverseGeocodeGET(Constants, {
            api_key: Constants['GeoCode_Key'],
            latitude: myLocationResult?.latitude,
            longitude: myLocationResult?.longitude,
          })
        )?.json;
        if (reverseLocation) {
          setMyLocation(reverseLocation?.results[0]?.formatted_address);
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const textInputeEM1u0QnRef = React.useRef();

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      scrollable={false}
    >
      {/* machted_Posts_count */}
      <>
        {!isOnline ? null : (
          <PostsApi.FetchMatchedPostsCountGET
            handlers={{
              onData: machtedPostsCountData => {
                try {
                  setGlobalVariableValue({
                    key: 'matched_posts_count',
                    value: machtedPostsCountData?.count,
                  });
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            refetchInterval={10000}
          >
            {({ loading, error, data, refetchMatchedPostsCount }) => {
              const machtedPostsCountData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return null;
            }}
          </PostsApi.FetchMatchedPostsCountGET>
        )}
      </>
      {/* Header */}
      <>
        {!isOnline ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Top_Header'],
              dimensions.width
            )}
          >
            {/* Info */}
            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  setShow_info_Modal(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.PressableStyles(theme)['header_text_btn_phone'],
                  { marginLeft: 5 }
                ),
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Nav Btn Bigscreen'],
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Primary']}
                  name={'Ionicons/information-circle-outline'}
                  size={28}
                />
              </View>
            </Pressable>
            {/* screen heading */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                  { paddingLeft: 40 }
                ),
                dimensions.width
              )}
            >
              {'Home'}
            </Text>
            {/* Right Section */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'flex-end' },
                dimensions.width
              )}
            >
              {/* Notification */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    navigation.navigate('NotificationsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.PressableStyles(theme)[
                      'header_text_btn_phone'
                    ],
                    { marginRight: 0 }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['Nav Btn Bigscreen'],
                      { width: 40 }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Ionicons/notifications-outline'}
                    size={24}
                  />
                </View>
              </Pressable>

              <ChatApi.FetchGetUnreadMessagesCountGET refetchInterval={30000}>
                {({ loading, error, data, refetchGetUnreadMessagesCount }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Messages */}
                      <Pressable
                        activeOpacity={0.3}
                        onPress={() => {
                          try {
                            navigation.navigate('ChatListScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.PressableStyles(theme)[
                              'header_text_btn_phone'
                            ],
                            { marginLeft: 0, marginRight: 0 }
                          ),
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ViewStyles(theme)[
                                'Nav Btn Bigscreen'
                              ],
                              { width: 40 }
                            ),
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={theme.colors['Primary']}
                            name={'Ionicons/md-chatbubble-outline'}
                            size={24}
                          />
                          {/* Badge */}
                          <>
                            {!fetchData?.count ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors['Custom Color_29'],
                                    borderRadius: 9,
                                    height: 18,
                                    justifyContent: 'center',
                                    minWidth: 18,
                                    position: 'absolute',
                                    right: 5,
                                    top: 5,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  allowFontScaling={false}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Custom #ffffff'],
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.count}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      </Pressable>
                    </>
                  );
                }}
              </ChatApi.FetchGetUnreadMessagesCountGET>
            </View>
          </View>
        )}
      </>
      {/* No Internet View */}
      <>
        {isOnline ? null : (
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['empty state 6'],
              dimensions.width
            )}
          >
            {/* empty set UI */}
            <View
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['empty state'],
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 31,
                    marginBottom: 10,
                    opacity: 0.5,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'No Internet \nConnection '}
              </Text>
              <Image
                resizeMode={'cover'}
                source={Images.NoInternet}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: 300,
                    width: 300,
                  }),
                  dimensions.width
                )}
              />
              <Button
                icon={'AntDesign/wifi'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    {
                      backgroundColor: theme.colors['Secondary'],
                      color: theme.colors['Custom #ffffff'],
                      fontFamily: 'Coda_400Regular',
                      fontSize: 18,
                      paddingBottom: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 10,
                    }
                  ),
                  dimensions.width
                )}
                title={'   Refresh'}
              />
            </View>
          </View>
        )}
      </>
      {/* My Location */}
      <>
        {!isOnline ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Custom #ffffff'],
                height: 35,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'stretch' },
                dimensions.width
              )}
            >
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setLocation_search_text('');
                    setShow_location_modal(true);
                    textInputeEM1u0QnRef.current.focus();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingLeft: 30,
                      paddingRight: 30,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.Search}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { height: 21, marginRight: 5, width: 17 }
                      ),
                      dimensions.width
                    )}
                  />
                  <>
                    {!myLocation ? null : (
                      <Text
                        numberOfLines={1}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_300Light',
                              fontSize: 16,
                              marginLeft: 4,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {myLocation}
                      </Text>
                    )}
                  </>
                  <>
                    {myLocation ? null : (
                      <Text
                        numberOfLines={1}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary Blue'],
                              fontFamily: 'Poppins_300Light',
                              fontSize: 16,
                              marginLeft: 4,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Set My Location'}
                      </Text>
                    )}
                  </>
                </View>
              </Pressable>
            </View>
          </View>
        )}
      </>
      {/* Online View */}
      <>
        {!isOnline ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Top Menu */}
            <>
              {!my_latitude ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'space-between',
                        },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      padding: 20,
                      paddingBottom: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  {/* Have */}
                  <View>
                    {/* selected */}
                    <>
                      {!have_selected ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              if (!need_selected) {
                                return;
                              }
                              setShow_selectedPost(false);
                              setHave_selected(false);
                              if (!need_selected) {
                                setType('');
                              }
                              if (need_selected) {
                                setType('Need');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <Surface
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                { borderRadius: 13, minHeight: 32 }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: theme.colors['Primary Blue'],
                                  borderRadius: 13,
                                  height: 32,
                                  justifyContent: 'center',
                                  width: 135,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Custom #ffffff'],
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 15,
                                      letterSpacing: 1,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'HAVE'}
                              </Text>
                            </View>
                          </Surface>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {have_selected ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setShow_selectedPost(false);
                              setHave_selected(true);
                              if (!need_selected) {
                                setType('Have');
                              }
                              if (need_selected) {
                                setType('');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <Surface
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                { borderRadius: 13, minHeight: 32 }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Custom #ffffff'],
                                  borderRadius: 13,
                                  height: 32,
                                  justifyContent: 'center',
                                  width: 135,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'HAVE'}
                              </Text>
                            </View>
                          </Surface>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* Need */}
                  <View>
                    {/* selected */}
                    <>
                      {!need_selected ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              if (!have_selected) {
                                return;
                              }
                              setShow_selectedPost(false);
                              setNeed_selected(false);
                              if (!have_selected) {
                                setType('');
                              }
                              if (have_selected) {
                                setType('Have');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              marginLeft: {
                                minWidth: Breakpoints.Tablet,
                                value: 20,
                              },
                              marginRight: {
                                minWidth: Breakpoints.Tablet,
                                value: 20,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                { borderRadius: 13, minHeight: 32 }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Primary Yellow'],
                                  borderRadius: 13,
                                  height: 32,
                                  justifyContent: 'center',
                                  width: 135,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Custom #ffffff'],
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 15,
                                      letterSpacing: 1,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'NEED'}
                              </Text>
                            </View>
                          </Surface>
                        </Pressable>
                      )}
                    </>
                    {/* unselected */}
                    <>
                      {need_selected ? null : (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setShow_selectedPost(false);
                              setNeed_selected(true);
                              if (!have_selected) {
                                const valueLWybZOJN = 'Need';
                                setType(valueLWybZOJN);
                                const newtype = valueLWybZOJN;
                              }
                              if (have_selected) {
                                setType('');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              marginLeft: {
                                minWidth: Breakpoints.Tablet,
                                value: 20,
                              },
                              marginRight: {
                                minWidth: Breakpoints.Tablet,
                                value: 20,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                { borderRadius: 13, minHeight: 32 }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Custom #ffffff'],
                                  borderRadius: 13,
                                  height: 32,
                                  justifyContent: 'center',
                                  width: 135,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Yellow'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'NEED'}
                              </Text>
                            </View>
                          </Surface>
                        </Pressable>
                      )}
                    </>
                  </View>
                  {/* Filters */}
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShow_all_filters(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Surface
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          { borderRadius: 13, minHeight: 32 }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Light Button Color'],
                            borderRadius: 13,
                            height: 32,
                            justifyContent: 'center',
                            width: 41,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon name={'MaterialIcons/tune'} size={20} />
                      </View>
                    </Surface>
                  </Pressable>
                </View>
              )}
            </>
            <>
              {!my_latitude ? null : (
                <XanoApi.FetchHomePOST
                  distance={distance}
                  handlers={{
                    onData: fetchData => {
                      try {
                        setShow_selectedPost(false);
                        const businesses = fetchData?.profiles;
                        if (businesses?.length) {
                          setPostsList((fetchData?.posts).concat(businesses));
                        }
                        if (!businesses?.length) {
                          setPostsList(fetchData?.posts);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  lat={my_latitude}
                  long={my_longitude}
                  material_type={selected_material_Ids}
                  price_type={selected_price_types}
                  search={search}
                  type={type}
                  user_type={selected_UserTypes}
                >
                  {({ loading, error, data, refetchHome }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Content */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* MapView */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <MapView
                              apiKey={'AIzaSyCqfSy6WqO1ho-saMDNPlLHLgNf9QaOW0g'}
                              autoClusterMarkers={true}
                              autoClusterMarkersDistanceMeters={1000000}
                              keyExtractor={(mapViewData, index) =>
                                mapViewData?.id ??
                                mapViewData?.uuid ??
                                index.toString()
                              }
                              latitude={my_latitude}
                              listKey={'WIwKlZzq'}
                              loadingEnabled={true}
                              longitude={my_longitude}
                              mapType={'standard'}
                              markersData={PostsList}
                              provider={'google'}
                              renderItem={({ item, index }) => {
                                const mapViewData = item;
                                return (
                                  <>
                                    {/* Have Post */}
                                    <>
                                      {!(
                                        mapViewData?.type === 'Have'
                                      ) ? null : (
                                        <MapMarker
                                          androidUseDefaultIconImplementation={
                                            true
                                          }
                                          description={
                                            mapViewData?._material_types?.name
                                          }
                                          latitude={
                                            mapViewData?.coordinates?.data?.lat
                                          }
                                          longitude={
                                            mapViewData?.coordinates?.data?.lng
                                          }
                                          onPress={(latitude, longitude) => {
                                            try {
                                              setSelectedPost(mapViewData);
                                              setShow_selectedPost(true);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          pinImage={`${mapViewData?._material_types?.have_icon?.url}`}
                                          pinImageSize={80}
                                          title={mapViewData?.location?.toUpperCase()}
                                        >
                                          <MapCallout showTooltip={true}>
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 8,
                                                  maxWidth: 300,
                                                  overflow: 'hidden',
                                                  width: getCalloutWidth(
                                                    mapViewData?.location
                                                  ),
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Location */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Poppins_600SemiBold',
                                                      fontSize: 17,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {mapViewData?.location}
                                              </Text>
                                              {/* material */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      flex: 1,
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  mapViewData?._material_types
                                                    ?.name
                                                }
                                              </Text>
                                            </View>
                                          </MapCallout>
                                        </MapMarker>
                                      )}
                                    </>
                                    {/* Need Post */}
                                    <>
                                      {!(
                                        mapViewData?.type === 'Need'
                                      ) ? null : (
                                        <MapMarker
                                          androidUseDefaultIconImplementation={
                                            true
                                          }
                                          description={
                                            mapViewData?._material_types?.name
                                          }
                                          latitude={
                                            mapViewData?.coordinates?.data?.lat
                                          }
                                          longitude={
                                            mapViewData?.coordinates?.data?.lng
                                          }
                                          onPress={(latitude, longitude) => {
                                            try {
                                              setSelectedPost(mapViewData);
                                              setShow_selectedPost(true);
                                              console.log(mapViewData);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          pinImage={`${mapViewData?._material_types?.need_icon?.url}`}
                                          pinImageSize={80}
                                          title={mapViewData?.location?.toUpperCase()}
                                        >
                                          <MapCallout showTooltip={true}>
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 8,
                                                  maxWidth: 300,
                                                  overflow: 'hidden',
                                                  width: getCalloutWidth(
                                                    mapViewData?.location
                                                  ),
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Location */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Poppins_600SemiBold',
                                                      fontSize: 17,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {mapViewData?.location}
                                              </Text>
                                              {/* material */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      flex: 1,
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  mapViewData?._material_types
                                                    ?.name
                                                }
                                              </Text>
                                            </View>
                                          </MapCallout>
                                        </MapMarker>
                                      )}
                                    </>
                                  </>
                                );
                              }}
                              rotateEnabled={true}
                              scrollEnabled={true}
                              showsCompass={true}
                              showsPointsOfInterest={true}
                              showsUserLocation={true}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.MapViewStyles(theme)['Map View'],
                                dimensions.width
                              )}
                              zoom={zoomLevel}
                              zoomEnabled={true}
                            />
                            {/* List View */}
                            <View
                              style={StyleSheet.applyWidth(
                                { bottom: 40, position: 'absolute', right: 20 },
                                dimensions.width
                              )}
                            >
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setShow_Full_PostsView(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        theme.colors['Custom #ffffff'],
                                      borderColor: theme.colors['Light'],
                                      borderRadius: 25,
                                      borderWidth: 1,
                                      height: 50,
                                      justifyContent: 'center',
                                      width: 50,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={theme.colors['Primary Blue']}
                                    name={'Foundation/list-number'}
                                    size={24}
                                  />
                                </View>
                              </Pressable>
                            </View>
                          </View>
                          {/* Posts */}
                          <>
                            {!show_selectedPost ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors['Divider'],
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderTopWidth: 2,
                                    marginLeft: 1,
                                    marginRight: 1,
                                    paddingBottom: 15,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Full Posts View */}
                                  <Pressable
                                    activeOpacity={0.3}
                                    onPress={() => {
                                      try {
                                        setShow_Full_PostsView(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Light'],
                                          borderRadius: 100,
                                          height: 6,
                                          marginTop: 5,
                                          opacity: 0.65,
                                          width: 40,
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  </Pressable>
                                </View>
                                {/* post */}
                                <>
                                  {!selectedPost?.type ? null : (
                                    <Pressable
                                      activeOpacity={0.3}
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'PostDetailsScreen',
                                            { post_id: selectedPost?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            flexDirection: 'row',
                                            height: 125,
                                            justifyContent: 'space-between',
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flex: 1,
                                              marginLeft: 6,
                                              paddingLeft: 16,
                                              paddingRight: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Poppins_500Medium',
                                                  fontSize: 16,
                                                  lineHeight: 22,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {selectedPost?.type}{' '}
                                            {addCommasToNumber(
                                              selectedPost?.quantity
                                            )}
                                            {' CYD - '}
                                            {
                                              selectedPost?._material_types
                                                ?.name
                                            }
                                          </Text>

                                          <Text
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  fontSize: 15,
                                                  lineHeight: 24,
                                                  textTransform: 'capitalize',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {selectedPost?.location}
                                          </Text>

                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  fontSize: 15,
                                                  lineHeight: 24,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Available From: '}
                                            {changeDateFormat(
                                              selectedPost?.availability_date,
                                              Constants['AppDateformat']
                                            )}
                                          </Text>
                                          <>
                                            {!selectedPost?.expires_at ? null : (
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                      lineHeight: 24,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Available Until: '}
                                                {changeDateFormat(
                                                  selectedPost?.expires_at,
                                                  Constants['AppDateformat']
                                                )}
                                              </Text>
                                            )}
                                          </>
                                          <>
                                            {selectedPost?.expires_at ? null : (
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 15,
                                                      lineHeight: 24,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'Available Until: Always'}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Feather/chevron-right'}
                                          size={24}
                                        />
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                                {/* profile card */}
                                <>
                                  {selectedPost?.type ? null : (
                                    <Pressable
                                      activeOpacity={0.3}
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            { profile_id: selectedPost?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* Profile */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            backgroundColor:
                                              theme.colors['Custom #ffffff'],
                                            borderColor:
                                              theme.colors['Divider'],
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            marginTop: 20,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingTop: 20,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!selectedPost?.profile_image ? null : (
                                            <Image
                                              resizeMode={'cover'}
                                              source={{
                                                uri: `${selectedPost?.profile_image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderRadius: 8,
                                                    height: 100,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {selectedPost?.profile_image ? null : (
                                            <Image
                                              resizeMode={'cover'}
                                              source={{
                                                uri: `${selectedPost?._user_types?.icon_path}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  { height: 100 }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { marginLeft: 20, paddingTop: 5 },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Name */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Poppins_500Medium',
                                                  fontSize: 21,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {null}
                                            {/* Name */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Poppins_500Medium',
                                                    fontSize: 21,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {selectedPost?.profile_name}
                                            </Text>
                                          </Text>
                                          {/* Profile Type */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  fontSize: 16,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {selectedPost?._user_types?.name}
                                          </Text>

                                          <Text
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors['ShopAppBlue'],
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  paddingTop: 10,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'See Profile'}
                                          </Text>
                                        </View>
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                          {/* Full Posts List View */}
                          <>
                            {!show_Full_PostsView ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      theme.colors['Custom #ffffff'],
                                    bottom: 0,
                                    flex: 1,
                                    left: 0,
                                    position: 'absolute',
                                    right: 0,
                                    top: -1,
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!PostsList ? null : (
                                    <FlatList
                                      contentContainerStyle={StyleSheet.applyWidth(
                                        { paddingBottom: 20 },
                                        dimensions.width
                                      )}
                                      data={PostsList}
                                      keyExtractor={(listData, index) =>
                                        listData?.id ??
                                        listData?.uuid ??
                                        index.toString()
                                      }
                                      listKey={'SMsPjD4i'}
                                      numColumns={1}
                                      onEndReachedThreshold={0.5}
                                      renderItem={({ item, index }) => {
                                        const listData = item;
                                        return (
                                          <>
                                            {listData?.profile_name ? null : (
                                              <Pressable
                                                activeOpacity={0.3}
                                                onPress={() => {
                                                  try {
                                                    navigation.navigate(
                                                      'PostDetailsScreen',
                                                      { post_id: listData?.id }
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                              >
                                                {/* View 2 */}
                                                <>
                                                  {!listData?.type ? null : (
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* colorCode */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.ViewStyles(
                                                            theme
                                                          )['colorCode'],
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <>
                                                          {!(
                                                            listData?.type ===
                                                            'Need'
                                                          ) ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    theme
                                                                      .colors[
                                                                      'Primary Yellow'
                                                                    ],
                                                                  height:
                                                                    '100%',
                                                                  width: 15,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                        <>
                                                          {!(
                                                            listData?.type ===
                                                            'Have'
                                                          ) ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  backgroundColor:
                                                                    theme
                                                                      .colors[
                                                                      'Primary Blue'
                                                                    ],
                                                                  height:
                                                                    '100%',
                                                                  width: 15,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                      </View>

                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            borderTopLeftRadius: 8,
                                                            borderTopRightRadius: 8,
                                                            flex: 1,
                                                            flexDirection:
                                                              'row',
                                                            height: 118,
                                                            justifyContent:
                                                              'space-between',
                                                            paddingLeft: 6,
                                                            paddingRight: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              flex: 1,
                                                              marginLeft: 6,
                                                              paddingLeft: 16,
                                                              paddingRight: 16,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )['Text'],
                                                                {
                                                                  fontFamily:
                                                                    'Poppins_500Medium',
                                                                  fontSize: 16,
                                                                  lineHeight: 22,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listData?.type}{' '}
                                                            {addCommasToNumber(
                                                              listData?.quantity
                                                            )}
                                                            {' CYD - '}
                                                            {
                                                              listData
                                                                ?._material_types
                                                                ?.name
                                                            }
                                                          </Text>

                                                          <Text
                                                            numberOfLines={1}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )['Text'],
                                                                {
                                                                  fontFamily:
                                                                    'Poppins_400Regular',
                                                                  fontSize: 15,
                                                                  lineHeight: 24,
                                                                  textTransform:
                                                                    'capitalize',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listData?.location}
                                                          </Text>

                                                          <Text
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )['Text'],
                                                                {
                                                                  fontFamily:
                                                                    'Poppins_400Regular',
                                                                  fontSize: 15,
                                                                  lineHeight: 24,
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'Available From: '}
                                                            {changeDateFormat(
                                                              listData?.availability_date,
                                                              Constants[
                                                                'AppDateformat'
                                                              ]
                                                            )}
                                                          </Text>
                                                          <>
                                                            {!listData?.expires_at ? null : (
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )['Text'],
                                                                    {
                                                                      fontFamily:
                                                                        'Poppins_400Regular',
                                                                      fontSize: 15,
                                                                      lineHeight: 24,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  'Available Until: '
                                                                }
                                                                {changeDateFormat(
                                                                  listData?.expires_at,
                                                                  Constants[
                                                                    'AppDateformat'
                                                                  ]
                                                                )}
                                                              </Text>
                                                            )}
                                                          </>
                                                          <>
                                                            {listData?.expires_at ? null : (
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )['Text'],
                                                                    {
                                                                      fontFamily:
                                                                        'Poppins_400Regular',
                                                                      fontSize: 15,
                                                                      lineHeight: 24,
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  'Available Until: Always'
                                                                }
                                                              </Text>
                                                            )}
                                                          </>
                                                        </View>
                                                        <Icon
                                                          color={
                                                            theme.colors[
                                                              'Primary'
                                                            ]
                                                          }
                                                          name={
                                                            'Feather/chevron-right'
                                                          }
                                                          size={24}
                                                        />
                                                      </View>
                                                    </View>
                                                  )}
                                                </>
                                              </Pressable>
                                            )}
                                          </>
                                        );
                                      }}
                                      showsHorizontalScrollIndicator={true}
                                      showsVerticalScrollIndicator={true}
                                    />
                                  )}
                                </>
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Custom #ffffff'],
                                      bottom: 40,
                                      position: 'absolute',
                                      right: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Mapview */}
                                  <Pressable
                                    activeOpacity={0.3}
                                    onPress={() => {
                                      try {
                                        setShow_Full_PostsView(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            theme.colors['Custom #ffffff'],
                                          borderColor: theme.colors['Light'],
                                          borderRadius: 25,
                                          borderWidth: 1,
                                          height: 50,
                                          justifyContent: 'center',
                                          width: 50,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Primary Blue']}
                                        name={'Ionicons/md-map-outline'}
                                        size={24}
                                      />
                                    </View>
                                  </Pressable>
                                </View>
                              </View>
                            )}
                          </>
                        </View>
                      </>
                    );
                  }}
                </XanoApi.FetchHomePOST>
              )}
            </>
            <>
              {my_latitude ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'large'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ],
                      dimensions.width
                    )}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 21,
                          marginTop: 12,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Please Wait\nFetching personalized data...'}
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Bottom Tabbar */}
      <>
        {!isOnline ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom #ffffff'],
                borderColor: theme.colors['Divider'],
                borderTopWidth: 1,
                flexDirection: 'row',
                height: 55,
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'space-between' },
                  { minWidth: Breakpoints.Tablet, value: 'space-between' },
                ],
                paddingLeft: { minWidth: Breakpoints.Tablet, value: '20%' },
                paddingRight: { minWidth: Breakpoints.Tablet, value: '20%' },
                paddingTop: 6,
              },
              dimensions.width
            )}
          >
            <Pressable
              activeOpacity={0.3}
              style={StyleSheet.applyWidth(
                { height: 49, width: '19%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: 49 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.HomeSelected}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 20, marginTop: 4, width: 25 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  allowFontScaling={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                    dimensions.width
                  )}
                >
                  {'Home'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyPostsScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: 49, width: '19%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: 49 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MyPosts}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 20, marginTop: 4, width: 25 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  allowFontScaling={false}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                      { color: theme.colors['Primary'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Posts'}
                </Text>
                {/* Badge */}
                <>
                  {!Constants['matched_posts_count'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Custom Color_29'],
                          borderRadius: 9,
                          height: 18,
                          justifyContent: 'center',
                          position: 'absolute',
                          right: 15,
                          width: 18,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Custom #ffffff'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['matched_posts_count']}
                      </Text>
                    </View>
                  )}
                </>
              </View>
            </Pressable>

            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('CreatePostScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: 49, marginTop: -58, width: '24%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderColor: theme.colors['Divider'],
                    borderTopLeftRadius: 45,
                    borderTopRightRadius: 45,
                    borderTopWidth: 1,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                <Circle bgColor={theme.colors['Custom Color_25']} size={40}>
                  <Icon
                    color={theme.colors['Custom #ffffff']}
                    name={'Entypo/plus'}
                    size={24}
                  />
                </Circle>

                <Text
                  allowFontScaling={false}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                      { color: theme.colors['Primary'], marginTop: 7 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Create Post'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'FavouritesScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: 49, width: '19%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: 49 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.Favorites}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 20, marginTop: 4, width: 25 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  allowFontScaling={false}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                      { color: theme.colors['Primary'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'Favorites'}
                </Text>
              </View>
            </Pressable>

            <Pressable
              activeOpacity={0.3}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: 49, width: '19%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: 49 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.MyProfile}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 20, marginTop: 4, width: 25 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  allowFontScaling={false}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Bottom Tab Text'],
                      { color: theme.colors['Primary'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'My Profile'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      {/* All Filters Modal */}
      <>
        {!show_all_filters ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'pageSheet'}
            transparent={true}
          >
            {/* Black BG View */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_18'],
                  bottom: 0,
                  left: 0,
                  opacity: 0.5,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'stretch',
                  borderRadius: 12,
                  flex: 1,
                  justifyContent: 'flex-end',
                  marginTop: 40,
                  overflow: 'hidden',
                },
                dimensions.width
              )}
            >
              <ScrollView
                bounces={true}
                contentContainerStyle={StyleSheet.applyWidth(
                  { justifyContent: 'flex-end' },
                  dimensions.width
                )}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'stretch',
                      backgroundColor: theme.colors['Custom #ffffff'],
                      borderRadius: 10,
                      overflow: 'hidden',
                      paddingBottom: 30,
                    },
                    dimensions.width
                  )}
                >
                  {/* Handle View */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Primary'],
                          borderRadius: 100,
                          height: 6,
                          opacity: 0.3,
                          width: 60,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Header */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 50,
                        justifyContent: 'space-between',
                        marginBottom: 12,
                        paddingBottom: 12,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Cancel */}
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_all_filters(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 48,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary'],
                                fontFamily: 'Poppins_400Regular',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Cancel'}
                        </Text>
                      </View>
                    </Pressable>
                    {/* heading */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            lineHeight: 48,
                            marginRight: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Filters'}
                    </Text>
                    {/* Save */}
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_all_filters(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 48,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary'],
                                fontFamily: 'Poppins_400Regular',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Save'}
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                  {/* Material */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 135 },
                      dimensions.width
                    )}
                  >
                    <FlatList
                      data={Static_Materials}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'7LrQblva'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                if (listData?.isSelected) {
                                  if (selected_material_Ids?.length < 2) {
                                    return;
                                  }
                                }
                                const result = toggleStaticMaterial(
                                  listData?.value
                                );
                                setStatic_Materials(result);
                                const Selectedmaterial =
                                  selected_MaterialType_Ids(Static_Materials);
                                setSelected_material_Ids(
                                  (() => {
                                    const e = Selectedmaterial;
                                    console.log(e);
                                    return e;
                                  })()
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Custom #ffffff'],
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Divider'],
                                  flexDirection: 'row',
                                  height: 45,
                                  justifyContent: 'flex-start',
                                  paddingLeft: 18,
                                  width: 248,
                                },
                                dimensions.width
                              )}
                            >
                              {/* selected */}
                              <>
                                {!listData?.isSelected ? null : (
                                  <Icon
                                    color={theme.colors['Primary Blue']}
                                    name={'AntDesign/checkcircle'}
                                    size={22}
                                  />
                                )}
                              </>
                              {/* unselected */}
                              <>
                                {listData?.isSelected ? null : (
                                  <Icon
                                    color={theme.colors['Primary Blue']}
                                    name={'Ionicons/radio-button-off-sharp'}
                                    size={24}
                                  />
                                )}
                              </>
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.label}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </View>
                  {/* Distance */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 15 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            lineHeight: 35,
                            marginLeft: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Distance'}
                    </Text>
                    {/* Distance  */}
                    <RadioButtonGroup
                      onValueChange={newDistanceValue => {
                        try {
                          setDistance(newDistanceValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                      value={distance}
                    >
                      <FlatList
                        contentContainerStyle={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Custom #ffffff'],
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                        data={distance_filter}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        listKey={'sa74KgcK'}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <Pressable
                              activeOpacity={0.3}
                              onPress={() => {
                                try {
                                  setShow_Filters(false);
                                  if (listData !== '>100') {
                                    setDistance(listData);
                                  }
                                  if (listData === '>100') {
                                    setDistance(0);
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors['Divider'],
                                    flexDirection: 'row',
                                    height: 45,
                                    justifyContent: 'flex-start',
                                    paddingLeft: 18,
                                  },
                                  dimensions.width
                                )}
                              >
                                <RadioButton
                                  color={theme.colors['Primary Blue']}
                                  selectedIcon={'AntDesign/checkcircle'}
                                  size={24}
                                  unselectedColor={theme.colors['Primary']}
                                  unselectedIcon={'Entypo/circle'}
                                  value={listData}
                                />
                                <>
                                  {!(listData !== '0') ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Primary Blue'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 18,
                                            marginLeft: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData}
                                      {' miles'}
                                    </Text>
                                  )}
                                </>
                                <>
                                  {!(listData === '0') ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Primary Blue'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 18,
                                            marginLeft: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'>100 miles'}
                                    </Text>
                                  )}
                                </>
                              </View>
                            </Pressable>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </RadioButtonGroup>
                  </View>
                  {/* Pricing */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, marginTop: 15 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            lineHeight: 35,
                            marginLeft: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Pricing'}
                    </Text>
                    {/* Pricing  */}
                    <RadioButtonGroup
                      onValueChange={newPricingValue => {
                        try {
                          setPrice_type(newPricingValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                      value={price_type}
                    >
                      <FlatList
                        contentContainerStyle={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Custom #ffffff'],
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                        data={price_Pref_Array}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        listKey={'UPuF656m'}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <Pressable
                              activeOpacity={0.3}
                              onPress={() => {
                                try {
                                  if (listData?.isSelected) {
                                    if (selected_price_types?.length < 2) {
                                      return;
                                    }
                                  }
                                  const result = toggle_Price_Preferences(
                                    listData?.value
                                  );
                                  setPrice_Pref_Array(result);
                                  const selected_price_results =
                                    selected_price_prefs(result);
                                  setSelected_price_types(
                                    selected_price_results
                                  );
                                  setShow_Filters(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors['Divider'],
                                    flexDirection: 'row',
                                    height: 45,
                                    justifyContent: 'flex-start',
                                    paddingLeft: 18,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* selected */}
                                <>
                                  {!listData?.isSelected ? null : (
                                    <Icon
                                      color={theme.colors['Primary Blue']}
                                      name={'AntDesign/checkcircle'}
                                      size={22}
                                    />
                                  )}
                                </>
                                {/* unselcted */}
                                <>
                                  {listData?.isSelected ? null : (
                                    <Icon
                                      color={theme.colors['Primary Blue']}
                                      name={'Ionicons/radio-button-off'}
                                      size={24}
                                    />
                                  )}
                                </>
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary Blue'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 18,
                                        marginLeft: 18,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.label}
                                </Text>
                              </View>
                            </Pressable>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </RadioButtonGroup>
                  </View>
                  {/* Company type */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 15 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            lineHeight: 35,
                            marginLeft: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Company type'}
                    </Text>
                    {/* Company Type  */}
                    <RadioButtonGroup
                      onValueChange={newCompanyTypeValue => {
                        try {
                          undefined;
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                      value={selected_CompanyType}
                    >
                      <FlatList
                        contentContainerStyle={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Custom #ffffff'],
                            overflow: 'hidden',
                          },
                          dimensions.width
                        )}
                        data={filter_byUsers}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        listKey={'CkLDm7DD'}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <Pressable
                              activeOpacity={0.3}
                              onPress={() => {
                                try {
                                  setShow_Filters(false);
                                  const result = toggleUserTypes(
                                    listData?.value
                                  );
                                  const result2 =
                                    selected_UserTypes_Ids(result);
                                  console.log(result2);
                                  setSelected_UserTypes(result2);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors['Divider'],
                                    flexDirection: 'row',
                                    height: 45,
                                    justifyContent: 'flex-start',
                                    paddingLeft: 18,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* selected */}
                                <>
                                  {!listData?.isSelected ? null : (
                                    <Icon
                                      color={theme.colors['Primary Blue']}
                                      name={'AntDesign/checkcircle'}
                                      size={22}
                                    />
                                  )}
                                </>
                                {/* unselected */}
                                <>
                                  {listData?.isSelected ? null : (
                                    <Icon
                                      color={theme.colors['Primary Blue']}
                                      name={'Ionicons/radio-button-off'}
                                      size={24}
                                    />
                                  )}
                                </>
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary Blue'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 18,
                                        marginLeft: 18,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.label}
                                </Text>
                              </View>
                            </Pressable>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </RadioButtonGroup>
                  </View>
                </View>
              </ScrollView>
            </View>
          </Modal>
        )}
      </>
      {/* Info modal */}
      <>
        {!show_info_Modal ? null : (
          <Modal animationType={'none'} transparent={true}>
            {/* Opaque BG */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Strong'],
                  bottom: 0,
                  flex: 1,
                  height: '100%',
                  left: 0,
                  opacity: 0.7,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: '100%',
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Click to dismiss */}
              <Button
                onPress={() => {
                  try {
                    setShow_info_Modal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      bottom: 0,
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
                title={''}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom #ffffff'],
                    borderRadius: 10,
                    margin: 30,
                    padding: 8,
                    paddingTop: 0,
                  },
                  dimensions.width
                )}
              >
                {/* Header */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-end' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { marginRight: -22, marginTop: -22 },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_info_Modal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Primary Blue'],
                            borderColor: theme.colors['Custom #ffffff'],
                            borderRadius: 20,
                            borderWidth: 1,
                            height: 40,
                            justifyContent: 'center',
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Custom #ffffff']}
                          name={'Ionicons/md-close-sharp'}
                          size={24}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 10 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Shovel}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['infoImgs'],
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {'=    Dirt'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Grass}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['infoImgs'],
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {'=    Topsoil'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Paving}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['infoImgs'],
                          { height: 45, width: 45 }
                        ),
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {'  =    Stone/Gravel'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.DumpTruck}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['infoImgs'],
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {'=    Truckers'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.Recycle}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ImageStyles(theme)['infoImgs'],
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {'=    Quarry/Dump \n       Sites'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Primary Blue']}
                      name={'Ionicons/ios-notifications-outline'}
                      size={50}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {' =    Notifications'}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: 20,
                        paddingTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Primary Blue']}
                      name={'Ionicons/md-chatbubble-outline'}
                      size={50}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['info_texts'],
                        dimensions.width
                      )}
                    >
                      {' =    Messaging'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Location Modal */}
      <>
        {!show_location_modal ? null : (
          <Modal animationType={'none'}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Divider'],
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 12,
                  marginRight: 12,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 50,
                },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary Blue'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'Change Location'}
              </Text>

              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_location_modal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary Blue'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                          marginRight: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Close'}
                  </Text>
                  <Icon
                    color={theme.colors['Primary Blue']}
                    name={'Ionicons/md-close'}
                    size={24}
                  />
                </View>
              </Pressable>
            </View>

            <View
              style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
            >
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={100}
                onChangeText={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newTextInputValue => {
                  try {
                    setLocation_search_text(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Search a location'}
                placeholderTextColor={theme.colors['Light']}
                ref={textInputeEM1u0QnRef}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'],
                    {
                      borderColor: theme.colors['Divider'],
                      borderWidth: 1,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 16,
                      marginBottom: 15,
                      paddingLeft: 16,
                    }
                  ),
                  dimensions.width
                )}
                value={location_search_text}
              />
              <XanoGooglePlacesApi.FetchSearchGET
                handlers={{
                  onData: fetchData => {
                    try {
                      textInputeEM1u0QnRef.current.focus();
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                lat={my_latitude}
                lng={my_longitude}
                search={location_search_text}
              >
                {({ loading, error, data, refetchSearch }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <FlatList
                      data={fetchData?.predictions}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'always'}
                      listKey={'9Lbxbcr1'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const coords = (
                                    await XanoGooglePlacesApi.getCoordinatesGET(
                                      Constants,
                                      { place_id: listData?.place_id }
                                    )
                                  )?.json;
                                  setMyLocation(listData?.description);
                                  setMy_latitude(
                                    coords?.result?.geometry?.location?.lat
                                  );
                                  setMy_longitude(
                                    coords?.result?.geometry?.location?.lng
                                  );
                                  setShow_location_modal(false);
                                  setZoomLevel(10);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  paddingBottom: 10,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Entypo/location-pin'}
                                size={24}
                              />
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary Blue'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 15,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.description}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XanoGooglePlacesApi.FetchSearchGET>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
