import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const ChangePasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [confirm_new_password, setConfirm_new_password] = React.useState('');
  const [error_msg, setError_msg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [new_password, setNew_password] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const inputValidation = () => {
    let foundError = false;

    if (password.length < 1) {
      setError_msg('Password Fields cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (new_password.length < 1) {
      setError_msg('Password Fields cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    if (confirm_new_password.length < 1) {
      setError_msg('Password Fields cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setError_msg('');
    }

    return foundError;
  };

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      {/* screen header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Top_Header'], {
            borderBottomWidth: 1,
            borderColor: theme.colors['Divider'],
          }),
          dimensions.width
        )}
      >
        {/* Back */}
        <Pressable
          activeOpacity={0.3}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/chevron-back-outline'}
              size={24}
            />
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  lineHeight: 48,
                  marginLeft: 2,
                }),
                dimensions.width
              )}
            >
              {'Back'}
            </Text>
          </View>
        </Pressable>
        {/* screen heading */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['Screen heading Phone'],
              { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
            ),
            dimensions.width
          )}
        >
          {'Reset Password'}
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            padding: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: { minWidth: Breakpoints.Laptop, value: '5%' },
          },
          dimensions.width
        )}
      >
        {/* Current Password */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Enter Your Current Password'}
          </Text>
          {/* password hidden */}
          <>
            {showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setPassword(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Current Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShowPassword(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-off-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
          {/* password visible */}
          <>
            {!showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setPassword(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Current Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShowPassword(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
        </View>
        {/* New Password */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Enter Your New Password'}
          </Text>
          {/* password hidden */}
          <>
            {showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setNew_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'New Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShowPassword(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-off-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
          {/* password visible */}
          <>
            {!showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setNew_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'New Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        setShowPassword(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
        </View>
        {/* Confirm New Password */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
        >
          <Text
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['detail_heading'],
              dimensions.width
            )}
          >
            {'Re-Enter Your New Password'}
          </Text>
          {/* password hidden */}
          <>
            {showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setConfirm_new_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Confirm New Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={confirm_new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        setShowPassword(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-off-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
          {/* password visible */}
          <>
            {!showPassword ? null : (
              <View
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Bigscreen_Input_BG'],
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  onChangeText={newTextInputValue => {
                    try {
                      setConfirm_new_password(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Confirm New Password'}
                  placeholderTextColor={theme.colors['Primary']}
                  secureTextEntry={false}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Input Phone'],
                    dimensions.width
                  )}
                  value={confirm_new_password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', right: 10 },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      try {
                        setShowPassword(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/eye-outline'}
                        size={24}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </>
        </View>
        {/* error_msg */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Error'],
              fontFamily: 'Poppins_400Regular',
              marginTop: 16,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {error_msg}
        </Text>
        {/* Save */}
        <Button
          loading={isLoading}
          onPress={() => {
            const handler = async () => {
              try {
                const foundError = inputValidation();
                if (foundError) {
                  return;
                }
                if (new_password !== confirm_new_password) {
                  setError_msg("New passwords doesn't match");
                }
                if (new_password !== confirm_new_password) {
                  return;
                }
                setError_msg('');
                setIsLoading(true);
                const result = (
                  await XanoApi.changePasswordPOST(Constants, {
                    existing_password: password,
                    new_password: new_password,
                  })
                )?.json;
                setIsLoading(false);
                setSuccess(result?.success);
                setError_msg(result?.message);
                if (!result?.status) {
                  return;
                }
                setPassword('');
                setNew_password('');
                setConfirm_new_password('');

                showAlertUtil({
                  title: 'Congratulations',
                  message: 'Your password has been changed successfully.',
                  buttonText: 'Ok',
                });

                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ButtonStyles(theme)['Action Btn Bigscreen'],
            dimensions.width
          )}
          title={'Save'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ChangePasswordScreen);
