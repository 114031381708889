import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as XanoGooglePlacesApi from '../apis/XanoGooglePlacesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CountryCodesPicker from '../custom-files/CountryCodesPicker';
import * as CustomCode from '../custom-files/CustomCode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openCameraUtil from '../utils/openCamera';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Checkbox,
  Icon,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  SwitchRow,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const SignupScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [all_companyTypes, setAll_companyTypes] = React.useState([]);
  const [business_type, setBusiness_type] = React.useState('');
  const [call, setCall] = React.useState(true);
  const [chat, setChat] = React.useState(true);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [confirm_password, setConfirm_password] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1');
  const [device_type, setDevice_type] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [err_msg, setErr_msg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [last_name, setLast_name] = React.useState('');
  const [location_searchString, setLocation_searchString] = React.useState('');
  const [mail, setMail] = React.useState(true);
  const [myLocation, setMyLocation] = React.useState('');
  const [my_latitude, setMy_latitude] = React.useState('');
  const [my_longitude, setMy_longitude] = React.useState('');
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [phone_number, setPhone_number] = React.useState('');
  const [place_id, setPlace_id] = React.useState('');
  const [profile_image, setProfile_image] = React.useState('');
  const [referral_details, setReferral_details] = React.useState('');
  const [referral_modal, setReferral_modal] = React.useState(false);
  const [selected_companyType, setSelected_companyType] = React.useState('');
  const [selected_usertype_id, setSelected_usertype_id] = React.useState('');
  const [showPicker, setShowPicker] = React.useState('');
  const [
    show_business_classification_modal,
    setShow_business_classification_modal,
  ] = React.useState(false);
  const [show_companyTypeModal, setShow_companyTypeModal] =
    React.useState(false);
  const [show_form, setShow_form] = React.useState(true);
  const [show_image_picker, setShow_image_picker] = React.useState(false);
  const [show_location_modal, setShow_location_modal] = React.useState(false);
  const [show_password, setShow_password] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const inputValidation = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;

    let foundError = false;

    if (name.length < 1) {
      setErr_msg('First name cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (last_name.length < 1) {
      setErr_msg('Last name cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (email.length < 1) {
      setErr_msg('Email cannot be empty');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (!expr.test(email)) {
      setErr_msg('Please enter a valid email');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };

  const profile_Inputs_Validation = () => {
    const expr =
      /^([\w-\.]+)@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;

    let foundError = false;

    if (phone_number.length != 10) {
      setErr_msg('Please enter your correct phone number');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    if (selected_companyType.length < 1) {
      setErr_msg('Please select your Profile type');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    // if (business_type.length < 1) {
    //     setErr_msg("Please select a Business classification")
    //     foundError = true;
    //     return foundError;

    // } else {
    //     setErr_msg("")
    // }

    if (myLocation.length < 1) {
      setErr_msg('missing location');
      foundError = true;
      return foundError;
    } else {
      setErr_msg('');
    }

    return foundError;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setErr_msg('');
        const result = (await XanoApi.userTypesGET(Constants))?.json;
        setAll_companyTypes(result);
        if (Platform.OS === 'ios') {
          setDevice_type('iOS');
        }
        if (Platform.OS === 'android') {
          setDevice_type('Android');
        }
        if (Platform.OS === 'web') {
          setDevice_type('Web');
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth({ paddingBottom: 20 }, dimensions.width)}
    >
      <Utils.CustomCodeErrorBoundary>
        <CountryCodesPicker.Index
          theme={theme}
          setCountryCode={setCountryCode}
          show={showPicker}
          setShow={setShowPicker}
        />
      </Utils.CustomCodeErrorBoundary>
      {/* Form */}
      <>
        {!show_form ? null : (
          <View>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Top_Header'],
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Divider'],
                    height: 48,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Back */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Feather/chevron-left'}
                    size={24}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          lineHeight: 48,
                          marginLeft: 2,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Back'}
                  </Text>
                </View>
              </Pressable>
              {/* screen heading */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                    { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
                  ),
                  dimensions.width
                )}
              >
                {'Sign up'}
              </Text>
            </View>

            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  padding: { minWidth: Breakpoints.Tablet, value: '15%' },
                  paddingTop: { minWidth: Breakpoints.Tablet, value: '5%' },
                },
                dimensions.width
              )}
              keyboardShouldPersistTaps={'always'}
              showsVerticalScrollIndicator={true}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingTop: 24 },
                  dimensions.width
                )}
              >
                {/* Sign up form */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 8, paddingLeft: 24, paddingRight: 24 },
                    dimensions.width
                  )}
                >
                  {/* top logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['top logo'],
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={Images.DirtNearMe}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          { height: 166, width: 166 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* First Name */}
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'First Name*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          { height: 44 }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'words'}
                        keyboardType={'default'}
                        onChangeText={newTextInputValue => {
                          try {
                            setName(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Input your first name'}
                        placeholderTextColor={theme.colors['Primary']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)[
                              'Text Input Phone'
                            ],
                            { textAlign: 'auto' }
                          ),
                          dimensions.width
                        )}
                        textContentType={'name'}
                        value={name}
                      />
                    </View>
                  </View>
                  {/* Last Name */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Last Name*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          { height: 44 }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'words'}
                        keyboardType={'default'}
                        onChangeText={newTextInputValue => {
                          try {
                            setLast_name(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Input your last name'}
                        placeholderTextColor={theme.colors['Primary']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Text Input Phone'
                          ],
                          dimensions.width
                        )}
                        textContentType={'familyName'}
                        value={last_name}
                      />
                    </View>
                  </View>
                  {/* Email */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['detail_heading'],
                        dimensions.width
                      )}
                    >
                      {'Email*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          { height: 44 }
                        ),
                        dimensions.width
                      )}
                    >
                      <TextInput
                        autoCapitalize={'none'}
                        keyboardType={'email-address'}
                        onChangeText={newTextInputValue => {
                          try {
                            setEmail(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Input an Email'}
                        placeholderTextColor={theme.colors['Primary']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextInputStyles(theme)[
                            'Text Input Phone'
                          ],
                          dimensions.width
                        )}
                        textContentType={'emailAddress'}
                        value={email}
                      />
                    </View>
                  </View>
                  {/* Terms and Conditions */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        height: 30,
                        justifyContent: 'center',
                        marginTop: 16,
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      checkedIcon={'AntDesign/checkcircle'}
                      color={theme.colors['Primary Blue']}
                      onPress={newCheckboxValue => {
                        const checkboxValue = newCheckboxValue;
                        try {
                          setCheckboxValue(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      status={checkboxValue}
                      uncheckedColor={theme.colors['Primary Blue']}
                      uncheckedIcon={'Ionicons/radio-button-off-sharp'}
                    />
                    {/* Text  */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Poppins_300Light',
                            fontSize: 14,
                            lineHeight: 22,
                            textAlign: 'center',
                            textDecorationLine: 'none',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'  I accept  '}
                    </Text>

                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          navigation.navigate('AboutScreensScreen', {
                            srceen_heading: 'Terms & Conditions',
                            web_URL:
                              'https://www.dirtnearme.com/app-terms-&-conditions',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Text  */}
                      <Text
                        numberOfLines={2}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_300Light',
                              fontSize: 14,
                              lineHeight: 22,
                              textAlign: 'center',
                              textDecorationLine: 'underline',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Terms and Conditions'}
                      </Text>
                    </Pressable>
                  </View>
                  {/* err message */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 15,
                          marginTop: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {err_msg}
                  </Text>
                  {/* Next */}
                  <Button
                    disabled={!checkboxValue}
                    onPress={() => {
                      try {
                        const found_Error = inputValidation();
                        if (!found_Error) {
                          setShow_form(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)[
                          'Action Btn Bigscreen'
                        ],
                        { fontSize: 18 }
                      ),
                      dimensions.width
                    )}
                    title={'Next'}
                  />
                  {/* Log in */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 16,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            lineHeight: 22,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Already have an account? '}
                    </Text>

                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          navigation.navigate('LoginScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Text 2 */}
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                              lineHeight: 22,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Log in'}
                      </Text>
                    </Pressable>
                  </View>
                </View>
                {/* Social Sign ups */}
                <View
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['SocialLogins'],
                    dimensions.width
                  )}
                >
                  {/* section header */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Divider */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Light'],
                          borderTopWidth: 1,
                          flex: 1,
                          height: 1,
                        },
                        dimensions.width
                      )}
                    />
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Or Sign Up with'}
                    </Text>
                    {/* Divider */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors['Light'],
                          borderTopWidth: 1,
                          flex: 1,
                          height: 1,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* GoogleAuth */}
                  <Utils.CustomCodeErrorBoundary>
                    <AuthComponents.GoogleWrapper
                      navigation={props.navigation}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      setFirst_name={setName}
                      setLast_name={setLast_name}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: theme.colors['Primary Blue'],
                            borderRadius: 8,
                            borderWidth: 1,
                            flexDirection: 'row',
                            height: 44,
                            justifyContent: 'center',
                            marginBottom: 8,
                            marginTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Primary Blue']}
                          name={'AntDesign/google'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginRight: 6 },
                            dimensions.width
                          )}
                        />
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                lineHeight: 22,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Continue with Google'}
                        </Text>
                      </View>
                    </AuthComponents.GoogleWrapper>
                  </Utils.CustomCodeErrorBoundary>
                  {/* AppleAuth */}
                  <Utils.CustomCodeErrorBoundary>
                    <AuthComponents.AppleWrapper navigation={props.navigation}>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: theme.colors['Primary Blue'],
                            borderRadius: 8,
                            borderWidth: 1,
                            flexDirection: 'row',
                            height: 44,
                            justifyContent: 'center',
                            marginBottom: 8,
                            marginTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Primary Blue']}
                          name={'AntDesign/apple1'}
                          size={24}
                          style={StyleSheet.applyWidth(
                            { marginRight: 6 },
                            dimensions.width
                          )}
                        />
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                lineHeight: 22,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Continue with Apple'}
                        </Text>
                      </View>
                    </AuthComponents.AppleWrapper>
                  </Utils.CustomCodeErrorBoundary>
                </View>
              </View>
            </KeyboardAwareScrollView>
          </View>
        )}
      </>
      {/* profile */}
      <>
        {show_form ? null : (
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Top_Header'],
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Divider'],
                    height: 48,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Back */}
              <Pressable
                activeOpacity={0.3}
                onPress={() => {
                  try {
                    setShow_form(true);
                    setErr_msg('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['back_btn_Phone'],
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Feather/chevron-left'}
                    size={24}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          lineHeight: 48,
                          marginLeft: 2,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Back'}
                  </Text>
                </View>
              </Pressable>
              {/* screen heading */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Screen heading Phone'],
                    { fontFamily: 'Poppins_600SemiBold', marginRight: 65 }
                  ),
                  dimensions.width
                )}
              >
                {'1  of 2'}
              </Text>
            </View>

            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  padding: { minWidth: Breakpoints.Tablet, value: '15%' },
                  paddingTop: { minWidth: Breakpoints.Tablet, value: 0 },
                },
                dimensions.width
              )}
              keyboardShouldPersistTaps={'always'}
              showsVerticalScrollIndicator={true}
            >
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, paddingTop: 24 },
                  dimensions.width
                )}
              >
                {/* Complete Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 1,
                      paddingBottom: 8,
                      paddingLeft: 24,
                      paddingRight: 24,
                    },
                    dimensions.width
                  )}
                >
                  {/* Section heading */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 25,
                          lineHeight: 33,
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: '10%',
                          },
                          textAlign: [
                            { minWidth: Breakpoints.Tablet, value: 'center' },
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Complete your profile'}
                  </Text>
                  {/* Profile Image */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_image_picker(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <>
                        {profile_image?.length ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={Images.ProfileImage}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 166, width: 166 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <>
                        {!profile_image?.length ? null : (
                          <Image
                            resizeMode={'cover'}
                            source={{ uri: `${profile_image}` }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { height: 166, width: 166 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </Pressable>
                  </View>
                  {/* Phone Number */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Phone Number*'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            height: 44,
                            justifyContent: 'flex-start',
                            paddingLeft: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Pressable
                        activeOpacity={0.3}
                        disabled={true}
                        onPress={() => {
                          try {
                            setShowPicker(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {countryCode}
                        </Text>
                      </Pressable>

                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <TextInput
                          autoCapitalize={'none'}
                          keyboardType={'phone-pad'}
                          onChangeText={newTextInputValue => {
                            try {
                              if (newTextInputValue?.length < 11) {
                                setPhone_number(newTextInputValue);
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Input your Phone number'}
                          placeholderTextColor={theme.colors['Primary']}
                          secureTextEntry={false}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextInputStyles(theme)[
                              'Text Input Phone'
                            ],
                            dimensions.width
                          )}
                          value={phone_number}
                        />
                      </View>
                    </View>
                  </View>
                  {/* Profile Type */}
                  <Pressable
                    activeOpacity={0.3}
                    disabled={false}
                    onPress={() => {
                      try {
                        setShow_companyTypeModal(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Profile Type */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Profile Type*'}
                      </Text>

                      <View
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                            { height: 44 }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Placeholder */}
                        <>
                          {selected_companyType ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Light'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Select Profile type'}
                            </Text>
                          )}
                        </>
                        {/* Selected Value */}
                        <>
                          {!selected_companyType ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {selected_companyType}
                            </Text>
                          )}
                        </>
                      </View>
                    </View>
                  </Pressable>
                  {/* Business Classfication */}
                  <Pressable
                    activeOpacity={0.3}
                    onPress={() => {
                      try {
                        setShow_business_classification_modal(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Business Classification'}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                            { height: 44 }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Placeholder */}
                        <>
                          {business_type ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Light'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Select Business Classification'}
                            </Text>
                          )}
                        </>
                        {/* Selected Value */}
                        <>
                          {!business_type ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    lineHeight: 44,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {business_type}
                            </Text>
                          )}
                        </>
                      </View>
                    </View>
                  </Pressable>
                  {/* Location */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Location*'}
                    </Text>

                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_location_modal(true);
                          setLocation_searchString('');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                            { height: 44 }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Placeholder */}
                        <>
                          {myLocation ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Light'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    lineHeight: 45,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Type your location'}
                            </Text>
                          )}
                        </>
                        {/* Selected Value */}
                        <>
                          {!myLocation ? null : (
                            <Text
                              numberOfLines={1}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    lineHeight: 44,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {myLocation}
                            </Text>
                          )}
                        </>
                      </View>
                    </Pressable>
                  </View>
                  {/* Preferences */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 15 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 17,
                            marginTop: 20,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Contact Preferences'}
                    </Text>
                    {/* Call */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: 25,
                          paddingLeft: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary Blue']}
                        name={'MaterialIcons/call'}
                        size={24}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <SwitchRow
                          activeTrackColor={theme.colors['Primary Blue']}
                          label={'Call'}
                          onValueChange={newSwitchRowValue => {
                            try {
                              setCall(newSwitchRowValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 15,
                              }
                            ),
                            dimensions.width
                          )}
                          value={call}
                        />
                      </View>
                    </View>
                    {/* Chat */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: 25,
                          paddingLeft: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary Blue']}
                        name={'MaterialIcons/chat-bubble'}
                        size={24}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <SwitchRow
                          activeTrackColor={theme.colors['Primary Blue']}
                          label={'Chat'}
                          onValueChange={newSwitchRowValue => {
                            try {
                              setChat(newSwitchRowValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 15,
                              }
                            ),
                            dimensions.width
                          )}
                          value={chat}
                        />
                      </View>
                    </View>
                    {/* Email */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: 25,
                          paddingLeft: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary Blue']}
                        name={'MaterialIcons/email'}
                        size={24}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <SwitchRow
                          activeTrackColor={theme.colors['Primary Blue']}
                          label={'Email'}
                          onValueChange={newSwitchRowValue => {
                            try {
                              setMail(newSwitchRowValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SwitchRowStyles(theme)['Switch Row'],
                              {
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 16,
                                marginLeft: 15,
                              }
                            ),
                            dimensions.width
                          )}
                          value={mail}
                        />
                      </View>
                    </View>
                  </View>
                  {/* How did you discover Dirt Near Me */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <Text
                      numberOfLines={2}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          { fontFamily: 'Poppins_400Regular', fontSize: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      {'How did you discover Dirt Near Me? (Optional)'}
                    </Text>

                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setReferral_modal(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['Tablet_Input_BG'],
                            { height: 44 }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Placeholder */}
                        <>
                          {referral_details ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Light'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Select your answer'}
                            </Text>
                          )}
                        </>
                        {/* Selected Value */}
                        <>
                          {!referral_details ? null : (
                            <Text
                              numberOfLines={1}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary Blue'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 17,
                                    marginLeft: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {referral_details}
                            </Text>
                          )}
                        </>
                      </View>
                    </Pressable>
                  </View>
                  {/* err message */}
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Error'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 15,
                          marginTop: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {err_msg}
                  </Text>
                  {/* Sign up */}
                  <Button
                    loading={isLoading}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const found_Error = profile_Inputs_Validation();
                          if (found_Error) {
                            return;
                          }
                          setErr_msg('');
                          setIsLoading(true);
                          const result = (
                            await XanoApi.signUpPOST(Constants, {
                              company_type: business_type,
                              device_type: device_type,
                              email: email,
                              first_name: name,
                              last_name: last_name,
                              lat: my_latitude,
                              location: myLocation,
                              long: my_longitude,
                              phone_number: phone_number,
                              privacy_call: call,
                              privacy_mail: mail,
                              privacy_message: chat,
                              profile_image: profile_image,
                              referral_details: referral_details,
                              user_type: selected_usertype_id,
                            })
                          )?.json;
                          setErr_msg(result?.message);
                          setGlobalVariableValue({
                            key: 'AUTH_TOKEN',
                            value: result?.auth_token,
                          });
                          setGlobalVariableValue({
                            key: 'User_Details',
                            value: result?.user,
                          });
                          setIsLoading(false);
                          if (result?.auth_token?.length) {
                            navigation.navigate('SignupOTPScreen');
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)[
                          'Action Btn Bigscreen'
                        ],
                        { fontSize: 18 }
                      ),
                      dimensions.width
                    )}
                    title={'Sign up'}
                  />
                </View>
              </View>
            </KeyboardAwareScrollView>
            {/* Company Types Modal */}
            <>
              {!show_companyTypeModal ? null : (
                <Modal animationType={'fade'} transparent={true}>
                  {/* Black BG View */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom Color_18'],
                        bottom: 0,
                        left: 0,
                        opacity: 0.5,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                      },
                      dimensions.width
                    )}
                  />
                  {/* Company Type List */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 385, justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <RadioButtonGroup
                        onValueChange={newRadioButtonGroupValue => {
                          try {
                            setSelected_companyType(newRadioButtonGroupValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        value={selected_companyType}
                      >
                        <FlatList
                          data={all_companyTypes}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          listKey={'Tn0de68V'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Pressable
                                activeOpacity={0.3}
                                onPress={() => {
                                  try {
                                    setSelected_companyType(listData?.label);
                                    setSelected_usertype_id(listData?.value);
                                    setShow_companyTypeModal(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      borderBottomWidth: 1,
                                      borderColor:
                                        theme.colors['Primary Yellow'],
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      padding: 12,
                                      paddingLeft: 30,
                                      width: 300,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    source={{ uri: `${listData?.icon_path}` }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ],
                                        { height: 30, width: 30 }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          flex: 1,
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 18,
                                          marginLeft: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.label}
                                  </Text>
                                </View>
                              </Pressable>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            { height: 400 },
                            dimensions.width
                          )}
                          contentContainerStyle={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom #ffffff'],
                              borderRadius: 8,
                              justifyContent: 'center',
                              overflow: 'hidden',
                            },
                            dimensions.width
                          )}
                        />
                      </RadioButtonGroup>
                    </View>
                  </View>
                </Modal>
              )}
            </>
            {/* Referral Moda */}
            <Modal
              animationType={'fade'}
              transparent={true}
              visible={referral_modal}
            >
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* List */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 385, justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <FlatList
                    data={[
                      'Search Engine (Google, etc.)',
                      'Social Media',
                      'Friend',
                      'Other',
                    ]}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'Fa6ud2FN'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          activeOpacity={0.3}
                          onPress={() => {
                            try {
                              setReferral_details(listData);
                              setReferral_modal(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor: theme.colors['Primary Yellow'],
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: 12,
                                paddingLeft: 30,
                                width: 300,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    flex: 1,
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 15,
                                    marginLeft: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth(
                      { height: 400 },
                      dimensions.width
                    )}
                    contentContainerStyle={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom #ffffff'],
                        borderRadius: 8,
                        justifyContent: 'center',
                        overflow: 'hidden',
                      },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Modal>
            {/* Business Classifications Modal */}
            <Modal
              animationType={'fade'}
              transparent={true}
              visible={show_business_classification_modal}
            >
              {/* Black BG View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_18'],
                    bottom: 0,
                    left: 0,
                    opacity: 0.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                  dimensions.width
                )}
              />
              {/* view */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { height: 200 },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup
                    onValueChange={newRadioButtonGroupValue => {
                      try {
                        setBusiness_type(newRadioButtonGroupValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    value={business_type}
                  >
                    <FlatList
                      contentContainerStyle={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom #ffffff'],
                          borderRadius: 8,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                        dimensions.width
                      )}
                      data={['N/A', 'WBE', 'MBE', 'DBE']}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      listKey={'Gs75kEDq'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <Pressable
                            activeOpacity={0.3}
                            onPress={() => {
                              try {
                                setBusiness_type(listData);
                                setShow_business_classification_modal(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors['Primary Yellow'],
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  padding: 12,
                                  paddingLeft: 12,
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      flex: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 18,
                                      marginLeft: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </RadioButtonGroup>
                </View>
              </View>
            </Modal>
            {/* Location Modal */}
            <>
              {!show_location_modal ? null : (
                <Modal animationType={'none'}>
                  {/* Header */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 1,
                        borderColor: theme.colors['Divider'],
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginLeft: 12,
                        marginRight: 12,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 50,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary Blue'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 18,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Change Location'}
                    </Text>

                    <Pressable
                      activeOpacity={0.3}
                      onPress={() => {
                        try {
                          setShow_location_modal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            height: 48,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Primary Blue'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                                marginRight: 4,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Close'}
                        </Text>
                        <Icon
                          color={theme.colors['Primary Blue']}
                          name={'Ionicons/md-close'}
                          size={24}
                        />
                      </View>
                    </Pressable>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { padding: 20 },
                      dimensions.width
                    )}
                  >
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={100}
                      onChangeText={newTextInputValue => {
                        try {
                          setLocation_searchString(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeTextDelayed={newTextInputValue => {
                        const handler = async () => {
                          try {
                            setLocation_searchString(newTextInputValue);
                            const api_Response = (
                              await XanoGooglePlacesApi.searchGET(Constants, {
                                lat: 23.22,
                                lng: 33.3333,
                                search: location_searchString,
                              })
                            )?.json;
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      placeholder={'Search a location'}
                      placeholderTextColor={theme.colors['Light']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input'],
                          {
                            borderColor: theme.colors['Divider'],
                            borderWidth: 1,
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 16,
                            marginBottom: 15,
                            paddingLeft: 16,
                          }
                        ),
                        dimensions.width
                      )}
                      value={location_searchString}
                    />
                    <XanoGooglePlacesApi.FetchSearchGET
                      lat={23.22}
                      lng={33.3333}
                      search={location_searchString}
                    >
                      {({ loading, error, data, refetchSearch }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <FlatList
                            data={fetchData?.predictions}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            listKey={'DMy5hf0V'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <Pressable
                                  activeOpacity={0.3}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const coords = (
                                          await XanoGooglePlacesApi.getCoordinatesGET(
                                            Constants,
                                            { place_id: listData?.place_id }
                                          )
                                        )?.json;
                                        setMyLocation(listData?.description);
                                        console.log(listData);
                                        setPlace_id(
                                          (() => {
                                            const e = listData?.place_id;
                                            console.log(e);
                                            return e;
                                          })()
                                        );
                                        setShow_location_modal(false);
                                        setMy_latitude(
                                          coords?.result?.geometry?.location
                                            ?.lat
                                        );
                                        setMy_longitude(
                                          coords?.result?.geometry?.location
                                            ?.lng
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        paddingBottom: 10,
                                        paddingTop: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Primary']}
                                      name={'Entypo/location-pin'}
                                      size={24}
                                    />
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Primary Blue'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 15,
                                            paddingLeft: 12,
                                            paddingRight: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.description}
                                    </Text>
                                  </View>
                                </Pressable>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </XanoGooglePlacesApi.FetchSearchGET>
                  </View>
                </Modal>
              )}
            </>
            {/* Image Picker */}
            <ActionSheet visible={show_image_picker}>
              {/* Gallery */}
              <ActionSheetItem
                color={theme.colors.strong}
                label={'Gallery'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const image = await openImagePickerUtil({
                        mediaTypes: 'All',
                        allowsEditing: false,
                        quality: 0.2,
                      });

                      setProfile_image(image);
                      setShow_image_picker(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActionSheetItemStyles(theme)[
                    'Action Sheet Item'
                  ],
                  dimensions.width
                )}
              />
              {/* Camera */}
              <ActionSheetItem
                color={theme.colors.strong}
                label={'Camera'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const image = await openCameraUtil({
                        mediaTypes: 'Images',
                        allowsEditing: false,
                        cameraType: 'front',
                        videoMaxDuration: undefined,
                        quality: 0.2,
                      });

                      setProfile_image(image);
                      setShow_image_picker(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActionSheetItemStyles(theme)[
                    'Action Sheet Item'
                  ],
                  dimensions.width
                )}
              />
              {/* Cancel */}
              <ActionSheetCancel
                label={'Cancel'}
                onPress={() => {
                  try {
                    setShow_image_picker(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              />
            </ActionSheet>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(SignupScreen);
